import * as React from "react";
import { ConfirmationDialog } from "../components/ui/ConfirmationDialog";

export type UseConfirmationDialogProps = {
    title: string;
    message: React.ReactNode;
    submitLabel?: string;
    submitTooltip?: string;
    onSubmit?: () => void;
    secondaryActionLabel?: string;
    secondaryActionTooltip?: string;
    onSecondaryAction?: () => void;
    onCancel?: () => void;
    cancelLabel?: string;
    hideCancel?: boolean;
};

export const useConfirmationDialog = ({
    title,
    message,
    submitLabel,
    submitTooltip,
    onSubmit,
    secondaryActionLabel,
    secondaryActionTooltip,
    onSecondaryAction,
    onCancel,
    cancelLabel,
    hideCancel,
}: UseConfirmationDialogProps) => {
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        if (onCancel) {
            onCancel();
        }

        setOpen(false);
    };

    const component = (
        <ConfirmationDialog
            open={open}
            title={title}
            message={message}
            submitLabel={submitLabel}
            submitTooltip={submitTooltip}
            onSubmit={
                onSubmit
                    ? () => {
                          handleClose();
                          onSubmit();
                      }
                    : undefined
            }
            secondaryActionLabel={secondaryActionLabel}
            secondaryActionTooltip={secondaryActionTooltip}
            onSecondaryAction={
                onSecondaryAction
                    ? () => {
                          handleClose();
                          onSecondaryAction();
                      }
                    : undefined
            }
            cancelLabel={cancelLabel}
            onCancel={handleClose}
            hideCancel={hideCancel}
        />
    );

    return {
        open() {
            setOpen(true);
        },

        component,
    };
};
