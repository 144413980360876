import {
    Button,
    ButtonProps,
    Menu,
    styled,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableSortLabel,
    tableSortLabelClasses,
    Tooltip,
    TooltipProps,
} from "@mui/material";
import * as React from "react";
import { CARD_WIDTH, SIDE_PADDING } from "../../config";
import { Colors } from "../util/Colors";
import { overlayPaperStyle } from "./filters/Filters";

export const SiteContainer = styled("div")({
    padding: "32px 108px 24px 108px",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
});

export const SettingSubSiteContainer = styled(SiteContainer)({
    padding: 0,
    paddingTop: 48,
});

export const CmsTableContainer = styled(TableContainer)({
    border: `1px solid ${Colors.GREY_200}`,
    borderRadius: 8,
});

export const CmsTableCell = styled(TableCell)({
    fontSize: 14,
    lineHeight: "22px",
    padding: "0px 8px",
    height: 54,
    borderBottom: "none",
    [`&.${tableCellClasses.head}`]: {
        color: Colors.HEADINGS_DARK,
        fontWeight: 600,
        backgroundColor: Colors.GREY_50_BACKGROUND,
        border: "none",
        whiteSpace: "nowrap",
    },
    [`&.${tableCellClasses.root}:not(&:last-of-type)`]: {
        borderRight: `1px solid ${Colors.GREY_200}`,
    },
    [`&.${tableCellClasses.paddingCheckbox}`]: {
        paddingLeft: 4,
    },
});

export const CmsTableCellWithCheckbox = styled(TableCell)({
    fontSize: 14,
    lineHeight: "22px",
    padding: "0px 8px",
    height: 54,
    borderBottom: "none",
    [`&.${tableCellClasses.head}`]: {
        color: Colors.HEADINGS_DARK,
        fontWeight: 600,
        backgroundColor: Colors.GREY_50_BACKGROUND,
        border: "none",
        whiteSpace: "nowrap",
    },
    [`&.${tableCellClasses.root}:not(&:first-of-type):not(&:last-of-type)`]: {
        borderRight: `1px solid ${Colors.GREY_200}`,
    },
    [`&.${tableCellClasses.paddingCheckbox}`]: {
        paddingLeft: 4,
    },
});

export const CmsTableSortLabel = styled(TableSortLabel)({
    color: `${Colors.HEADINGS_DARK} !important`,
    [`&.${tableSortLabelClasses.active} .${tableSortLabelClasses.icon}`]: {
        color: Colors.PRIMARY_500,
    },
});

export const OptionalTooltip = ({ title, children }: { title?: string; setDangerously?: boolean; children: any }) =>
    title ? (
        <Tooltip PopperProps={{ style: { whiteSpace: "pre-line" } }} title={<div>{title}</div>}>
            {children}
        </Tooltip>
    ) : (
        <>{children}</>
    );

export const TableLabel = ({
    children,
    style,
    tooltip,
}: {
    children: string;
    style?: React.CSSProperties;
    tooltip?: string;
    setDangerously?: boolean;
}) => {
    const [isOverflowing, setIsOverflowing] = React.useState(false);
    const [isHovered, setIsHovered] = React.useState(false);

    const ref = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (ref.current && isHovered) {
            setIsOverflowing(ref.current.offsetWidth < ref.current.scrollWidth);
        }
    }, [children, isHovered]);

    const tooltipMessage = tooltip ?? children;

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <OptionalTooltip title={isOverflowing || tooltip ? tooltipMessage : undefined}>
            <div
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                style={{
                    maxWidth: 150,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    ...style,
                }}
                ref={ref}
            >
                {children}
            </div>
        </OptionalTooltip>
    );
};

export const NeutralButton = styled((props: ButtonProps) => <Button {...props} style={{ ...props.style }} />)({
    height: 32,
    fontSize: 12,
    fontWeight: 600,
    color: "black",
    border: `1px solid ${Colors.GREY_200}`,
    backgroundColor: Colors.LIGHT,
    padding: "7px 12px",
});

export const CmsMenu = styled(Menu)(() => ({
    "& .MuiPaper-root": overlayPaperStyle,
    "& .MuiList-root": {
        padding: 0,
    },
}));

export const Banner = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: SIDE_PADDING,
    paddingRight: SIDE_PADDING,
    minHeight: 40,
    borderBottom: `1px solid ${Colors.GREY_200}`,
    backgroundColor: Colors.GREY_50_BACKGROUND,
    width: "100%",
    zIndex: 2,
});

export const CardContainer = styled("div")({
    display: "flex",
    flexDirection: "row",
    gap: 32,
    padding: "50px 0px",
    flexWrap: "wrap",
});

export const Card = styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: 12,
    padding: "40px 24px",
    minWidth: CARD_WIDTH,
    height: "fit-content",
    border: `1px solid ${Colors.GREY_200}`,
    borderRadius: 8,
});

export const CardColumn = styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: 32,
});

export const CardDataContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: 12,
    marginTop: 12,
});

export const StyledTooltip = styled((props: TooltipProps) => (
    <Tooltip classes={{ popper: props.className }} {...props} />
))({
    "& .MuiTooltip-tooltip": {
        backgroundColor: Colors.LIGHT,
        color: Colors.BODY_2,
        borderRadius: 6,
        boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.1)",
    },
});

export const FileName = styled("p")(({ theme }) => ({
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "20px",
    color: theme.palette.primary.main,
}));

export const TwoColumnLayoutContainer = styled("div")({
    display: "flex",
    flexGrow: 1,
    gap: 32,
    overflowY: "auto",
});

export const PermissionsContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    borderRadius: 8,
    border: `1px solid ${Colors.GREY_200}`,
    background: "#FFFFFF",
    overflowY: "auto",
    padding: "40px 24px",
});
