import * as React from "react";
import { Colors } from "../util/Colors";
import { Icon, IconNames } from "../util/Icon";
import { Button } from "@mui/material";
import { t } from "../../i18n/util";

type ErrorStateProps = {
    iconName?: IconNames;
    title: string;
    description?: string;
    redoAction?: () => void;
};

export const ErrorState = ({ iconName, title, description, redoAction }: ErrorStateProps) => {
    return (
        <div
            style={{
                display: "flex",
                flexGrow: 1,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <div style={{ textAlign: "center", maxWidth: 440 }}>
                {iconName && (
                    <div>
                        <Icon name={iconName} size={64} color={Colors.ERROR} />
                    </div>
                )}
                <h1 style={{ fontSize: 32, marginTop: 32 }}>{title}</h1>
                <p className="body2" style={{ marginTop: 8 }}>
                    {description}
                </p>
                {redoAction && (
                    <Button type="button" variant="contained" style={{ marginTop: 36 }} onClick={() => redoAction()}>
                        {t("button.reload")}
                    </Button>
                )}
            </div>
        </div>
    );
};
