import { IconButton } from "@mui/material";
import { Icon } from "../util/Icon";

export const DetailsHeader = ({ title, onBack }: { title: string; onBack?: () => void }) => {
    return (
        <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
            {onBack && (
                <IconButton onClick={onBack} size="small">
                    <Icon name="close" size={36} />
                </IconButton>
            )}
            <h1>{title}</h1>
        </div>
    );
};
