import { InputAdornment, OutlinedInput, TextField, TextFieldProps } from "@mui/material";
import { FieldInputProps, FormikState, getIn } from "formik";
import * as React from "react";
import { Colors } from "../util/Colors";
import { Icon } from "../util/Icon";
import { FieldError } from "./FieldError";

type IProps = TextFieldProps & {
    field: FieldInputProps<string>;
    form: FormikState<any>;
    showValidationErrorText?: boolean;
    unitAdornment: string;
};

export const CustomSettingInputField = ({
    style,
    type,
    autoComplete,
    form,
    field,
    unitAdornment,
    "aria-label": ariaLabel,
    showValidationErrorText = true,
}: IProps) => {
    const fieldError = getIn(form.errors, field.name);
    const showError = getIn(form.touched, field.name) && !!fieldError;

    return (
        <div style={style}>
            <OutlinedInput
                value={field.value}
                name={field.name}
                onChange={field.onChange}
                onBlur={field.onBlur}
                type={type}
                autoComplete={autoComplete}
                error={showError}
                margin="dense"
                aria-label={ariaLabel}
                style={{ backgroundColor: Colors.GREY_50_BACKGROUND, paddingRight: 16 }}
                inputProps={{
                    style: {
                        textAlign: "left",
                    },
                }}
                endAdornment={
                    <InputAdornment position="end">
                        <p
                            style={{
                                color: Colors.HEADINGS_DARK,
                                fontWeight: "600",
                                whiteSpace: "nowrap",
                                minWidth: 16,
                            }}
                        >
                            {unitAdornment}
                        </p>
                    </InputAdornment>
                }
            />
            {showValidationErrorText && <FieldError>{showError ? fieldError : ""}</FieldError>}
        </div>
    );
};
