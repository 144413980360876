import { observer } from "mobx-react";
import { Route, Router } from "react-router-dom";
import { generalStore } from "../../../stores/GeneralStore";
import { AuthForgotPasswordConfirmSite } from "../../auth/sites/AuthForgotPasswordConfirmSite";
import { AuthForgotPasswordEmailSite } from "../../auth/sites/AuthForgotPasswordEmailSite";
import { AuthForgotPasswordResetSite } from "../../auth/sites/AuthForgotPasswordResetSite";
import { AuthLoginSite } from "../../auth/sites/AuthLoginSite";
import { ExpoTicketsRoutes } from "../../expoTickets/router/ExpoTicketsRoutes";
import { RequestsRoutes } from "../../requests/router/RequestsRoutes";
import { SettingsRoutes } from "../../settings/router/SettingsRoutes";
import { ErrorToast } from "../../ui/ErrorToast";
import { LoadingOverlay } from "../../ui/LoadingOverlay";
import { UsersRoutes } from "../../users/router/UsersRoutes";
import { AuthorizedRouter } from "./AuthorizedRouter";
import { CustomSwitch } from "./CustomSwitch";
import { history } from "./history";
import { NoAuthOnlyRoute } from "./NoAuthOnlyRoute";
import { PrivateRoute } from "./PrivateRoute";
import { Routes } from "./Routes";
import { RoutingManager } from "./RoutingManager";
import { InterfacesRoutes } from "../../interfaces/router/InterfacesRoutes";

const paths = [
    UsersRoutes.ROOT,
    RequestsRoutes.ROOT,
    InterfacesRoutes.ROOT,
    SettingsRoutes.ROOT,
    ExpoTicketsRoutes.ROOT,
];

export const AppRouter = observer(() => (
    <>
        <Router history={history}>
            <RoutingManager>
                <CustomSwitch>
                    <NoAuthOnlyRoute exact path={Routes.ROOT}>
                        <AuthLoginSite />
                    </NoAuthOnlyRoute>
                    <NoAuthOnlyRoute exact path={Routes.FORGOT_PASSWORD.EMAIL}>
                        <AuthForgotPasswordEmailSite />
                    </NoAuthOnlyRoute>
                    <NoAuthOnlyRoute exact path={Routes.FORGOT_PASSWORD.CONFIRM}>
                        <AuthForgotPasswordConfirmSite />
                    </NoAuthOnlyRoute>
                    <NoAuthOnlyRoute exact path={Routes.FORGOT_PASSWORD.RESET}>
                        <AuthForgotPasswordResetSite />
                    </NoAuthOnlyRoute>
                    <PrivateRoute path={paths}>
                        <AuthorizedRouter />
                    </PrivateRoute>
                </CustomSwitch>
            </RoutingManager>
        </Router>
        {generalStore.isLoading && <LoadingOverlay delayMs={generalStore.loadingOverlayMs ?? 300} />}
        <ErrorToast />
    </>
));
