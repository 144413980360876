import { useState } from "react";
import { t } from "../i18n/util";
import { API } from "../network/API";
import { generalStore } from "../stores/GeneralStore";
import { useConfirmationDialog } from "./useConfirmationDialog";

export function useDeactivateRoleDialog({
    role,
    reload,
}: { role?: { id: string; userCount?: number } | null; reload?: () => void } = {}) {
    const [isDelete, setIsDelete] = useState(false);

    const dialog = useConfirmationDialog({
        title: t("role.action.deactivateDialog.title"),
        message: t(
            role?.userCount && isDelete
                ? "role.action.deactivateDialog.message.cannotDelete"
                : "role.action.deactivateDialog.message",
            { userCount: role?.userCount },
        ),
        submitLabel: t("role.action.deactivateDialog.submitLabel"),
        cancelLabel: t("common.no"),
        onSubmit: async () => {
            if (!role) {
                return;
            }

            try {
                generalStore.isLoading = true;
                await API.deactivateRole(role.id);
                generalStore.setSuccessMessage(t("common.success"));
                reload?.();
            } catch (error) {
                generalStore.setError(t("error.deactivateRole"), error);
            } finally {
                generalStore.isLoading = false;
            }
        },
    });

    return {
        ...dialog,
        open: ({ isDelete }: { isDelete?: boolean } = {}) => {
            setIsDelete(isDelete ?? false);
            dialog.open();
        },
    };
}
