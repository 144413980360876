import { AccountTypes } from "../components/util/AccountTypes";
import { t } from "../i18n/util";
import { AccountType } from "../network/APITypes";
import { useMultiSelectionFilter } from "./useMultiSelectionFilter";

export const useAccountTypeFilter = (name: string) => {
    return useMultiSelectionFilter<AccountType>({
        name,
        label: t("filter.label.accountType"),
        options: Object.keys(AccountTypes).map((key) => ({
            value: key as AccountType, // Need to cast because of Object.keys
            label: t(AccountTypes[key]),
        })),
    });
};
