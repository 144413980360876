import { useEffect, useState } from "react";
import { API } from "../network/API";
import { generalStore } from "../stores/GeneralStore";
import { t } from "../i18n/util";

export const useServiceGroups = () => {
    const [serviceGroups, setServiceGroups] = useState<string[]>([]);
    const [isInitialized, setIsInitialized] = useState(false);

    const load = async () => {
        try {
            generalStore.setIsLoading(true)
            const res = await API.getServiceGroups();
            setServiceGroups(res.permissionServiceGroups ?? []);
            setIsInitialized(true);
        } catch (error) {
            generalStore.setError(t("error.loadServiceGroups"), error);
        } finally {
            generalStore.setIsLoading(false)
        }
    };

    useEffect(() => {
        load();
    }, []);

    return {
        serviceGroups,
        isInitialized,
        reloadServiceGroups: load,
    };
};
