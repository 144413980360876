import { Button, FormControlLabel, IconButton, Radio, RadioGroup, styled } from "@mui/material";
import { groupBy } from "lodash";
import * as React from "react";
import { CustomDrawer } from "../components/ui/CustomDrawer";
import { Colors } from "../components/util/Colors";
import { isErrorOfType } from "../components/util/Helpers";
import { Icon } from "../components/util/Icon";
import { t } from "../i18n/util";
import { API } from "../network/API";
import { UserRequest } from "../network/APITypes";
import { generalStore } from "../stores/GeneralStore";
import { useRoles } from "./useRoles";

const Category = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: 56,
    flexShrink: 0,
    cursor: "pointer",
});

type RoleSelectionDrawerVariant = "addRole" | "editRole";

export const useRoleSelection = ({
    user,
    reloadUser,
}: {
    user?: UserRequest | null;
    reloadUser: () => Promise<void>;
}) => {
    const [open, setOpen] = React.useState(false);
    const { rolesResponse } = useRoles({ accountTypes: user?.accountType ? [user?.accountType] : undefined }, open);
    const [preselectedRoleId, setPreselectedRoleId] = React.useState("");
    const [selectedRoleGroupId, setSelectedRoleGroupId] = React.useState<string>();
    const [selectedRoleId, setSelectedRoleId] = React.useState("");
    const [drawerVariant, setDrawerVariant] = React.useState<RoleSelectionDrawerVariant>();

    const handleClose = () => {
        setOpen(false);
    };

    const handleChangeRole = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedRoleId(event.target.value);
    };

    const handleClickRoleGroup = (roleGroupId: string) => {
        if (selectedRoleGroupId === roleGroupId) {
            setSelectedRoleGroupId(undefined);
        } else {
            setSelectedRoleGroupId(roleGroupId);
        }
    };

    const handleSubmit = async () => {
        if (user) {
            try {
                generalStore.setIsLoading(true);

                await API.putUserRoles(user.id, {
                    roleIdentifiers:
                        user.roles
                            ?.flatMap((role) => {
                                if (drawerVariant === "editRole" && role.id === preselectedRoleId) {
                                    return [selectedRoleId];
                                }

                                return [role.id];
                            })
                            .concat(drawerVariant === "addRole" ? [selectedRoleId] : []) ?? [],
                });

                await reloadUser();
                generalStore.setSuccessMessage(t("common.success"));
            } catch (error) {
                if (isErrorOfType(error, "CONFLICT_ROLE_DEACTIVATED")) {
                    generalStore.setError(t("error.setRole.deactivated"), error);
                } else {
                    generalStore.setError(t("error.setRole"), error);
                }
            } finally {
                handleClose();
                generalStore.setIsLoading(false);
            }
        }
    };

    const filteredRoles = rolesResponse?.roles?.filter((role) => {
        // Don't show deactivated roles
        if (role.status === "deactivated") {
            return false;
        }

        if (drawerVariant === "editRole" && role.id === preselectedRoleId) {
            return true;
        }

        return user?.roles
            ?.filter((userRole) => userRole.id !== preselectedRoleId)
            .every((userRole) => userRole.id !== role.id);
    });

    const groupedRoles = groupBy(filteredRoles, (role) => role.roleGroup?.id ?? "noRoleGroup");

    const component = open ? (
        <CustomDrawer open={open} onClose={handleClose}>
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <h4>
                            {drawerVariant === "addRole" && t("screen.userDetails.role.form.title.add")}
                            {drawerVariant === "editRole" && t("screen.userDetails.role.form.title.edit")}
                        </h4>
                        <IconButton onClick={handleClose}>
                            <Icon name="close" />
                        </IconButton>
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        flexGrow: 1,
                        overflow: "auto",
                        marginTop: 32,
                        paddingTop: 8,
                    }}
                >
                    {Object.entries(groupedRoles).map(([roleGroupId, roles]) => {
                        const roleGroup = roles[0].roleGroup;
                        const roleGroupName = roleGroup?.name ?? t("screen.userDetails.role.form.noRoleGroup");

                        return (
                            <React.Fragment key={roleGroupId}>
                                <Category onClick={() => handleClickRoleGroup(roleGroupId)}>
                                    <p style={{ fontWeight: 600, color: Colors.DARK }}>{roleGroupName}</p>
                                    <Icon name={selectedRoleGroupId === roleGroupId ? "chevronUp" : "chevronDown"} />
                                </Category>
                                {selectedRoleGroupId === roleGroupId && (
                                    <div style={{ paddingLeft: 24 }}>
                                        <RadioGroup name="position" value={selectedRoleId} onChange={handleChangeRole}>
                                            {roles.map((role) => (
                                                <FormControlLabel
                                                    key={role.id}
                                                    control={<Radio />}
                                                    value={role.id}
                                                    label={role.name}
                                                    componentsProps={{
                                                        typography: {
                                                            style: {
                                                                fontSize: 14,
                                                                fontWeight: 400,
                                                                color: Colors.DARK,
                                                            },
                                                        },
                                                    }}
                                                    style={{ height: 40, gap: 8 }}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </div>
                                )}
                            </React.Fragment>
                        );
                    })}
                </div>
                <Button variant="contained" fullWidth style={{ marginTop: 32 }} onClick={handleSubmit}>
                    {drawerVariant === "addRole" && t("screen.userDetails.role.form.button.add")}
                    {drawerVariant === "editRole" && t("screen.userDetails.role.form.button.edit")}
                </Button>
            </div>
        </CustomDrawer>
    ) : null;

    return {
        open(variant: RoleSelectionDrawerVariant, preselectedRoleId?: string) {
            setOpen(true);
            setDrawerVariant(variant);
            setSelectedRoleId(preselectedRoleId ?? "");
            setPreselectedRoleId(preselectedRoleId ?? "");
            setSelectedRoleGroupId(rolesResponse?.roles?.find((role) => role.id === preselectedRoleId)?.roleGroup?.id);
        },
        component,
    };
};
