import { Route, useLocation } from "react-router-dom";
import { CustomSwitch } from "../../app/router/CustomSwitch";
import { OciManagementSite } from "../sites/OciManagementSite";
import { InterfacesRoutes } from "./InterfacesRoutes";
import { SiteContainer } from "../../ui/Primitives";
import { t } from "../../../i18n/util";
import { Divider, Tabs } from "@mui/material";
import { StyledLinkTab } from "../../ui/Navigation";
import { IdsManagementSite } from "../sites/ids-management/IdsManagementSite";

const getNavigationValue = (pathname: string) => {
    const tabs = [InterfacesRoutes.IDS_MANAGEMENT];

    return tabs.findIndex((route) => pathname.startsWith(route)) + 1;
};

export const InterfacesRouter = () => {
    const location = useLocation();

    return (
        <SiteContainer>
            <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                <h1>{t("screen.interfaces.title")}</h1>
            </div>
            <Tabs value={getNavigationValue(location.pathname)} style={{ marginTop: 8 }}>
                <StyledLinkTab to={InterfacesRoutes.ROOT} label={t("common.oci")} style={{ marginLeft: 0 }} />
                <StyledLinkTab to={InterfacesRoutes.IDS_MANAGEMENT} label={t("common.ids")} />
            </Tabs>
            <Divider />
            <CustomSwitch>
                <Route exact path={[InterfacesRoutes.ROOT]}>
                    <OciManagementSite />
                </Route>
                <Route exact path={[InterfacesRoutes.IDS_MANAGEMENT]}>
                    <IdsManagementSite />
                </Route>
            </CustomSwitch>
        </SiteContainer>
    );
};
