import { maxWidth, padding } from "@mui/system";
import { useRoles } from "../../../hooks/useRoles";
import { CmsTableCell, CmsTableContainer, SettingSubSiteContainer } from "../../ui/Primitives";
import { Alert, Table, TableBody, TableRow } from "@mui/material";
import { TableHeader, TableHeaderConfig } from "../../ui/TableHeader";
import { t } from "../../../i18n/util";
import { AccountTypes } from "../../util/AccountTypes";

export const SettingsAccountTypesSite = () => {
    const { rolesResponse } = useRoles({});

    if (!rolesResponse) {
        return null;
    }

    const defaultRoles = rolesResponse.roles?.filter((role) => role.isDefaultForAccountType);
    const headerFields: TableHeaderConfig[] = [
        { column: "accountType", label: "table.label.accountType" },
        { column: "defaultRole", label: "table.label.defaultRole" },
    ];
    return (
        <SettingSubSiteContainer>
            <div
                style={{ display: "flex", justifyContent: "center", flexWrap: "wrap", maxWidth: 920, margin: "0 auto" }}
            >
                <Alert severity="info" style={{ marginBottom: 32 }}>
                    {t("screen.settings.accountTypes.alert.text")}
                </Alert>
                <CmsTableContainer>
                    <Table style={{ tableLayout: "fixed" }}>
                        <TableHeader headerFields={headerFields} />
                        <TableBody>
                            {defaultRoles?.map((role) => {
                                return (
                                    <TableRow key={role.id}>
                                        {headerFields.map(({ column }, index) => {
                                            let label;
                                            if (column === "accountType") {
                                                label = t(AccountTypes[role.accountType]);
                                            }
                                            if (column === "defaultRole") {
                                                label = role.name;
                                            }

                                            return <CmsTableCell key={index}>{label}</CmsTableCell>;
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </CmsTableContainer>
            </div>
        </SettingSubSiteContainer>
    );
};
