import { useState } from "react";
import { t } from "../i18n/util";
import { API } from "../network/API";
import { Role, RolePayload } from "../network/APITypes";
import { generalStore } from "../stores/GeneralStore";
import { useManagePermissionsDialog } from "./useManagePermissionsDialog";
import { useRoleDetailsForm } from "./useRoleDetailsForm";
import { useStepFlow } from "./useStepFlow";
import { RoleDetailsFormData } from "../components/forms/addRole/RoleDetailsForm";
import { useRoleGroupDetailsForm } from "./useRoleGroupDetailsForm";

const STEP_ROLE_DETAILS = 0;
const STEP_MANAGE_PERMISSIONS = 1;
const STEP_ADD_ROLE_GROUP = 2;
const STEP_COUNT = 3;

export const useDuplicateRoleFlow = ({
    sourceRole,
    reloadRoles,
}: { sourceRole?: Role; reloadRoles?: () => void } = {}) => {
    const flow = useStepFlow(STEP_COUNT, { [STEP_ADD_ROLE_GROUP]: STEP_ROLE_DETAILS });
    const [open, setOpen] = useState(false);
    const [detailsFormData, setDetailsFormData] = useState<RoleDetailsFormData>();
    const roleDetailsForm = useRoleDetailsForm({
        role: sourceRole,
        duplicate: true,
        formData: detailsFormData,
        async onSubmit(formData) {
            if (formData) {
                setDetailsFormData(formData);
                managePermissionsDialog.open();
                flow.next();
            }
        },
        stepper: {
            activePage: STEP_ROLE_DETAILS,
            pages: STEP_COUNT,
            description: t("addRole.step.roleInformation"),
        },
        onNewGroup(params) {
            // Store values to restore later
            setDetailsFormData(params.formData);
            roleGroupDetailsForm.open(params?.initialGroupName);
            flow.setStep(STEP_ADD_ROLE_GROUP);
        },
    });

    const roleGroupDetailsForm = useRoleGroupDetailsForm({
        onSubmit(result) {
            if (result && "id" in result) {
                // Restore old form with new role group id
                const newFormData = {
                    name: "",
                    description: "",
                    accountType: "",
                    ...detailsFormData,
                    roleGroupId: result.id,
                };
                setDetailsFormData(newFormData);
            }
            flow.back();
        },
        onBack() {
            flow.back();
        },
    });

    const managePermissionsDialog = useManagePermissionsDialog({
        roleId: sourceRole?.id,
        title: t("manageRoleForm.add.title"),
        onClose: () => {
            setOpen(false);
        },
        onBack() {
            roleDetailsForm.open();
            flow.back();
        },
        stepper: {
            activePage: STEP_MANAGE_PERMISSIONS,
            pages: STEP_COUNT,
            description: t("addRole.step.managePermissions"),
        },
        submitButtonLabel: t("screen.settings.roles.button.addRole.text"),
        async onSubmit(grantedPermissions?: string[]) {
            if (grantedPermissions) {
                try {
                    generalStore.isLoading = true;
                    const result = await API.postRole(detailsFormData as RolePayload);
                    await API.putRoleServices(result.id, { permissionServices: grantedPermissions });
                    setOpen(false);
                    reloadRoles?.();
                    generalStore.setSuccessMessage(t("success.duplicateRole"));
                } catch (error) {
                    generalStore.setError(t("error.duplicateRole"), error);
                } finally {
                    generalStore.isLoading = false;
                    return true;
                }
            }
        },
    });

    const handleOpen = () => {
        setOpen(true);
        setDetailsFormData(undefined);
        flow.reset();
        roleDetailsForm.open();
    };

    let component = roleDetailsForm.component;
    if (flow.step === STEP_MANAGE_PERMISSIONS) {
        component = managePermissionsDialog.component;
    } else if (flow.step === STEP_ADD_ROLE_GROUP) {
        component = roleGroupDetailsForm.component;
    }

    return {
        open: handleOpen,
        component: open ? component : null,
    };
};
