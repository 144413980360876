import { groupBy } from "lodash";
import * as React from "react";
import { BasePermissionService, UserRequestPermissionService } from "../../network/APITypes";
import { Colors } from "../util/Colors";
import { Icon } from "../util/Icon";

export type ExtendedBasePermissionService = BasePermissionService & { isExplicitlyGranted?: boolean };

export const GrantedPermissionServicesList = ({
    permissionServices,
    diffingPermissionServices,
    showDescriptions,
    emptyMessage,
    style,
}: {
    permissionServices?: BasePermissionService[] | UserRequestPermissionService[];
    diffingPermissionServices?: BasePermissionService[];
    showDescriptions?: boolean;
    emptyMessage?: string;
    style?: React.CSSProperties;
}) => {
    const filteredServices = permissionServices?.filter((permissionService) => permissionService.isGranted);
    const groupedServices = groupBy(filteredServices, "serviceGroup");

    const showDiffing = !!diffingPermissionServices;

    if (emptyMessage && filteredServices && filteredServices.length === 0) {
        return <p>{emptyMessage}</p>;
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: 16, ...style }}>
            {Object.entries(groupedServices).map(([permissionServiceGroup, permissionServices]) => (
                <div key={permissionServiceGroup} style={{ display: "flex", flexDirection: "column", gap: 4 }}>
                    <span className="body1Bold" style={{ color: Colors.HEADINGS_DARK }}>
                        {permissionServiceGroup}
                    </span>
                    {permissionServices.map((permissionService: ExtendedBasePermissionService) => {
                        const isUnassignedPermissionService = !diffingPermissionServices?.some(
                            (diffingPermissionService) =>
                                diffingPermissionService.isGranted &&
                                diffingPermissionService.identifier === permissionService.identifier,
                        );

                        return (
                            <div
                                key={permissionService.identifier}
                                style={{ display: "flex", alignItems: "flex-start", gap: 4 }}
                            >
                                <Icon
                                    name={
                                        (showDiffing && isUnassignedPermissionService) ||
                                        (permissionService.isExplicitlyGranted && !showDiffing)
                                            ? "add"
                                            : "checkmark"
                                    }
                                    color={showDiffing && isUnassignedPermissionService ? Colors.ACTIVE : undefined}
                                />
                                <div
                                    className="body1"
                                    style={
                                        showDiffing && !isUnassignedPermissionService
                                            ? { color: Colors.GREY_500 }
                                            : undefined
                                    }
                                >
                                    <p>{permissionService.name}</p>
                                    {showDescriptions && <p className="caption">{permissionService.description}</p>}
                                </div>
                            </div>
                        );
                    })}
                </div>
            ))}
        </div>
    );
};
