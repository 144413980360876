import { Button } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import { observer } from "mobx-react";
import * as React from "react";
import * as Yup from "yup";
import { useFileUpload } from "../../../hooks/useFileUpload";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { generalStore } from "../../../stores/GeneralStore";
import { FieldError } from "../../ui/FieldError";
import { FileUpload } from "../../ui/FileUpload";
import { SiteContainer } from "../../ui/Primitives";

type Values = {
    file?: File;
};

const validationSchema = Yup.object().shape({
    file: Yup.mixed().required(t("screen.expoTickets.form.file.required.error")),
});

const initialValues: Values = {
    file: undefined,
};

export const ExpoTicketsSite = observer(() => {
    const fileUpload = useFileUpload();

    const handleSubmit = async (values: Values, formikHelpers: FormikHelpers<Values>) => {
        try {
            generalStore.setIsLoading(true);

            if (values.file) {
                await API.postExpoTickets(values.file);
            }

            generalStore.setSuccessMessage(t("success.uploadFile"));

            fileUpload.reset();
            formikHelpers.resetForm();
        } catch (error) {
            generalStore.setError(t("error.uploadFile"), error);
        } finally {
            generalStore.setIsLoading(false);
        }
    };

    return (
        <SiteContainer>
            <h1>{t("screen.expoTickets.title")}</h1>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                {({ setFieldValue, isSubmitting, errors }) => (
                    <Form style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: 48 }}>
                        <div style={{ width: "100%", maxWidth: 600 }}>
                            {fileUpload.component({
                                title: t("screen.expoTickets.upload.title"),
                                fileSizeInfo: t("screen.expoTickets.upload.fileFormats"),
                                onChange: (files) => setFieldValue("file", files[0]),
                            })}
                            <FieldError>{errors.file}</FieldError>
                        </div>
                        <Button type="submit" variant="contained" disabled={isSubmitting} style={{ marginTop: 24 }}>
                            {t("screen.expoTickets.upload.button")}
                        </Button>
                    </Form>
                )}
            </Formik>
        </SiteContainer>
    );
});
