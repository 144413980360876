import { styled } from "@mui/material";
import * as React from "react";
import { Colors } from "./Colors";

const icons = {
    caretDown: {
        path: (
            // ic_dropdown_accending_blue.svg
            <g fill="none" fillRule="evenodd">
                <path fillRule="evenodd" clipRule="evenodd" d="M7 10L12 15L17 10H7Z" fill="#004990" />
            </g>
        ),
    },
    caretUp: {
        path: (
            // ic_dropdown_decending_blue.svg
            <g fill="none" fillRule="evenodd">
                <path fillRule="evenodd" clipRule="evenodd" d="M7 15L12 10L17 15H7Z" fill="#004990" />
            </g>
        ),
    },
    search: {
        path: (
            // ic_search_blue.svg
            <g fill="none" fillRule="evenodd">
                <path
                    d="M15.5 14H14.71L14.43 13.73C15.41 12.59 16 11.11 16 9.5C16 5.91 13.09 3 9.5 3C5.91 3 3 5.91 3 9.5C3 13.09 5.91 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14V14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z"
                    fill="currentcolor"
                />
            </g>
        ),
    },
    more: {
        path: (
            // ic_dot_blue.svg
            <g fill="none" fillRule="evenodd">
                <circle cx="12" cy="4" r="2" fill="#004990" />
                <circle cx="12" cy="12" r="2" fill="#004990" />
                <circle cx="12" cy="20" r="2" fill="#004990" />
            </g>
        ),
    },
    passwordHidden: {
        path: (
            // ic_visibility_off.svg
            <g fill="none" fillRule="evenodd">
                <path
                    d="M12 7C14.76 7 17 9.24 17 12C17 12.65 16.87 13.26 16.64 13.83L19.56 16.75C21.07 15.49 22.26 13.86 22.99 12C21.26 7.61 16.99 4.5 11.99 4.5C10.59 4.5 9.25 4.75 8.01 5.2L10.17 7.36C10.74 7.13 11.35 7 12 7ZM2 4.27L4.28 6.55L4.74 7.01C3.08 8.3 1.78 10.02 1 12C2.73 16.39 7 19.5 12 19.5C13.55 19.5 15.03 19.2 16.38 18.66L16.8 19.08L19.73 22L21 20.73L3.27 3L2 4.27ZM7.53 9.8L9.08 11.35C9.03 11.56 9 11.78 9 12C9 13.66 10.34 15 12 15C12.22 15 12.44 14.97 12.65 14.92L14.2 16.47C13.53 16.8 12.79 17 12 17C9.24 17 7 14.76 7 12C7 11.21 7.2 10.47 7.53 9.8V9.8ZM11.84 9.02L14.99 12.17L15.01 12.01C15.01 10.35 13.67 9.01 12.01 9.01L11.84 9.02Z"
                    fill="#ABAFB2"
                />
            </g>
        ),
    },
    arrowBack: {
        path: (
            // ic_arrow_back.svg
            <g fill="none">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 11H7.8L13.4 5.4L12 4L4 12L12 20L13.4 18.6L7.8 13H20V11Z"
                    fill="black"
                />
            </g>
        ),
    },
    passwordVisible: {
        path: (
            // ic_visibility.svg
            <g fill="none" fillRule="evenodd">
                <path
                    d="M12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 17C9.24 17 7 14.76 7 12C7 9.24 9.24 7 12 7C14.76 7 17 9.24 17 12C17 14.76 14.76 17 12 17ZM12 9C10.34 9 9 10.34 9 12C9 13.66 10.34 15 12 15C13.66 15 15 13.66 15 12C15 10.34 13.66 9 12 9Z"
                    fill="#ABAFB2"
                />
            </g>
        ),
    },
    mail: {
        path: (
            // ic_mail.svg
            <g fill="none">
                <path
                    d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6ZM20 6L12 11L4 6H20ZM20 18H4V8L12 13L20 8V18Z"
                    fill="currentcolor"
                />
            </g>
        ),
    },
    phone: {
        path: (
            // ic_phone.svg
            <g fill="none">
                <path
                    d="M17.46 5C17.4 5.89 17.25 6.76 17.01 7.59L18.21 8.79C18.62 7.59 18.88 6.32 18.97 5H17.46V5ZM7.6 17.02C6.75 17.26 5.88 17.41 5 17.47V18.96C6.32 18.87 7.59 18.61 8.8 18.21L7.6 17.02ZM16.5 3H20C20.55 3 21 3.45 21 4C21 13.39 13.39 21 4 21C3.45 21 3 20.55 3 20V16.51C3 15.96 3.45 15.51 4 15.51C5.24 15.51 6.45 15.31 7.57 14.94C7.67 14.9 7.78 14.89 7.88 14.89C8.14 14.89 8.39 14.99 8.59 15.18L10.79 17.38C13.62 15.93 15.94 13.62 17.38 10.79L15.18 8.59C14.9 8.31 14.82 7.92 14.93 7.57C15.3 6.45 15.5 5.25 15.5 4C15.5 3.45 15.95 3 16.5 3Z"
                    fill="#004990"
                />
            </g>
        ),
    },
    checkboxOff: {
        path: (
            // ic_checkbox_off.svg
            <g fill="none">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19 5V19H5V5H19ZM19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z"
                    fill="#ABAFB2"
                />
            </g>
        ),
    },
    checkboxIndeterminate: {
        path: (
            // ic_checkbox_minus.svg
            <g fill="none">
                <path
                    d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM19 19H5V5H19V19ZM7 11H17V13H7V11Z"
                    fill={Colors.GREY_700}
                />
            </g>
        ),
    },
    checkboxOn: {
        path: (
            // ic_checkbox_on.svg
            <g fill="none">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM10 17L5 12.1923L6.4 10.8462L10 14.3077L17.6 7L19 8.34615L10 17Z"
                    fill="#004990"
                />
            </g>
        ),
    },
    delete: {
        path: (
            // ic_delete.svg
            <g fill="none">
                <path
                    d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM8 9H16V19H8V9ZM15.5 4L14.5 3H9.5L8.5 4H5V6H19V4H15.5Z"
                    fill="#004990"
                />
            </g>
        ),
    },
    edit: {
        path: (
            // ic_edit_blue.svg
            <g fill="none">
                <path
                    d="M3 17.25V21H6.75L17.81 9.94L14.06 6.19L3 17.25ZM20.71 7.04C21.1 6.65 21.1 6.02 20.71 5.63L18.37 3.29C17.98 2.9 17.35 2.9 16.96 3.29L15.13 5.12L18.88 8.87L20.71 7.04V7.04Z"
                    fill="#004990"
                />
            </g>
        ),
    },
    editDisabled: {
        path: (
            // ic_edit_disabled.svg
            <g fill="none">
                <path
                    d="M3 17.25V21H6.75L17.81 9.94L14.06 6.19L3 17.25ZM20.71 7.04C21.1 6.65 21.1 6.02 20.71 5.63L18.37 3.29C17.98 2.9 17.35 2.9 16.96 3.29L15.13 5.12L18.88 8.87L20.71 7.04V7.04Z"
                    fill="#ABAFB2"
                />
                <path d="M3.91421 4.5L19.707 20.2929" stroke="#ABAFB2" strokeWidth="2" />
            </g>
        ),
    },
    chevronLeft: {
        path: (
            // ic_chevron_left.svg
            <g fill="none">
                <path d="M15.41 16.59L10.83 12L15.41 7.41L14 6L8 12L14 18L15.41 16.59Z" fill="#ABAFB2" />
            </g>
        ),
    },
    chevronRight: {
        path: (
            // ic_chevron_right.svg
            <g fill="none">
                <path
                    d="M8.59009 16.59L13.1701 12L8.59009 7.41L10.0001 6L16.0001 12L10.0001 18L8.59009 16.59Z"
                    fill="#ABAFB2"
                />
            </g>
        ),
    },
    chevronUp: {
        path: (
            // ic_chevron_up.svg
            <g fill="none">
                <path d="M7.41 15.41L12 10.83L16.59 15.41L18 14L12 8L6 14L7.41 15.41Z" fill="#B4B8B8" />
            </g>
        ),
    },
    chevronDown: {
        path: (
            // ic_chevron_down.svg
            <g fill="none">
                <path d="M16.59 8L12 12.58L7.41 8L6 9.41L12 15.41L18 9.41L16.59 8Z" fill="#ABAFB2" />
            </g>
        ),
    },
    checkmarkCircle: {
        path: (
            // ic_check_green.svg
            <g fill="none">
                <path
                    d="M16.59 7.58L10 14.17L6.41 10.59L5 12L10 17L18 9L16.59 7.58ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z"
                    fill="currentcolor"
                />
            </g>
        ),
    },
    close: {
        path: (
            // ic_close.svg
            <g fill="none">
                <path
                    d="M8.46451 7.05026L7.05029 8.46448L10.5858 12L7.05029 15.5355L8.46451 16.9498L12 13.4142L15.5356 16.9498L16.9498 15.5355L13.4143 12L16.9498 8.46448L15.5356 7.05026L12 10.5858L8.46451 7.05026Z"
                    fill="#4F4F4F"
                />
            </g>
        ),
    },
    accept: {
        viewBoxSize: 32,
        path: (
            // ic_cancel_big_32px-1.svg
            <g fill="none">
                <path
                    d="M0 16C0 7.16344 7.16344 0 16 0V0C24.8366 0 32 7.16344 32 16V16C32 24.8366 24.8366 32 16 32V32C7.16344 32 0 24.8366 0 16V16Z"
                    fill="#004990"
                />
                <path
                    d="M13 20.545L8.83003 16.375L7.41003 17.785L13 23.375L25 11.375L23.59 9.965L13 20.545Z"
                    fill="white"
                />
            </g>
        ),
    },
    decline: {
        viewBoxSize: 32,
        path: (
            // ic_cancel_big_32px.svg
            <g fill="none">
                <path
                    d="M0 16C0 7.16344 7.16344 0 16 0V0C24.8366 0 32 7.16344 32 16V16C32 24.8366 24.8366 32 16 32V32C7.16344 32 0 24.8366 0 16V16Z"
                    fill="#E7333F"
                />
                <path
                    d="M23 10.785L21.59 9.375L16 14.965L10.41 9.375L9 10.785L14.59 16.375L9 21.965L10.41 23.375L16 17.785L21.59 23.375L23 21.965L17.41 16.375L23 10.785Z"
                    fill="white"
                />
            </g>
        ),
    },
    warning: {
        path: (
            // ic_warning.svg
            <g fill="none">
                <path
                    d="M12 17C12.2833 17 12.521 16.904 12.713 16.712C12.9043 16.5207 13 16.2833 13 16C13 15.7167 12.9043 15.479 12.713 15.287C12.521 15.0957 12.2833 15 12 15C11.7167 15 11.4793 15.0957 11.288 15.287C11.096 15.479 11 15.7167 11 16C11 16.2833 11.096 16.5207 11.288 16.712C11.4793 16.904 11.7167 17 12 17ZM11 13H13V7H11V13ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6873 5.825 19.975 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26267 14.6833 2 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31267 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.31233 8.1 2.787C9.31667 2.26233 10.6167 2 12 2C13.3833 2 14.6833 2.26233 15.9 2.787C17.1167 3.31233 18.175 4.025 19.075 4.925C19.975 5.825 20.6873 6.88333 21.212 8.1C21.7373 9.31667 22 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6873 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6873 15.9 21.212C14.6833 21.7373 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                    fill="#9B9B9B"
                />
            </g>
        ),
    },
    add: {
        path: (
            // ic_plus_blue.svg
            <g fill="none">
                <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill="#004990" />
            </g>
        ),
    },
    arrowForward: {
        path: (
            // ic_arrow_forward.svg
            <g fill="none">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4 11H16.2L10.6 5.4L12 4L20 12L12 20L10.6 18.6L16.2 13H4V11Z"
                    fill="#004990"
                />
            </g>
        ),
    },
    checkGreen: {
        path: (
            // ic_check_green.svg
            <g fill="none">
                <path
                    d="M16.59 7.58L10 14.17L6.41 10.59L5 12L10 17L18 9L16.59 7.58ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z"
                    fill="white"
                />
            </g>
        ),
    },
    checkmark: {
        path: (
            // ic_check
            <g fill="none">
                <path
                    d="M9.00016 16.1698L4.83016 11.9998L3.41016 13.4098L9.00016 18.9998L21.0002 6.99984L19.5902 5.58984L9.00016 16.1698Z"
                    fill="#004990"
                />
            </g>
        ),
    },
    confirm: {
        viewBoxSize: 32,
        path: (
            // ic_cancel_big_32px.svg
            <g fill="none">
                <path
                    d="M0 16C0 7.16344 7.16344 0 16 0V0C24.8366 0 32 7.16344 32 16V16C32 24.8366 24.8366 32 16 32V32C7.16344 32 0 24.8366 0 16V16Z"
                    fill="#CBDD92"
                />
                <path
                    d="M13 20.545L8.83003 16.375L7.41003 17.785L13 23.375L25 11.375L23.59 9.965L13 20.545Z"
                    fill="white"
                />
            </g>
        ),
    },
    upload: {
        path: (
            // ic_fileUpload.svg
            <g fill="none">
                <g clipPath="url(#clip0_2944_1847)">
                    <path
                        d="M31.4291 0.277222C31.209 0.0454104 30.9073 -0.09375 30.5945 -0.09375H11.8698C8.41456 -0.09375 5.5625 2.74695 5.5625 6.20179V41.6104C5.5625 45.0656 8.41456 47.9063 11.8698 47.9063H37.2841C40.7393 47.9063 43.5914 45.0656 43.5914 41.6104V13.4945C43.5914 13.1931 43.4522 12.9035 43.2552 12.683L31.4291 0.277222ZM31.7653 3.99903L39.6843 12.312H34.5364C33.006 12.312 31.7653 11.083 31.7653 9.55263V3.99903ZM37.2841 45.5875H11.8698C9.70179 45.5875 7.88135 43.7905 7.88135 41.6104V6.20179C7.88135 4.03382 9.69007 2.2251 11.8698 2.2251H29.4465V9.55263C29.4465 12.3699 31.7192 14.6309 34.5364 14.6309H41.2725V41.6104C41.2725 43.7905 39.4638 45.5875 37.2841 45.5875Z"
                        fill="#004990"
                    />
                    <path
                        d="M34.1189 37.5876H15.0349C14.3973 37.5876 13.8755 38.1091 13.8755 38.747C13.8755 39.3846 14.3973 39.9064 15.0349 39.9064H34.1307C34.7682 39.9064 35.2901 39.3846 35.2901 38.747C35.2901 38.1091 34.7682 37.5876 34.1189 37.5876Z"
                        fill="#004990"
                    />
                    <path
                        d="M18.6407 25.1122L23.4176 19.9757V32.6368C23.4176 33.2744 23.9395 33.7962 24.577 33.7962C25.215 33.7962 25.7365 33.2744 25.7365 32.6368V19.9757L30.5133 25.1122C30.7451 25.3554 31.0469 25.4832 31.3596 25.4832C31.638 25.4832 31.928 25.3788 32.1481 25.1701C32.6121 24.7295 32.6469 23.9989 32.2063 23.5353L25.412 16.2426C25.1915 16.0108 24.8901 15.8716 24.5657 15.8716C24.2408 15.8716 23.9395 16.0108 23.7194 16.2426L16.925 23.5353C16.4845 23.9989 16.5193 24.7409 16.9829 25.1701C17.47 25.6106 18.2002 25.5759 18.6407 25.1122Z"
                        fill="#004990"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_2944_1847">
                        <rect width="48" height="48" fill="white" transform="translate(0.5)" />
                    </clipPath>
                </defs>
            </g>
        ),
        viewBoxSize: 48,
    },
    file: {
        path: (
            // ic_file.svg
            <g fill="none">
                <g clipPath="url(#clip0_2962_2819)">
                    <path
                        d="M30.9291 0.277222C30.709 0.0454104 30.4073 -0.09375 30.0945 -0.09375H11.3698C7.91456 -0.09375 5.0625 2.74695 5.0625 6.20179V41.6104C5.0625 45.0656 7.91456 47.9063 11.3698 47.9063H36.7841C40.2393 47.9063 43.0914 45.0656 43.0914 41.6104V13.4945C43.0914 13.1931 42.9522 12.9035 42.7552 12.683L30.9291 0.277222ZM31.2653 3.99903L39.1843 12.312H34.0364C32.506 12.312 31.2653 11.083 31.2653 9.55263V3.99903ZM36.7841 45.5875H11.3698C9.20179 45.5875 7.38135 43.7905 7.38135 41.6104V6.20179C7.38135 4.03382 9.19007 2.2251 11.3698 2.2251H28.9465V9.55263C28.9465 12.3699 31.2192 14.6309 34.0364 14.6309H40.7725V41.6104C40.7725 43.7905 38.9638 45.5875 36.7841 45.5875Z"
                        fill="#004990"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_2962_2819">
                        <rect width="48" height="48" fill="white" />
                    </clipPath>
                </defs>
            </g>
        ),
        viewBoxSize: 48,
    },
    sadFace: {
        path: (
            // ill_sad_64px
            <g>
                <g clipPath="url(#clip0_1961_55653)">
                    <circle cx="32" cy="32" r="27" fill="#EDEDED" />
                    <path
                        d="M41.3333 29.3333C43.5424 29.3333 45.3333 27.5425 45.3333 25.3333C45.3333 23.1242 43.5424 21.3333 41.3333 21.3333C39.1241 21.3333 37.3333 23.1242 37.3333 25.3333C37.3333 27.5425 39.1241 29.3333 41.3333 29.3333Z"
                        fill="white"
                    />
                    <path
                        d="M22.6667 29.3333C24.8759 29.3333 26.6667 27.5425 26.6667 25.3333C26.6667 23.1242 24.8759 21.3333 22.6667 21.3333C20.4576 21.3333 18.6667 23.1242 18.6667 25.3333C18.6667 27.5425 20.4576 29.3333 22.6667 29.3333Z"
                        fill="white"
                    />
                    <path
                        d="M31.9999 44C33.9466 44 35.7066 44.5067 37.2533 45.4133C37.5733 45.04 39.5466 42.8 39.9466 42.3733C37.6799 40.88 34.9599 40 31.9999 40C29.0399 40 26.3199 40.88 24.0266 42.3467C26.6133 45.2533 24.0533 42.4 26.7199 45.3867C28.2933 44.5067 30.0533 44 31.9999 44Z"
                        fill="white"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_1961_55653">
                        <rect width="64" height="64" fill="white" />
                    </clipPath>
                </defs>
            </g>
        ),
        viewBoxSize: 64,
    },
    verified: {
        // ic_verified_fill
        path: (
            <g>
                <g clipPath="url(#clip0_3305_19377)">
                    <path
                        d="M23 12L20.56 9.21L20.9 5.52L17.29 4.7L15.4 1.5L12 2.96L8.6 1.5L6.71 4.69L3.1 5.5L3.44 9.2L1 12L3.44 14.79L3.1 18.49L6.71 19.31L8.6 22.5L12 21.03L15.4 22.49L17.29 19.3L20.9 18.48L20.56 14.79L23 12ZM10.09 16.72L6.29 12.91L7.77 11.43L10.09 13.76L15.94 7.89L17.42 9.37L10.09 16.72Z"
                        fill="currentcolor"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_3305_19377">
                        <rect width="24" height="24" fill="white" />
                    </clipPath>
                </defs>
            </g>
        ),
    },
    link: {
        path: (
            <path
                fill="currentColor"
                d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1M8 13h8v-2H8zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5"
            />
        ),
    },
};

type IContainerProps = {
    color?: string;
    hovercolor?: string;
    onClick?: any;
    disabled?: boolean;
    useCurrentColor?: boolean;
};

const BlockContainer = styled("div", {
    shouldForwardProp: (prop: string) => prop !== "useCurrentColor",
})(({ disabled, onClick, hovercolor, color, useCurrentColor }: IContainerProps) => ({
    userSelect: "none",
    cursor: disabled || !onClick ? undefined : "pointer",

    "& > svg": {
        display: "block",
    },

    "& > svg *[fill]:not([fill=none])": {
        transition: hovercolor ? "fill cubic-bezier(0.4, 0, 0.2, 1) 0ms" : undefined,
        fill: useCurrentColor ? "currentColor" : color,
    },

    "&: hover > svg *[fill]:not([fill=none])": {
        fill: hovercolor,
    },
}));

const InlineBlockContainer = styled(BlockContainer)({
    display: "inline-block",
    lineHeight: 0,
    verticalAlign: "bottom",
    "& > svg": {
        display: "inline-block",
    },
});

type IIconMap = {
    [name: string]: {
        viewBoxSize?: number;
        path: React.ReactNode;
    };
};

export type IconNames = keyof typeof icons;

export type IProps = {
    name: IconNames;
    title?: string;
    onClick?: any;
    disabled?: boolean;
    color?: string;
    hoverColor?: string;
    useCurrentColor?: boolean;
    size?: string | number;
    style?: React.CSSProperties;
    className?: string;
    display?: "block" | "inline-block";
    "data-id"?: string;
};
export const Icon = ({
    name,
    title,
    onClick,
    disabled,
    color,
    hoverColor,
    useCurrentColor,
    size = 24,
    style,
    className,
    "data-id": dataId,
    display = "inline-block",
}: IProps) => {
    const Container = display === "block" ? BlockContainer : InlineBlockContainer;
    const viewBoxSize = (icons as IIconMap)[name].viewBoxSize || 24;
    return (
        <Container
            data-id={dataId}
            onClick={disabled ? undefined : onClick}
            disabled={disabled}
            color={disabled ? "#A1A1A1" : color}
            // the props have to be lowercase to appear on DOM
            hovercolor={disabled ? undefined : hoverColor}
            useCurrentColor={disabled ? undefined : useCurrentColor}
            className={className}
            style={style}
        >
            <svg
                viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
                xmlns="http://www.w3.org/2000/svg"
                width={size}
                height={size}
            >
                {title && <title>{title}</title>}
                {icons[name].path}
            </svg>
        </Container>
    );
};
