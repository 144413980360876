import { Button } from "@mui/material";
import { Form, Formik } from "formik";
import { omit } from "lodash";
import * as Yup from "yup";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { RoleGroup, RoleGroupPayload } from "../../../network/APITypes";
import { generalStore } from "../../../stores/GeneralStore";
import { DialogHeader } from "../../ui/DialogHeader";
import { RoleGroupDetailsFields } from "./RoleGroupDetailsFields";
import { Stepper } from "../../../types";
import { DialogStepper } from "../../ui/DialogStepper";

const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("manageRoleGroupForm.validationError.required.name")),
    description: Yup.string().max(100, t("manageRoleGroupForm.validationError.descriptionLength", { maxLength: 100 })),
});

export type RoleGroupDetailsFormData = {
    name: string;
    description?: string;
    roleCount?: number;
};

export const RoleGroupDetailsForm = ({
    onClose,
    onSubmit,
    onBack,
    roleGroup,
    stepper,
    name,
    persistOnSubmit,
}: {
    onClose: () => void;
    onBack?: () => void;
    roleGroup?: RoleGroup | null;
    onSubmit?: (result?: RoleGroup | RoleGroupDetailsFormData) => void | Promise<void>;
    stepper?: Stepper;
    name?: string;
    persistOnSubmit: boolean;
}) => {
    const initialValues: RoleGroupDetailsFormData = {
        name: roleGroup?.name ?? name ?? "",
        description: roleGroup?.description ?? "",
    };

    const handleSubmit = async (model: typeof initialValues) => {
        if (!persistOnSubmit) {
            onSubmit?.(model);
            return;
        }

        let result: RoleGroup | undefined;
        try {
            generalStore.setIsLoading(true);
            const sanitizedModel = omit(model, "id") as RoleGroupPayload;

            if (roleGroup) {
                result = await API.putRoleGroup(roleGroup.id, sanitizedModel);
                generalStore.setSuccessMessage(t("success.editRoleGroup"));
            } else {
                result = await API.postRoleGroup(sanitizedModel);
                generalStore.setSuccessMessage(t("success.addRoleGroup"));
            }

            await onSubmit?.(result);
        } catch (error) {
            if (roleGroup) {
                generalStore.setError(t("error.editRoleGroup"), error);
            } else {
                generalStore.setError(t("error.addRoleGroup"), error);
            }
        } finally {
            onClose();
            generalStore.setIsLoading(false);
        }
    };

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
            <Form
                style={{
                    width: "100%",
                    height: "inherit",
                    overflow: "auto",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
                noValidate
            >
                <div>
                    <DialogHeader
                        title={roleGroup ? t("manageRoleGroupForm.edit.title") : t("manageRoleGroupForm.add.title")}
                        onClose={onClose}
                        onBack={onBack}
                    />
                    {stepper && <DialogStepper stepper={stepper} />}

                    <div style={{ marginTop: 40 }}>
                        <RoleGroupDetailsFields roleGroup={roleGroup ?? undefined} />
                    </div>
                </div>
                <Button type="submit" variant="contained" style={{ marginTop: 32 }}>
                    {roleGroup ? t("button.saveChanges") : t("button.next")}
                </Button>
            </Form>
        </Formik>
    );
};
