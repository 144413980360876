import { Table, TableBody, TableRow } from "@mui/material";
import { styled } from "@mui/system";
import { observer } from "mobx-react";
import * as React from "react";
import { ENABLE_LINKED_USER_INDICATION_COLUMN } from "../../../config";
import { useConfirmationDialog } from "../../../hooks/useConfirmationDialog";
import { usePagination } from "../../../hooks/usePagination";
import { useUsers } from "../../../hooks/useUsers";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { BaseUserRequest } from "../../../network/APITypes";
import { generalStore } from "../../../stores/GeneralStore";
import { EmptyState } from "../../ui/EmptyState";
import { CmsTableCell, CmsTableContainer, NeutralButton, SiteContainer, TableLabel } from "../../ui/Primitives";
import { UserStatus } from "../../ui/Status";
import { TableHeader, TableHeaderConfig } from "../../ui/TableHeader";
import { Colors } from "../../util/Colors";
import { getUserDialogConfiguration } from "../../util/Dialogs";
import { Icon } from "../../util/Icon";

const ActionIcon = styled("div")({
    display: "flex",
    justifyContent: "center",
    width: 90,
});

export const RequestsSite = observer(() => {
    const [contextUser, setContextUser] = React.useState<BaseUserRequest>();
    const pagination = usePagination();
    const { users, reloadUsers, isLoading, isInitialized } = useUsers({
        limit: pagination.pageSize,
        offset: pagination.offset,
        orderDir: pagination.orderDir,
        orderBy: pagination.orderBy,
        search: pagination.search,
        status: ["waiting"],
    });

    const handleSubmitApproveUserDialog = async () => {
        if (contextUser) {
            try {
                generalStore.setIsLoading(true);

                await API.postApproveUser(contextUser.id);
                await reloadUsers();
                await generalStore.loadDashboardInfo();

                generalStore.setSuccessMessage(t("common.success"));
            } catch (error) {
                generalStore.setError(t("error.deactivateUsers"), error);
            } finally {
                setContextUser(undefined);
                generalStore.setIsLoading(false);
            }
        }
    };

    const handleSubmitDenyUserDialog = async () => {
        if (contextUser) {
            try {
                generalStore.setIsLoading(true);

                await API.postDenyUser(contextUser.id);
                await reloadUsers();
                await generalStore.loadDashboardInfo();

                generalStore.setSuccessMessage(t("common.success"));
            } catch (error) {
                generalStore.setError(t("error.deactivateUsers"), error);
            } finally {
                setContextUser(undefined);
                generalStore.setIsLoading(false);
            }
        }
    };

    const handleSubmitRequestUserManagementDialog = async () => {
        if (contextUser) {
            try {
                generalStore.setIsLoading(true);
                await API.postCustomerRequestOptIn(contextUser.customerID);
                await reloadUsers();
                generalStore.setSuccessMessage(t("common.success"));
            } catch (error) {
                generalStore.setError(t("error.customerOptIn"), error);
            } finally {
                generalStore.setIsLoading(false);
            }
        }
    };

    const approveUserDialog = useConfirmationDialog({
        ...getUserDialogConfiguration("approve", { user: contextUser }),
        onSubmit: handleSubmitApproveUserDialog,
    });

    const handleClickApproveUser = (user: BaseUserRequest) => {
        setContextUser(user);
        approveUserDialog.open();
    };

    const denyUserDialog = useConfirmationDialog({
        ...getUserDialogConfiguration("deny", { user: contextUser }),
        onSubmit: handleSubmitDenyUserDialog,
    });

    const handleClickDenyUser = (user: BaseUserRequest) => {
        setContextUser(user);
        denyUserDialog.open();
    };

    const requestUserManagementDialog = useConfirmationDialog({
        ...getUserDialogConfiguration("requestUserManagement", { user: contextUser }),
        onSubmit: handleSubmitRequestUserManagementDialog,
    });

    const handleClickRequestUserManagement = (user: BaseUserRequest) => {
        setContextUser(user);
        requestUserManagementDialog.open();
    };

    const headerFields: TableHeaderConfig[] = [
        { column: "customerNumber", label: "table.label.customerNumber", style: { width: 0 } },
        { column: "lastName", label: "table.label.lastName" },
        { column: "firstName", label: "table.label.firstName" },
        { column: "email", label: "table.label.email" },
        { column: "position", label: "table.label.position" },
        { column: "status", label: "table.label.status", style: { width: 0 } },
        ...(ENABLE_LINKED_USER_INDICATION_COLUMN
            ? [{ column: "linkedUserIndication", style: { width: 40 } } as TableHeaderConfig]
            : []),
        { column: "actions", style: { width: 0 } },
    ];

    const tableBody = (
        <TableBody>
            {users?.userRequests?.map((user, index) => {
                return (
                    <TableRow key={user.id}>
                        {headerFields.map(({ column }, index) => {
                            let label = user[column];

                            if (column === "status") {
                                label = <UserStatus status={user[column]} />;
                            }

                            if (column === "linkedUserIndication") {
                                label = user.appUserProfile?.userId ? (
                                    <Icon name="link" color={Colors.GREY_700} />
                                ) : null;
                            }

                            if (column === "customerNumber") {
                                return (
                                    <CmsTableCell
                                        key={column}
                                        style={{
                                            opacity: user.customer?.optIn ? 1 : 0.5,
                                        }}
                                    >
                                        {user.customer?.externalId}
                                    </CmsTableCell>
                                );
                            }

                            if (column === "position") {
                                label = user.corporatePosition?.role.name;
                            }

                            if (column === "actions") {
                                if (!user.customer?.optIn) {
                                    return (
                                        <CmsTableCell key={column}>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: 24,
                                                    paddingLeft: 8,
                                                }}
                                            >
                                                <Icon name="editDisabled" />
                                                <NeutralButton
                                                    style={{ whiteSpace: "nowrap", width: 180 }}
                                                    onClick={() => handleClickRequestUserManagement(user)}
                                                >
                                                    {t("screen.requests.actions.requestUserManagement")}
                                                </NeutralButton>
                                            </div>
                                        </CmsTableCell>
                                    );
                                }

                                return (
                                    <CmsTableCell key={column}>
                                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <ActionIcon>
                                                <Icon
                                                    name="decline"
                                                    onClick={() => handleClickDenyUser(user)}
                                                    size={32}
                                                />
                                            </ActionIcon>
                                            <ActionIcon>
                                                <Icon
                                                    name="accept"
                                                    onClick={() => handleClickApproveUser(user)}
                                                    size={32}
                                                />
                                            </ActionIcon>
                                        </div>
                                    </CmsTableCell>
                                );
                            }

                            return (
                                <CmsTableCell
                                    key={column}
                                    style={{
                                        opacity: user.customer?.optIn ? 1 : 0.5,
                                        maxWidth: column !== "status" ? 100 : undefined,
                                    }}
                                >
                                    <TableLabel style={{ maxWidth: "100%" }}>{label}</TableLabel>
                                </CmsTableCell>
                            );
                        })}
                    </TableRow>
                );
            })}
        </TableBody>
    );

    const isEmpty = users && users.total === 0;

    return (
        <SiteContainer>
            <h1>{t("screen.requests.title")}</h1>
            {generalStore.dashboardInfo && generalStore.dashboardInfo.openRequests > 0 && (
                <p>{t("screen.requests.openRequests", { count: generalStore.dashboardInfo?.openRequests })}</p>
            )}
            {isInitialized && !isLoading && isEmpty && (
                <EmptyState
                    iconName="mail"
                    title={t("screen.requests.emptyScreen.title")}
                    description={t("screen.requests.emptyScreen.description")}
                />
            )}
            {!isEmpty && (
                <>
                    <div style={{ marginTop: 48 }}>{pagination.component(users?.total ?? 0)}</div>
                    {isInitialized && !isLoading && !isEmpty && (
                        <CmsTableContainer>
                            <Table>
                                <TableHeader
                                    headerFields={headerFields}
                                    orderBy={pagination.orderBy}
                                    orderDir={pagination.orderDir}
                                    onChangeOrder={pagination.onChangeOrder}
                                />
                                {tableBody}
                            </Table>
                        </CmsTableContainer>
                    )}
                </>
            )}
            {requestUserManagementDialog.component}
            {approveUserDialog.component}
            {denyUserDialog.component}
        </SiteContainer>
    );
});
