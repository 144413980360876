import * as React from "react";
import { Colors } from "../util/Colors";
import { Icon, IconNames } from "../util/Icon";

type EmptyStateProps = {
    iconName?: IconNames;
    title: string;
    description?: string;
};

export const EmptyState = ({ iconName, title, description }: EmptyStateProps) => {
    return (
        <div
            style={{
                display: "flex",
                flexGrow: 1,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <div style={{ textAlign: "center", maxWidth: 440 }}>
                {iconName && (
                    <div>
                        <Icon name={iconName} size={64} style={{ color: Colors.GREY_200 }} />
                    </div>
                )}
                <h1 style={{ fontSize: 32, marginTop: 32 }}>{title}</h1>
                <p className="body2" style={{ marginTop: 8 }}>
                    {description}
                </p>
            </div>
        </div>
    );
};
