import { compact, uniq } from "lodash";
import { useLocation } from "react-router-dom";
import { pushRoute, withQuery } from "../components/app/router/history";
import {
    MultiSelectionFilterButton,
    MultiSelectionFilterOption,
} from "../components/ui/filters/MultiSelectionFilterButton";
import { RemoveFilterButton } from "../components/ui/filters/RemoveFilterButton";
import { useQuery } from "./useQuery";

export const useMultiSelectionFilter = <T extends string>({
    name,
    label,
    options,
}: {
    name: string;
    label: string;
    options: MultiSelectionFilterOption<T>[];
}) => {
    const location = useLocation();
    const queryParams = useQuery();
    const values = compact(([] as T[]).concat(queryParams[name]));

    const handleChange = (values: T[]) => {
        pushRoute(withQuery(location.pathname, { ...queryParams, page: 1, [name]: uniq(values) }));
    };

    const handleRemove = () => {
        pushRoute(withQuery(location.pathname, { ...queryParams, page: 1, [name]: undefined }));
    };

    const component = (
        <MultiSelectionFilterButton
            key={name}
            values={values}
            label={label}
            onChange={handleChange}
            options={options}
        />
    );

    const resetComponent = values.length > 0 && (
        <RemoveFilterButton
            key={name}
            label={label}
            values={options.filter((option) => values.includes(option.value)).map((option) => option.label)}
            onRemove={handleRemove}
        />
    );

    return {
        name,
        values,
        component,
        resetComponent,
    };
};

export type IFilter = ReturnType<typeof useMultiSelectionFilter>;
