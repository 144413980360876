import { Button, Checkbox, FormControlLabel } from "@mui/material";
import { uniq } from "lodash";
import { useEffect, useState } from "react";
import { CustomDrawer } from "../components/ui/CustomDrawer";
import { DialogHeader } from "../components/ui/DialogHeader";
import { SearchField } from "../components/ui/SearchField";
import { t } from "../i18n/util";
import { API } from "../network/API";
import { generalStore } from "../stores/GeneralStore";
import { Stepper } from "../types";
import { useRoleGroup } from "./useRoleGroup";
import { useRoles } from "./useRoles";
import { DialogStepper } from "../components/ui/DialogStepper";

export const useManageRolesDialog = ({
    roleGroupId,
    onClose,
    onSubmit,
    onBack,
    stepper,
    title,
}: {
    roleGroupId?: string;
    onBack?: () => void;
    onClose?: () => void;
    onSubmit?: (selectedRoleIds?: string[]) => Promise<void | boolean> | void | boolean;
    stepper?: Stepper;
    title: string;
}) => {
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState<string>();

    const { roleGroup, isLoading } = useRoleGroup({ id: roleGroupId, loadCondition: open });

    const [selectedRoleIds, setSelectedRoleIds] = useState<string[]>([]);

    useEffect(() => {
        if (roleGroup && roleGroup.roles && roleGroup.roles.length > 0) {
            setSelectedRoleIds(roleGroup.roles.map((role) => role.id));
        }
    }, [roleGroup]);

    const isRoleSelected = (roleId: string) => {
        return selectedRoleIds.includes(roleId);
    };

    const selectRole = (roleId: string, selected: boolean) => {
        if (selected) {
            setSelectedRoleIds(uniq([...selectedRoleIds, roleId]));
        } else {
            setSelectedRoleIds(selectedRoleIds.filter((id) => id !== roleId));
        }
    };

    const { rolesResponse } = useRoles({ status: ["active", "system"], limit: 20, search }, open);

    const close = () => {
        setOpen(false);
        setSelectedRoleIds([]);
    };

    const handleClose = () => {
        close();
        onClose?.();
    };

    const handleSubmit = async () => {
        const wasProcessedExternally = await onSubmit?.(selectedRoleIds);

        if (wasProcessedExternally || !roleGroupId) {
            return;
        }

        try {
            generalStore.isLoading = true;
            await API.putRoleGroupRoles(roleGroupId, selectedRoleIds);
            generalStore.setSuccessMessage(t("success.editRoleGroup"));
        } catch (error) {
            generalStore.setError(t("error.editRoleGroup"), error);
        } finally {
            generalStore.isLoading = false;
        }

        onSubmit?.();
        close();
    };

    const component = (
        <CustomDrawer open={open} onClose={handleClose}>
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    overflow: "auto",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
            >
                <DialogHeader title={title} onBack={onBack} onClose={handleClose} />
                {stepper && <DialogStepper stepper={stepper} />}
                <SearchField
                    fullWidth
                    placeholder={t("common.search.placeholder")}
                    onChange={setSearch}
                    style={{ marginTop: 32, marginBottom: 32 }}
                />
                <div style={{ flexGrow: 1, overflowY: "auto", paddingLeft: 10 }}>
                    {!isLoading && (
                        <>
                            {rolesResponse?.roles?.map((role) => {
                                return (
                                    <div key={role.id}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isRoleSelected(role.id)}
                                                    onChange={(e, checked) => {
                                                        selectRole(role.id, checked);
                                                    }}
                                                />
                                            }
                                            label={role.name}
                                        />
                                    </div>
                                );
                            })}
                        </>
                    )}
                </div>
                <Button variant="contained" style={{ marginTop: 32 }} onClick={handleSubmit}>
                    {t("button.saveChanges")}
                </Button>
            </div>
        </CustomDrawer>
    );

    return {
        open() {
            setOpen(true);
        },
        component,
    };
};
