import { Checkbox, TableHead, TableRow, TableSortLabel } from "@mui/material";
import * as React from "react";
import { MessageIDS, t } from "../../i18n/util";
import { OrderDir } from "../../network/APITypes";
import { Colors } from "../util/Colors";
import { CmsTableCell, CmsTableCellWithCheckbox, CmsTableSortLabel } from "./Primitives";

export type TableHeaderConfig = {
    column:
        | "checkbox"
        | "customerNumber"
        | "isAdmin"
        | "lastName"
        | "firstName"
        | "email"
        | "phone"
        | "alias"
        | "userIdentifier"
        | "position"
        | "externalVkorgID"
        | "externalVtwegID"
        | "status"
        | "source"
        | "domain"
        | "accountType"
        | "description"
        | "contextMenu"
        | "actions"
        | "roleGroup"
        | "role"
        | "name"
        | "userCount"
        | "permissionCount"
        | "group"
        | "identifier"
        | "serviceGroup"
        | "roleCount"
        | "toggle"
        | "hasDefaultOCISettings"
        | "vkoShortName"
        | "linkedUserIndication"
        | "companyName"
        | "salesRepresentative"
        | "defaultRole";
    label?: MessageIDS;
    sort?: boolean;
    style?: React.CSSProperties;
    allowSort?: boolean;
};

type TableHeaderProps = {
    headerFields: TableHeaderConfig[];
    orderDir?: OrderDir;
    orderBy?: string;
    hasCheckbox?: boolean;
    allowSort?: boolean;
    onChangeOrder?: (column: string) => void;
};

export const TableHeader = ({
    headerFields,
    orderBy,
    orderDir,
    allowSort,
    onChangeOrder,
    hasCheckbox,
}: TableHeaderProps) => {
    const handleChangeOrder = (column: string) => {
        if (onChangeOrder) {
            onChangeOrder(column);
        }
    };

    return (
        <TableHead>
            <TableRow>
                {headerFields.map(({ column, label, sort, style }) => {
                    const sortDisabled = sort === false;

                    const Cell = hasCheckbox ? CmsTableCellWithCheckbox : CmsTableCell;

                    return (
                        <Cell key={column} style={style}>
                            {label && (
                                <>
                                    {!allowSort && <span>{t(label)}</span>}
                                    {allowSort && (
                                        <CmsTableSortLabel
                                            data-id={"sort_label_" + column}
                                            disabled={sortDisabled}
                                            hideSortIcon={sortDisabled}
                                            active={orderBy === column}
                                            direction={orderBy === column ? orderDir : "asc"}
                                            onClick={() => handleChangeOrder(column)}
                                        >
                                            {t(label)}
                                        </CmsTableSortLabel>
                                    )}
                                </>
                            )}
                        </Cell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
};
