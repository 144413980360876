import { t } from "../i18n/util";
import { API } from "../network/API";
import { generalStore } from "../stores/GeneralStore";
import { useConfirmationDialog } from "./useConfirmationDialog";

export function useDeleteRoleGroupDialog(roleGroupId?: string, reload?: () => void) {
    return useConfirmationDialog({
        title: t("roleGroup.action.deleteDialog.title"),
        message: t("roleGroup.action.deleteDialog.message"),
        submitLabel: t("roleGroup.action.deleteDialog.submitLabel"),
        cancelLabel: t("common.no"),
        onSubmit: async () => {
            if (!roleGroupId) {
                return;
            }

            try {
                generalStore.isLoading = true;
                await API.deleteRoleGroup(roleGroupId);
                console.log("deleteRoleGroup");
                generalStore.setSuccessMessage(t("success.deleteRoleGroup"));
                reload?.();
            } catch (error) {
                generalStore.setError(t("error.deleteRoleGroup"), error);
            } finally {
                generalStore.isLoading = false;
            }
        },
    });
}
