import { t } from "../i18n/util";
import { useMultiSelectionFilter } from "./useMultiSelectionFilter";

export const useOciFilter = () => {
    return useMultiSelectionFilter<"standard" | "custom">({
        name: "oci",
        label: t("common.oci"),
        options: [
            {
                value: "standard",
                label: t("common.standard"),
            },
            {
                value: "custom",
                label: t("common.custom"),
            },
        ],
    });
};
