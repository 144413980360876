import * as React from "react";
import { t } from "../i18n/util";
import { API } from "../network/API";
import { CMSCustomer } from "../network/APITypes";
import { generalStore } from "../stores/GeneralStore";

export const useCustomer = (customerId?: string) => {
    const [response, setResponse] = React.useState<CMSCustomer | null>(null);
    const [isLoading, setIsLoading] = React.useState(false);

    const load = React.useCallback(async () => {
        if (!customerId) {
            setResponse(null);
            return;
        }

        generalStore.setIsLoading(true);
        setIsLoading(true);
        try {
            const res = await API.getCustomer(customerId);
            setResponse(res);
        } catch (error) {
            generalStore.setError(t("error.loadCustomer"), error);
        } finally {
            generalStore.setIsLoading(false);
            setIsLoading(false);
        }
    }, [customerId]);

    React.useEffect(() => {
        load();
    }, [load]);

    return { customer: response, reloadCustomer: load, isLoading };
};
