import * as React from "react";
import { useDeepCompareCallback } from "use-deep-compare";
import { t } from "../i18n/util";
import { API } from "../network/API";
import { RoleGroupRoles } from "../network/APITypes";
import { generalStore } from "../stores/GeneralStore";

export const useRoleGroup = ({ id, loadCondition = true }: { id?: string; loadCondition?: boolean } = {}) => {
    const [roleGroup, setRoleGroup] = React.useState<RoleGroupRoles | null>(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isInitialized, setIsInitialized] = React.useState(false);

    const load = useDeepCompareCallback(async () => {
        if (!id) {
            return;
        }

        generalStore.setIsLoading(true);
        setIsLoading(true);
        try {
            const res = await API.getRoleGroup(id);
            setRoleGroup(res);
            setIsInitialized(true);
        } catch (error) {
            generalStore.setError(t("error.loadRoleGroup"), error);
            setRoleGroup(null);
        } finally {
            generalStore.setIsLoading(false);
            setIsLoading(false);
        }
    }, [id]);

    React.useEffect(() => {
        if (loadCondition) {
            load();
        }
    }, [load, loadCondition]);

    const resetRoleGroup = React.useCallback(() => {
        setRoleGroup(null);
        setIsInitialized(false);
    }, []);

    return {
        roleGroup,
        reloadRoleGroup: load,
        isLoading,
        isInitialized,
        resetRoleGroup,
    };
};
