import { Field } from "formik";
import { GetOCIOverridesResponse } from "../../../network/APITypes";
import { CustomAutocomplete } from "../../ui/CustomAutocomplete";
import { MessageIDS, t } from "../../../i18n/util";
import { MAX_OCI_CONSTANT_LENGTH } from "../../../config";

const overrideOptions: { value: string; label: MessageIDS }[] = [
    { value: "BaseArticle.Name", label: "oci.base_article.name" },
    { value: "BaseArticle.Matchcode", label: "oci.base_article.matchcode" },
    { value: "Quantity", label: "oci.quantity" },
    { value: "BaseArticle.UnitOfSale", label: "oci.base_article.unit_of_sale" },
    { value: "BaseArticle.BasePrice.Amount", label: "oci.base_article.base_price.amount" },
    { value: "BaseArticle.BasePrice.Unit", label: "oci.base_article.base_price.unit" },
    { value: "BaseArticle.Manufacturer.ShortName", label: "oci.base_article.manufacturer.short_name" },
    { value: "BaseArticle.Manufacturer.Name", label: "oci.base_article.manufacturer.name" },
    { value: "BaseArticle.ManufacturerArticleNumber", label: "oci.base_article.manufacturer_article_number" },
    { value: "BaseArticle.ImageURL", label: "oci.base_article.image_url" },
    { value: "BaseArticle.EClassNumber", label: "oci.base_article.eclass_number" },
    { value: "OrderPosition.DeliveryTimeDays", label: "oci.order_position.delivery_time_days" },
    { value: "Prices.NetPriceWithSurcharges", label: "oci.prices.net_price_with_surcharges" },
    { value: "Prices.NetPrice", label: "oci.prices.net_price" },
    { value: "Prices.NetPriceWithSurchargesPerPiece", label: "oci.prices.net_price_with_surcharges_per_piece" },
    { value: "Prices.GrossPricePerPiece", label: "oci.prices.gross_price_per_piece" },
    { value: "Prices.NetPricePerPiece", label: "oci.prices.net_price_per_piece" },
    { value: "BaseArticle.UnitOfSaleSAP", label: "oci.base_article.unit_of_sale_sap" },
];
// hardcoded default fields until a better solution was found
const defaultFieldNames = [
    "NEW_ITEM-DESCRIPTION[n]",
    "NEW_ITEM-MATNR[n]",
    "NEW_ITEM-QUANTITY[n]",
    "NEW_ITEM-UNIT[n]",
    "NEW_ITEM-PRICE[n]",
    "NEW_ITEM-CURRENCY[n]",
];

export const isOciOverrideValue = (value?: string) => {
    return value ? overrideOptions.map((option) => option.value).includes(value.trim()) : false;
};

// Use this to convert OCI key to Formik compatible names. Formik does not like [, ], (, )
export const ociToFormikName = (ociKey: string) => {
    return ociKey.replace("[", "+").replace("]", "*");
};

export const CustomerDetailsOCIFields = ({ overrides }: { overrides: GetOCIOverridesResponse }) => {
    return (
        <>
            {overrides.ociOverrides
                .sort((a, b) => {
                    const aIsDefault = defaultFieldNames.includes(a.field);
                    const bIsDefault = defaultFieldNames.includes(b.field);

                    if (aIsDefault && bIsDefault) {
                        return a.field.localeCompare(b.field);
                    }

                    if (aIsDefault) return -1;

                    if (bIsDefault) return 1;

                    return a.field.localeCompare(b.field);
                })
                .map((field) => {
                    // Default value have no ID, but an override
                    const isDefault = !field.id && (field.constantValue || field.valueKey);
                    return (
                        <Field
                            key={field.field}
                            component={CustomAutocomplete}
                            label={field.field + (isDefault ? t("oci.default") : "")}
                            name={ociToFormikName(field.field)}
                            type={"input"}
                            maxLength={MAX_OCI_CONSTANT_LENGTH}
                            options={overrideOptions.map((option) => ({ value: option.value, label: t(option.label) }))}
                            useFreeSoloValue
                        />
                    );
                })}
        </>
    );
};
