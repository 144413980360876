import { useParams } from "react-router";
import { useDeleteRoleGroupDialog } from "../../../hooks/useDeleteRoleGroupDialog";
import { useRoleGroup } from "../../../hooks/useRoleGroup";
import { useRoleGroupDetailsForm } from "../../../hooks/useRoleGroupDetailsForm";
import { t } from "../../../i18n/util";
import { generalStore } from "../../../stores/GeneralStore";
import { pushRoute } from "../../app/router/history";
import { CardHeader } from "../../ui/CardHeader";
import { DetailsHeader } from "../../ui/DetailsHeader";
import { InfoBlock } from "../../ui/InformationRow";
import { Card, CardContainer } from "../../ui/Primitives";
import { Icon } from "../../util/Icon";
import { SettingsRoutes } from "../router/SettingsRoutes";
import { CardAction } from "../../ui/CardAction";
import { Colors } from "../../util/Colors";
import { EmptyState } from "../../ui/EmptyState";
import { useManageRolesDialog } from "../../../hooks/useManageRolesDialog";

export const SettingsRoleGroupDetailsSite = () => {
    const { roleGroupId } = useParams<{ roleGroupId: string }>();

    const { roleGroup, reloadRoleGroup } = useRoleGroup({ id: roleGroupId });

    const details = useRoleGroupDetailsForm({
        roleGroup,
        onSubmit: () => {
            reloadRoleGroup();
        },
    });

    const handleBack = () => {
        pushRoute(generalStore.lastLocation ?? SettingsRoutes.ROLES);
    };

    const deleteDialog = useDeleteRoleGroupDialog(roleGroup?.id, handleBack);

    const manageRolesDialog = useManageRolesDialog({
        title: t("manageRoles.title"),
        roleGroupId,
        onSubmit() {
            reloadRoleGroup();
        },
    });

    if (!roleGroup) {
        return null;
    }

    const hasRoles = roleGroup.roles && roleGroup.roles.length > 0;

    return (
        <div style={{ display: "flex", justifyContent: "center", marginTop: 32 }}>
            <div>
                <DetailsHeader title={roleGroup?.name ?? ""} onBack={handleBack} />

                <CardContainer>
                    <Card style={{ width: 596 }}>
                        <CardHeader title={t("roleGroupDetails.groupInformation")} onEdit={details.open} />
                        <InfoBlock
                            rows={[
                                { label: t("common.name"), value: roleGroup.name },
                                { label: t("common.description"), value: roleGroup.description },
                            ]}
                        />
                        {details.component}
                        <CardAction title={t("button.delete")} color={Colors.RED} onClick={deleteDialog.open}>
                            {deleteDialog.component}
                        </CardAction>
                    </Card>
                    <Card>
                        <CardHeader title={t("common.roles")} onEdit={manageRolesDialog.open} />
                        {hasRoles ? (
                            roleGroup.roles?.map((role) => (
                                <div key={role.id} style={{ display: "flex", alignItems: "center", gap: 4 }}>
                                    <Icon name="checkmark" />
                                    <span className="body1">{role.name}</span>
                                </div>
                            ))
                        ) : (
                            <EmptyState iconName="sadFace" title={t("roleGroupDetails.noRoles")} />
                        )}
                        {manageRolesDialog.component}
                    </Card>
                </CardContainer>
            </div>
        </div>
    );
};
