import { Routes } from "../../app/router/Routes";

const prefix = (route: string) => Routes.SETTINGS + route;

export const SettingsRoutes = {
    ROOT: prefix(""),
    DOMAIN_MANAGEMENT: prefix("/domains"),
    ROLES: prefix("/roles"),
    ROLE_DETAILS: prefix("/roles/:roleId"),
    ROLE_GROUPS: prefix("/role-groups"),
    ACCOUNT_TYPES: prefix("/account-types"),
    ROLE_GROUP_DETAILS: prefix("/role-groups/:roleGroupId"),
};
