import * as React from "react";
import { Icon } from "../util/Icon";
import * as _ from "lodash";
import { Colors } from "../util/Colors";
import { Close } from "@mui/icons-material";
import { TextField, InputAdornment } from "@mui/material";
import { SEARCH_DEBOUNCE_MS } from "../../config";
import { DebouncedFunc } from "lodash";

export const SearchField = ({
    placeholder,
    value,
    style,
    debounceMs = SEARCH_DEBOUNCE_MS,
    fullWidth,
    onChange,
    onFocus,
    onEnter,
    readOnly,
    fetchOnDeletion,
}: {
    placeholder?: string;
    value?: string;
    style?: React.CSSProperties;
    debounceMs?: number;
    fullWidth?: boolean;
    onChange: (value: string) => void;
    onFocus?: () => void;
    onEnter?: (value: string, event?: React.KeyboardEvent<HTMLInputElement>) => void;
    readOnly?: boolean;
    fetchOnDeletion?: boolean;
}) => {
    const [currentValue, setCurrentValue] = React.useState(value ?? "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onSearchChanged = React.useCallback(
        debounceMs
            ? _.debounce((value: string) => {
                  onChange(value);
              }, debounceMs)
            : onChange,
        [onChange, debounceMs],
    );

    const isDebouncedFunc = (
        func: _.DebouncedFunc<(value: string) => void> | ((value: string) => void),
    ): func is DebouncedFunc<(value: string) => void> => {
        return typeof (func as DebouncedFunc<(value: string) => void>).cancel === "function";
    };

    React.useEffect(() => {
        return () => {
            if (isDebouncedFunc(onSearchChanged)) {
                onSearchChanged.cancel();
            }
        };
    }, [debounceMs, onSearchChanged]);

    const handleDefaultChange = (textFieldValue: string) => {
        setCurrentValue(textFieldValue);
        onSearchChanged(textFieldValue);
    };

    const handleCustomChange = (textFieldValue: string) => {
        //Cancel Debounce if there is input after being empty
        if (value && currentValue.length === 0 && isDebouncedFunc(onSearchChanged)) {
            onSearchChanged.cancel();
        }

        // Call onSearchChanged if text field changes from having content to being empty
        // and if search parameter is present
        if (value && currentValue.length > 0 && textFieldValue.length === 0) {
            onSearchChanged(textFieldValue);
        }

        setCurrentValue(textFieldValue);
    };
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (fetchOnDeletion) {
            handleCustomChange(event.target.value);
        } else {
            handleDefaultChange(event.target.value);
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if ((event.keyCode === 13 || event.which === 13) && onEnter) {
            onEnter(currentValue, event);
        }
    };

    const handleReset = () => {
        setCurrentValue("");
        onChange("");
    };

    return (
        <TextField
            inputProps={{ maxLength: 500 }}
            onChange={handleChange}
            onFocus={onFocus}
            onKeyPress={handleKeyPress}
            variant="outlined"
            fullWidth={fullWidth}
            placeholder={placeholder}
            autoComplete="off"
            value={currentValue}
            InputProps={{
                readOnly,
                startAdornment: (
                    <InputAdornment position="start">
                        <Icon name="search" style={{ color: Colors.BODY_2 }} />
                    </InputAdornment>
                ),
                endAdornment: currentValue ? (
                    <InputAdornment position="end" style={{ cursor: "pointer" }} onClick={handleReset}>
                        <Close color="primary" fontSize="small" />
                    </InputAdornment>
                ) : null,
            }}
            style={style}
        />
    );
};
