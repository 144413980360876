import { IconButton, RadioGroup, FormControlLabel, Radio, Button } from "@mui/material";
import * as React from "react";
import { t } from "../../i18n/util";
import { Colors } from "../util/Colors";
import { Icon } from "../util/Icon";

type SelectionFormContainerProps = {
    title: string;
    onSubmit: () => void;
    onClose: () => void;
    children?: React.ReactNode;
};

export const SelectionFormContainer = ({ title, onSubmit, onClose, children }: SelectionFormContainerProps) => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                overflow: "hidden",
                height: "100%",
            }}
        >
            <div>
                <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
                    <IconButton onClick={onClose}>
                        <Icon name="arrowBack" color={Colors.GREY_700} />
                    </IconButton>
                    <h4>{title}</h4>
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1, overflow: "auto", marginTop: 32 }}>
                {children}
            </div>
            <Button variant="contained" fullWidth style={{ marginTop: 32 }} onClick={onSubmit}>
                {t("button.save")}
            </Button>
        </div>
    );
};
