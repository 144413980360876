import { Field, getIn, useFormikContext } from "formik";
import * as React from "react";
import { useCoporatePositionSelection } from "../../../hooks/useCorporatePositionSelection";
import {
    DistributionChannelsGroup,
    useDistributionChannelSelection,
} from "../../../hooks/useDistributionChannelSelection";
import { useRegionSelection } from "../../../hooks/useRegionSelection";
import { t } from "../../../i18n/util";
import {
    AccountType,
    DistributionChannel,
    Region,
    SalesArea,
    UserPreferences,
    UserRequest,
} from "../../../network/APITypes";
import { FieldDefinition, Option, Section } from "../../../types";
import { CustomInputField } from "../../ui/CustomInputField";
import { CustomSectionButton } from "../../ui/CustomSectionButton";
import { CustomSelect } from "../../ui/CustomSelect";
import { accountTypeOptions } from "../../util/AccountTypes";
import { useRoles } from "../../../hooks/useRoles";
import { compact } from "lodash";
import { CustomPhoneInput } from "../../ui/CustomPhoneInput";
import { useCustomer } from "../../../hooks/useCustomer";
import _ from "lodash";
import { useDistributionChannels } from "../../../hooks/useDistributionChannels";
import { useRegions } from "../../../hooks/useRegions";

type UserDetailFieldsProps = {
    user?: UserRequest;
    userPreferences?: UserPreferences[];
};

export const UserDetailsFields = ({ user, userPreferences }: UserDetailFieldsProps) => {
    const corporatePositionName = "corporatePositionID";
    const distributionChannelName = "distributionChannelID";
    const regionName = "regionID";
    const [accountType, setAccountType] = React.useState<AccountType>();

    const { values, setFieldValue, touched } = useFormikContext<any>();

    const { rolesResponse } = useRoles({ accountTypes: accountType ? [accountType] : undefined }, !!accountType);
    const { customer } = useCustomer(getIn(values, "customerID"));
    const { distributionChannels } = useDistributionChannels();
    const regions = useRegions();

    const getDistinctRegionsFromSalesAreas = React.useCallback(() => {
        return (customer?.salesAreas ?? [])
            .map(
                (area): Region => ({
                    description: area.regionDescription,
                    externalID: area.regionExternalId,
                    id: area.regionId ?? "",
                }),
            )
            .filter((region, index, self) => index === self.findIndex((r) => r.externalID === region.externalID));
    }, [customer?.salesAreas]);

    const getDistributionChannelsFromSelectedRegion = React.useCallback(() => {
        const groupedSalesAreasByRegionId = _.groupBy(customer?.salesAreas, "regionId");

        return (groupedSalesAreasByRegionId[getIn(values, "regionID") ?? ""] ?? []).map(
            (salesArea: SalesArea): DistributionChannel => ({
                description: salesArea.distributionChannelDescription,
                externalID: salesArea.distributionChannelExternalId,
                id: salesArea.distributionChannelId ?? "",
            }),
        );
    }, [customer?.salesAreas, values]);

    const getDistributionChannelsGroups = React.useCallback(() => {
        const selectedRegionDistributionChannelsGroup = {
            distributionChannels: getDistributionChannelsFromSelectedRegion(),
        };

        const konsiDistributionChannelsGroup: DistributionChannelsGroup = {
            distributionChannels:
                distributionChannels?.filter((channel) => channel.externalID.toLocaleLowerCase().startsWith("w")) ?? [],
            title: t("screen.userDetails.edit.form.distributionChannel.group.konsi.title"),
            isCollapsible: true,
        };

        return [selectedRegionDistributionChannelsGroup, konsiDistributionChannelsGroup];
    }, [distributionChannels, getDistributionChannelsFromSelectedRegion]);

    React.useEffect(() => {
        if (user) {
            return;
        }
        const defaultSalesArea = customer?.salesAreas?.find((salesArea) => salesArea.isDefault);
        if (!getIn(touched, "regionID")) {
            setFieldValue("regionID", defaultSalesArea?.regionId);
            setFieldValue("distributionChannelID", defaultSalesArea?.distributionChannelId);
        }
    }, [customer?.salesAreas, setFieldValue, touched, user, values.regionID]);
    // Account type changed -> triggers loading of matching roles in useRoles() -> once loading is done, set default role
    React.useEffect(() => {
        if (accountType && rolesResponse?.roles) {
            const roles = rolesResponse?.roles;
            // Verify roles for new account type have been loaded
            if (roles.length > 0 && roles[0].accountType === accountType) {
                const defaultRole = roles.find((role) => role.isDefaultForAccountType);
                if (defaultRole) {
                    setFieldValue("roleId", defaultRole.id);
                }
            }
        }
    }, [accountType, rolesResponse?.roles, setFieldValue]);

    React.useEffect(() => {
        if (!user) {
            setFieldValue("accountType", "");
            setFieldValue("roleId", "");
        }
    }, [setFieldValue, user]);

    const distributorId = user?.distributorID ?? getIn(values, "distributorID");

    const corporatePositionSelection = useCoporatePositionSelection({
        distributorId,
        name: corporatePositionName,
        hideBackdrop: true,
    });

    const distributionChannelSelection = useDistributionChannelSelection({
        name: distributionChannelName,
        hideBackdrop: true,
        distributionChannelsGroups: user
            ? [{ distributionChannels: distributionChannels ?? [] }]
            : getDistributionChannelsGroups(),
    });

    const regionSelection = useRegionSelection({
        name: regionName,
        hideBackdrop: true,
        regions: user ? regions ?? [] : getDistinctRegionsFromSalesAreas(),
        isUserEdit: !!user,
    });

    const titleOptions: Option[] = [
        { label: t("common.male.title"), value: "Herr" },
        { label: t("common.female.title"), value: "Frau" },
    ];
    const userPreferencesOptions: Option[] | undefined = userPreferences?.map((userPreference) => {
        return { label: userPreference.name, value: userPreference.id } as Option;
    });

    const fields: FieldDefinition[] = compact([
        {
            label: "screen.userDetails.edit.form.title",
            name: "salutation",
            component: CustomSelect,
            options: titleOptions,
        },
        {
            label: "screen.userDetails.edit.form.firstName",
            name: "firstName",
        },
        {
            label: "screen.userDetails.edit.form.lastName",
            name: "lastName",
        },
        {
            label: "screen.userDetails.edit.form.accountType",
            name: "accountType",
            component: CustomSelect,
            options: accountTypeOptions,
            onChange: (event) => {
                // New account type selected -> clear role and trigger prefill with new default role in effect above
                setFieldValue("roleId", "");
                setAccountType(event.target.value as AccountType);
            },
        },
        userPreferences
            ? {
                  label: "screen.userDetails.edit.form.userProfile",
                  name: "userPreferencesId",
                  component: CustomSelect,
                  options: userPreferencesOptions,
              }
            : undefined,
        !user
            ? {
                  label: "common.role",
                  name: "roleId",
                  component: CustomSelect,
                  options:
                      rolesResponse?.roles?.map((role) => ({
                          label: role.name,
                          value: role.id,
                      })) ?? [],
                  disabled: !accountType,
              }
            : undefined,
        {
            label: "screen.userDetails.edit.form.corporatePosition",
            name: corporatePositionName,
            component: CustomSectionButton,
            options: corporatePositionSelection.options,
        },
        {
            label: "screen.userDetails.edit.form.region",
            name: "regionID",
            component: CustomSectionButton,
            options: regionSelection.options,
        },
        {
            label: "screen.userDetails.edit.form.vtChannel",
            name: distributionChannelName,
            component: CustomSectionButton,
            options: distributionChannelSelection.options,
            disabled: !getIn(values, "regionID"),
        },
        {
            label: "screen.userDetails.edit.form.email",
            name: "email",
            type: "email",
            // https://frauenthal.atlassian.net/wiki/spaces/FBPF/pages/15990810/Benutzernamen+Emailadresse+eines+Users+ndern
            // "Emailadressen von gesperrten Usern können nicht geändert werden"
            disabled: user?.status === "deactivated",
        },
        {
            label: "screen.userDetails.edit.form.phone",
            name: "phone",
            component: CustomPhoneInput,
            showHint: true,
        },
    ]);

    const handleClickSection = (section: Section | null) => {
        switch (section) {
            case corporatePositionName: {
                corporatePositionSelection.open();
                return;
            }
            case distributionChannelName: {
                distributionChannelSelection.open();
                return;
            }
            case regionName: {
                regionSelection.open();
                return;
            }
        }
    };

    return (
        <>
            {fields.map((field) => {
                return (
                    <Field
                        key={field.name}
                        component={field.component ?? CustomInputField}
                        label={t(field.label)}
                        name={field.name}
                        type={field.type ?? "input"}
                        required={field.required}
                        options={field.options}
                        disabled={field.disabled}
                        onClick={() => handleClickSection(field.name as Section)}
                        onChange={field.onChange}
                        showHint={field.showHint}
                    />
                );
            })}
            {corporatePositionSelection.component}
            {distributionChannelSelection.component}
            {regionSelection.component}
        </>
    );
};
