import { Button } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { observer } from "mobx-react";
import * as React from "react";
import * as Yup from "yup";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { authStore } from "../../../stores/AuthStore";
import { generalStore } from "../../../stores/GeneralStore";
import { pushRoute } from "../../app/router/history";
import { Routes } from "../../app/router/Routes";
import { CustomInputField } from "../../ui/CustomInputField";
import { Colors } from "../../util/Colors";
import { AuthContainerSite } from "./AuthContainerSite";

type ForgotPasswordValues = {
    email: string;
};

export const AuthForgotPasswordEmailSite = observer(() => {
    const handleSubmit = async (model: ForgotPasswordValues) => {
        try {
            generalStore.setIsLoading(true);
            await API.postForgotPassword(model.email);
            authStore.forgotPasswordEmail = model.email;
            pushRoute(Routes.FORGOT_PASSWORD.CONFIRM);
        } catch (error) {
            generalStore.setError(t("screen.forgotPassword.email.error"), error);
        } finally {
            generalStore.setIsLoading(false);
        }
    };

    return (
        <AuthContainerSite>
            <h1>{t("screen.forgotPassword.email.title")}</h1>
            <p style={{ color: Colors.BODY_2, marginTop: 8 }}>{t("screen.forgotPassword.email.description")}</p>
            <Formik
                initialValues={{
                    email: "",
                }}
                onSubmit={handleSubmit}
                validationSchema={Yup.object().shape({
                    email: Yup.string()
                        .email(t("screen.login.form.validation.error.email"))
                        .required(t("screen.login.form.validation.error.email"))
                        .trim(),
                })}
            >
                {({ isSubmitting }) => (
                    <Form style={{ marginTop: 24 }} noValidate>
                        <Field
                            component={CustomInputField}
                            label={t("common.email")}
                            name="email"
                            type="email"
                            autoComplete="username"
                        />
                        <Button
                            variant="contained"
                            fullWidth
                            disabled={isSubmitting}
                            type="submit"
                            style={{ marginTop: 56 }}
                        >
                            {t("screen.forgotPassword.email.submit")}
                        </Button>
                    </Form>
                )}
            </Formik>
        </AuthContainerSite>
    );
});
