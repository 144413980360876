import { UnregisterCallback } from "history";
import * as React from "react";
import { useHistory } from "react-router";
import { useDeepCompareEffect } from "use-deep-compare";
import { pushRoute } from "../components/app/router/history";
import { t } from "../i18n/util";

export const useBlockRouting = (blockRouting?: boolean, onBlock?: () => void) => {
    const [newRoute, setNewRoute] = React.useState("");

    const history = useHistory();

    const unblockRef = React.useRef<UnregisterCallback | null>(null);

    React.useEffect(() => {
        const handleBeforeUnload = (event: any) => {
            if (blockRouting) {
                const warningMessage = t("warning.closeWindow");
                event = event || window.event;
                event.returnValue = warningMessage;
                return warningMessage;
            }
        };

        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => window.removeEventListener("beforeunload", handleBeforeUnload);
    }, [blockRouting]);

    useDeepCompareEffect(() => {
        if (blockRouting) {
            unblockRef.current = history.block((location) => {
                setNewRoute(location.pathname);
                if (onBlock) {
                    onBlock();
                }

                return false;
            });
        }

        return () => {
            if (unblockRef.current) {
                unblockRef.current();
            }
        };
    }, [history, blockRouting]);

    const unblockRouting = () => {
        if (unblockRef.current) {
            unblockRef.current();
        }
        pushRoute(newRoute);
    };

    return {
        unblockRouting,
    };
};
