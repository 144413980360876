import { IconButton } from "@mui/material";
import fileSize from "filesize";
import * as React from "react";
import { generalStore } from "../../stores/GeneralStore";
import { Colors } from "../util/Colors";
import { Icon } from "../util/Icon";
import { FileName } from "./Primitives";

export const FileLine = ({ file, onRemove }: { file: File; onRemove: (file: File) => void }) => (
    <div
        style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: Colors.GREY_50_BACKGROUND,
            border: `1px solid ${Colors.PRIMARY_500}`,
            borderRadius: 8,
            padding: "16px 30px",
        }}
    >
        <div style={{ display: "flex", alignItems: "center" }}>
            <Icon name="file" size={48} />
            <div style={{ marginLeft: 12 }}>
                <FileName>{file.name}</FileName>
                <p
                    style={{
                        fontSize: 12,
                        color: Colors.BODY_2,
                    }}
                >
                    {fileSize(file.size, { locale: generalStore.locale })}
                </p>
            </div>
        </div>
        <IconButton onClick={() => onRemove(file)}>
            <Icon name="close" color={Colors.GREY_700} />
        </IconButton>
    </div>
);
