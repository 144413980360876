import { useState } from "react";
import { t } from "../i18n/util";
import { RoleGroup, RoleGroupPayload } from "../network/APITypes";
import { useManageRolesDialog } from "./useManageRolesDialog";
import { useRoleGroupDetailsForm } from "./useRoleGroupDetailsForm";
import { useStepFlow } from "./useStepFlow";
import { RoleGroupDetailsFormData } from "../components/forms/addRoleGroup/RoleGroupDetailsForm";
import { API } from "../network/API";
import { generalStore } from "../stores/GeneralStore";

const STEP_ROLE_GROUP_DETAILS = 0;
const STEP_CHOOSE_ROLES = 1;
const STEP_COUNT = 2;

export const useAddRoleGroupFlow = ({ reloadRoleGroups }: { reloadRoleGroups?: () => void } = {}) => {
    const flow = useStepFlow(STEP_COUNT);
    const [open, setOpen] = useState(false);
    const [roleGroup, setRoleGroup] = useState<RoleGroup>();
    const [detailsFormData, setDetailsFormData] = useState<RoleGroupDetailsFormData>();

    const roleGroupDetailsForm = useRoleGroupDetailsForm({
        onSubmit(result?: RoleGroupDetailsFormData) {
            if (!result) {
                return;
            }

            setDetailsFormData(result);
            manageRolesDialog.open();
            flow.next();
        },
        roleGroup,
        stepper: {
            activePage: STEP_ROLE_GROUP_DETAILS,
            pages: STEP_COUNT,
            description: t("addRoleGroup.step.roleGroupInformation"),
        },
        persistOnSubmit: false,
    });

    const manageRolesDialog = useManageRolesDialog({
        title: t("manageRoles.title"),
        roleGroupId: roleGroup?.id,
        async onSubmit(selectedRoleIds) {
            if (selectedRoleIds) {
                try {
                    generalStore.isLoading = true;
                    const result = await API.postRoleGroup(detailsFormData as RoleGroupPayload);
                    await API.putRoleGroupRoles(result.id, selectedRoleIds);
                    generalStore.setSuccessMessage(t("success.addRoleGroup"));
                    setOpen(false);
                    reloadRoleGroups?.();
                } catch (error) {
                    generalStore.setError(t("error.addRoleGroup"), error);
                } finally {
                    generalStore.isLoading = false;
                    return true;
                }
            }
        },
        stepper: {
            activePage: STEP_CHOOSE_ROLES,
            pages: STEP_COUNT,
            description: t("addRoleGroup.step.addRoles"),
        },
    });

    const handleOpen = () => {
        setOpen(true);
        setRoleGroup(undefined);
        flow.reset();
        roleGroupDetailsForm.open();
    };

    let component = roleGroupDetailsForm.component;
    if (flow.step === STEP_CHOOSE_ROLES) {
        component = manageRolesDialog.component;
    }

    return {
        open: handleOpen,
        component: open ? component : null,
    };
};
