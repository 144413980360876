import * as React from "react";
import { useDeepCompareCallback } from "use-deep-compare";
import { t } from "../i18n/util";
import { API } from "../network/API";
import { BaseCustomer } from "../network/APITypes";
import { generalStore } from "../stores/GeneralStore";
import { GetCustomersOptions } from "../types";

export const useDistributorCustomers = (distributorId: string, options?: GetCustomersOptions) => {
    const [response, setResponse] = React.useState<BaseCustomer[] | null>(null);

    const load = useDeepCompareCallback(async () => {
        generalStore.setIsLoading(true);

        try {
            const res = await API.getDistributorCustomers(distributorId, options);
            setResponse(res.customers ?? []);
        } catch (error) {
            generalStore.setError(t("error.loadCustomers"), error);
        } finally {
            generalStore.setIsLoading(false);
        }
    }, [distributorId, options]);

    React.useEffect(() => {
        load();
    }, [load]);

    return { customers: response, reloadCustomers: load };
};
