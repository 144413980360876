import { Checkbox, Skeleton, TableBody, TableRow } from "@mui/material";
import { CmsTableCell, CmsTableCellWithCheckbox } from "./Primitives";
import { Colors } from "../util/Colors";
import { TableCellSkeleton } from "./TableCellSkeleton";

export const TableBodySkeleton = ({ columns, rows }: { columns: number; rows: number }) => {
    return (
        <TableBody>
            {[...Array(rows)].map((_, index) => {
                return (
                    <TableRow key={index}>
                        {[...Array(columns)].map((_, index) => {
                            return index === 0 ? (
                                <TableCellSkeleton
                                    CellComponent={CmsTableCellWithCheckbox}
                                    padding="checkbox"
                                    key={index}
                                    variant="rounded"
                                >
                                    <Checkbox size="small" />
                                </TableCellSkeleton>
                            ) : (
                                <TableCellSkeleton
                                    CellComponent={CmsTableCell}
                                    skeletonStyle={{ height: 24 }}
                                    key={index}
                                />
                            );
                        })}
                    </TableRow>
                );
            })}
        </TableBody>
    );
};
