import { Route } from "react-router";
import { InterfacesRoutes } from "../../interfaces/router/InterfacesRoutes";
import { ExpoTicketsRoutes } from "../../expoTickets/router/ExpoTicketsRoutes";
import { ExpoTicketsContainerSite } from "../../expoTickets/sites/ExpoTicketsContainerSite";
import { RequestsRoutes } from "../../requests/router/RequestsRoutes";
import { RequestsContainerSite } from "../../requests/sites/RequestsContainerSite";
import { SettingsRoutes } from "../../settings/router/SettingsRoutes";
import { SettingsContainerSite } from "../../settings/sites/SettingsContainerSite";
import { Navigation } from "../../ui/Navigation";
import { Banners } from "../../ui/banner/Banners";
import { UsersRoutes } from "../../users/router/UsersRoutes";
import { UsersContainerSite } from "../../users/sites/UsersContainerSite";
import { CustomSwitch } from "./CustomSwitch";
import { InterfacesContainerSite } from "../../interfaces/sites/InterfacesContainerSite";

export const AuthorizedRouter = () => {
    return (
        <>
            <Navigation />
            <Banners />
            <CustomSwitch>
                <Route path={UsersRoutes.ROOT}>
                    <UsersContainerSite />
                </Route>
                <Route exact path={RequestsRoutes.ROOT}>
                    <RequestsContainerSite />
                </Route>
                <Route path={InterfacesRoutes.ROOT}>
                    <InterfacesContainerSite />
                </Route>
                <Route exact path={Object.values(SettingsRoutes)}>
                    <SettingsContainerSite />
                </Route>
                <Route exact path={ExpoTicketsRoutes.ROOT}>
                    <ExpoTicketsContainerSite />
                </Route>
            </CustomSwitch>
        </>
    );
};
