import { styled, InputAdornment, TextField, TextFieldProps, outlinedInputClasses } from "@mui/material";
import { FieldInputProps, FormikState, getIn } from "formik";
import * as React from "react";
import { Option } from "../../types";
import { Icon } from "../util/Icon";
import { FieldError } from "./FieldError";
import { color } from "@mui/system";

type IProps = TextFieldProps & {
    field: FieldInputProps<string>;
    onClick: () => void;
    showValidationErrorText?: boolean;
    options?: Option[];
    form: FormikState<any>;
    disabled?: boolean;
};

const CustomTextfield = styled(TextField)<TextFieldProps>(({ theme, disabled }) => ({
    [`& .${outlinedInputClasses.input}`]: {
        cursor: disabled ? "not-allowed" : "pointer",
    },
}));

export const CustomSectionButton = ({
    style,
    label,
    type,
    autoComplete,
    required,
    field,
    onClick,
    options,
    form,
    "aria-label": ariaLabel,
    showValidationErrorText = true,
    disabled,
}: IProps) => {
    const fieldError = getIn(form.errors, field.name);
    const showError = getIn(form.touched, field.name) && !!fieldError;

    return (
        <div style={style} onClick={disabled ? undefined : onClick}>
            <CustomTextfield
                label={required ? `${label} *` : label}
                value={options ? options.find((option) => option.value === field.value)?.label ?? "" : field.value}
                name={field.name}
                onChange={field.onChange}
                onBlur={field.onBlur}
                fullWidth
                type={type}
                autoComplete={autoComplete}
                error={showError}
                margin="dense"
                aria-label={ariaLabel}
                variant="outlined"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Icon name="chevronRight" />
                        </InputAdornment>
                    ),
                    readOnly: true,
                    style: {
                        cursor: disabled ? "not-allowed" : "pointer",
                    },
                    disabled: disabled,
                }}
                focused={false}
                disabled={disabled}
            />
            {showValidationErrorText && <FieldError>{showError ? fieldError : ""}</FieldError>}
        </div>
    );
};
