import * as React from "react";
import { t } from "../i18n/util";
import { API } from "../network/API";
import { GetOCIOverridesResponse } from "../network/APITypes";
import { generalStore } from "../stores/GeneralStore";

export const useCustomerOciOverrides = (customerId?: string) => {
    const [response, setResponse] = React.useState<GetOCIOverridesResponse | null>(null);
    const [isLoading, setIsLoading] = React.useState(false);

    const load = React.useCallback(async () => {
        if (!customerId) {
            setResponse(null);
            return;
        }

        generalStore.setIsLoading(true);
        setIsLoading(true);
        try {
            const res = await API.getCustomerOciOverrides(customerId);
            setResponse(res);
        } catch (error) {
            generalStore.setError(t("error.loadCustomerOciOverrides"), error);
        } finally {
            generalStore.setIsLoading(false);
            setIsLoading(false);
        }
    }, [customerId]);

    React.useEffect(() => {
        load();
    }, [load]);

    return {
        ociOverrides: response,
        reloadOciOverrides: load,
        isLoading,
        reset() {
            setResponse(null);
        },
    };
};
