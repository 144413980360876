import { Button } from "@mui/material";
import { styled } from "@mui/system";
import * as React from "react";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import { ACCEPTED_DOCUMENT_FORMATS, MAX_FILE_UPLOAD_SIZE } from "../../config";
import { t } from "../../i18n/util";
import { Colors } from "../util/Colors";
import { Icon } from "../util/Icon";
import { FileLine } from "./FileLine";
import { FileName, NeutralButton } from "./Primitives";

const UploadContainer = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    border: `1px dashed ${Colors.PRIMARY_500}`,
    backgroundColor: Colors.GREY_50_BACKGROUND,
    borderRadius: 8,
    padding: 24,
}));

export const FileUpload = ({
    title,
    fileSizeInfo,
    values,
    onChange,
    style,
}: {
    title: string;
    fileSizeInfo: string;
    values: File[];
    onChange: (files: File[]) => void;
    style?: React.CSSProperties;
}) => {
    const dropzone = useDropzone({
        accept: ACCEPTED_DOCUMENT_FORMATS,
        noKeyboard: true,
        noClick: true,
        maxSize: MAX_FILE_UPLOAD_SIZE,
        useFsAccessApi: false, // https://github.com/react-dropzone/react-dropzone/issues/1190
        maxFiles: 1,
        onDrop: async (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
            onChange(acceptedFiles);
        },
    });

    const handleRemoveFile = (file: File) => {
        const newFiles = values.filter((fileItem) => fileItem !== file);
        onChange(newFiles);
    };

    return (
        <div style={style}>
            {values.length === 0 && (
                <UploadContainer {...dropzone.getRootProps()}>
                    <input {...dropzone.getInputProps()} />
                    <Icon name="upload" size={48} />
                    <FileName style={{ marginTop: 12 }}>{title}</FileName>
                    <p className="body2" style={{ fontSize: 12, lineHeight: "16px", marginTop: 4 }}>
                        {fileSizeInfo}
                    </p>
                    <NeutralButton type="button" style={{ marginTop: 12 }} onClick={dropzone.open}>
                        {t("common.selectFile")}
                    </NeutralButton>
                </UploadContainer>
            )}
            {values.length > 0 && (
                <div style={{ marginTop: 16 }}>
                    {values.map((file) => (
                        <FileLine key={file.name} file={file} onRemove={handleRemoveFile} />
                    ))}
                </div>
            )}
        </div>
    );
};
