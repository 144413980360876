import { Link, LinkProps } from "@mui/material";
import * as React from "react";
import { styled } from "@mui/system";

const StyledLinkButton = styled(Link)({
    fontWeight: 600,
    lineHeight: "16px",
    maxWidth: "fit-content",
    padding: "4px 0px",
});

export const LinkButton = ({
    onClick,
    style,
    color,
    children,
    disabled,
    type,
}: LinkProps & { disabled?: boolean; type?: string }) => (
    <StyledLinkButton
        component="button"
        className="body1"
        color={color ?? "inherit"}
        style={{
            pointerEvents: disabled ? "none" : "auto",
            ...style,
        }}
        onClick={onClick}
        underline="none"
        disabled={disabled}
        type={type}
    >
        {children}
    </StyledLinkButton>
);
