import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Collapse,
    FormControlLabel,
    Radio,
    RadioGroup,
    styled,
} from "@mui/material";
import { getIn, useFormikContext } from "formik";
import * as React from "react";
import { t } from "../../../i18n/util";
import { DistributionChannel } from "../../../network/APITypes";
import { SelectionFormContainer } from "../../ui/SelectionFormContainer";
import { DistributionChannelsGroup } from "../../../hooks/useDistributionChannelSelection";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { display } from "@mui/system";

type DistributionChannelSelectionProps = {
    onClose: () => void;
    name: string;
    disabled?: boolean;
    channelsGroups: DistributionChannelsGroup[];
};

const formControlLabelWrapperStyling: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
};

const StyledAccordion = styled(Accordion)(() => ({
    "&.MuiAccordion-root": {
        boxShadow: "none",
    },
    "&:before": {
        display: "none",
    },
    "& .MuiAccordionSummary-root": {
        padding: "0 8px 0 0",
    },
    "& .MuiAccordionDetails-root": {
        ...formControlLabelWrapperStyling,
        padding: 0,
    },
}));

export const DistributionChannelSelection = ({ onClose, name, channelsGroups }: DistributionChannelSelectionProps) => {
    const { setFieldValue, values, setFieldTouched } = useFormikContext<any>();

    const initialValue = getIn(values, name);

    const [selectedChannel, setSelectedChannel] = React.useState<string>(initialValue ?? "");

    const handleChangeChannel = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedChannel(event.target.value);
    };

    const handleSubmit = () => {
        setFieldTouched(name, false);
        setFieldValue(name, selectedChannel);
        onClose();
    };

    return (
        <SelectionFormContainer
            title={t("screen.userDetails.edit.form.distributionChannel.title")}
            onSubmit={handleSubmit}
            onClose={onClose}
        >
            <RadioGroup name={name} value={selectedChannel} onChange={handleChangeChannel}>
                {channelsGroups.map((channelsGroup, index) =>
                    channelsGroup.isCollapsible ? (
                        <StyledAccordion key={index} disableGutters square>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                {channelsGroup.title ? <h4>{channelsGroup.title}</h4> : null}
                            </AccordionSummary>
                            <AccordionDetails>
                                {channelsGroup.distributionChannels.map((channel) => (
                                    <FormControlLabel
                                        key={channel.id}
                                        value={channel.id}
                                        name={name}
                                        control={<Radio />}
                                        label={
                                            <p>
                                                {channel.externalID} - {channel.description}
                                            </p>
                                        }
                                        style={{ height: 56 }}
                                    />
                                ))}
                            </AccordionDetails>
                        </StyledAccordion>
                    ) : (
                        <div style={formControlLabelWrapperStyling} key={index}>
                            {channelsGroup.title ? <h4>{channelsGroup.title}</h4> : null}
                            {channelsGroup.distributionChannels.map((channel) => (
                                <FormControlLabel
                                    key={channel.id}
                                    value={channel.id}
                                    name={name}
                                    control={<Radio />}
                                    label={
                                        <p>
                                            {channel.externalID} - {channel.description}
                                        </p>
                                    }
                                    style={{ height: 56 }}
                                />
                            ))}
                        </div>
                    ),
                )}
            </RadioGroup>
        </SelectionFormContainer>
    );
};
