import { head, tail } from "lodash";
import * as React from "react";
import { Icon } from "../../util/Icon";

type RemoveFilterButtonProps = {
    label: string;
    values?: string[];
    onRemove: () => void;
};

export const RemoveFilterButton = ({ label, values, onRemove }: RemoveFilterButtonProps) => {
    const firstValue = head(values);
    const restValues = tail(values);

    return (
        <div style={{ display: "flex", alignItems: "center", gap: 10, padding: "8px 16px" }}>
            <p className="body2" style={{ fontWeight: 600 }}>
                {label}
                {firstValue && <span>: {firstValue}</span>}
                {restValues && restValues.length > 0 && <span>&nbsp;(+{restValues.length})</span>}
            </p>
            <div>
                <Icon name="close" onClick={onRemove} />
            </div>
        </div>
    );
};
