import * as React from "react";
import { useDeepCompareCallback } from "use-deep-compare";
import { t } from "../i18n/util";
import { API } from "../network/API";
import { LockedStatus } from "../network/APITypes";
import { generalStore } from "../stores/GeneralStore";
import { GetCustomerStatusOptions } from "../types";

export const useCustomerStatus = (options: GetCustomerStatusOptions) => {
    const [response, setResponse] = React.useState<LockedStatus | null>(null);
    const [loading, setLoading] = React.useState(false);

    const load = useDeepCompareCallback(async () => {
        setLoading(true);

        try {
            const res = await API.getCustomerStatus(options);
            setResponse(res.lockedStatus);
        } catch (error) {
            generalStore.setError(t("error.loadUsers"), error);
        } finally {
            setLoading(false);
        }
    }, [options]);

    React.useEffect(() => {
        if (options.customerId && options.distributionChannelId && options.distributorId && options.regionId) {
            load();
        }
    }, [load, options.customerId, options.distributionChannelId, options.distributorId, options.regionId]);

    return { customerStatus: response, reloadStatus: load, loading };
};
