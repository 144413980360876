import { Button, Link, LinkProps, styled } from "@mui/material";
import { LinkProps as RouterDomLinkProps, Link as RouterDomLink } from "react-router-dom";
import { Field, Form, Formik, FormikErrors } from "formik";
import { observer } from "mobx-react";
import * as React from "react";
import * as Yup from "yup";
import { t } from "../../../i18n/util";
import { authStore } from "../../../stores/AuthStore";
import { generalStore } from "../../../stores/GeneralStore";
import { pushRoute } from "../../app/router/history";
import { UsersRoutes } from "../../users/router/UsersRoutes";
import { CustomInputField } from "../../ui/CustomInputField";
import { ImageLogo } from "../../util/Images";
import { Colors } from "../../util/Colors";
import { Routes } from "../../app/router/Routes";
import { RouterLink } from "../../ui/RouterLink";
import { AuthContainerSite } from "./AuthContainerSite";
interface ILoginValues {
    email: string;
    password: string;
}

const ErrorContainer = styled("div")({
    display: "flex",
    alignItems: "center",
    color: Colors.ERROR,
    fontSize: 16,
    height: 50,
    padding: "8px 0",
});

const StyledRouterLink = styled(RouterLink)({
    display: "inline-block",
    fontWeight: 600,
    fontSize: 14,
    marginTop: 8,
});

const getErrorMessage = (errors: FormikErrors<ILoginValues>) => {
    if (errors.email) {
        if (errors.password) {
            return t("screen.login.form.validation.error.emailAndPassword");
        }

        return errors.email;
    } else if (errors.password) {
        return errors.password;
    }

    return null;
};

export const AuthLoginSite = observer(() => {
    const [error, setError] = React.useState<string>();

    const handleSubmit = async (model: ILoginValues) => {
        generalStore.setIsLoading(true);
        setError("");

        try {
            await authStore.loginWithPassword(model.email, model.password);
            if (authStore.error) {
                if (authStore.error === "PasswordWrong") {
                    setError(t("screen.login.invalidPasswordOrEmail"));
                } else if (authStore.error === "Unknown") {
                    setError(t("screen.login.errorDuringLogin"));
                }
            } else {
                pushRoute(UsersRoutes.ROOT);
            }
        } catch (error) {
            setError(t("screen.login.errorDuringLogin"));
        }

        generalStore.setIsLoading(false);
    };

    if (!authStore.isRehydrated) {
        return null;
    }

    return (
        <AuthContainerSite>
            <h1>{t("screen.login.title")}</h1>
            <p style={{ color: Colors.BODY_2, marginTop: 8 }}>{t("screen.login.description")}</p>
            <Formik
                initialValues={{
                    email: "",
                    password: "",
                }}
                onSubmit={handleSubmit}
                validationSchema={Yup.object().shape({
                    email: Yup.string()
                        .email(t("screen.login.form.validation.error.email"))
                        .required(t("screen.login.form.validation.error.email"))
                        .trim(),
                    password: Yup.string()
                        .min(6, t("screen.login.form.validation.error.password"))
                        .required(t("screen.login.form.validation.error.password")),
                })}
                validateOnBlur={false}
                validateOnChange={false}
            >
                {({ errors, isSubmitting }) => (
                    <Form style={{ marginTop: 24 }} noValidate>
                        <Field
                            component={CustomInputField}
                            label={t("common.email")}
                            name="email"
                            type="email"
                            required
                            autoComplete="username"
                            showValidationErrorText={false}
                        />
                        <Field
                            component={CustomInputField}
                            label={t("screen.login.form.password.label")}
                            name="password"
                            type="password"
                            required
                            autoComplete="current-password"
                            showValidationErrorText={false}
                            style={{ marginTop: 24 }}
                        />
                        <StyledRouterLink to={Routes.FORGOT_PASSWORD.EMAIL} underline="none">
                            {t("screen.login.forgotPassword")}
                        </StyledRouterLink>
                        <ErrorContainer>{getErrorMessage(errors) || error}</ErrorContainer>
                        <Button variant="contained" fullWidth disabled={isSubmitting} type="submit">
                            {t("screen.login.form.submit")}
                        </Button>
                    </Form>
                )}
            </Formik>
        </AuthContainerSite>
    );
});
