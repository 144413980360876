import * as React from "react";
import { Colors } from "../../util/Colors";

export const AuthContainerSite = ({ children }: { children: React.ReactNode }) => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                flexGrow: 1,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "50% 0%",
                backgroundColor: Colors.PRIMARY_500,
                padding: 24,
            }}
        >
            <div
                style={{
                    background: "#FFFFFF",
                    borderRadius: 8,
                    width: "100%",
                    maxWidth: 600,
                    padding: 80,
                }}
            >
                {children}
            </div>
        </div>
    );
};
