import { useState } from "react";
import { t } from "../i18n/util";
import { GetRoleDetailsResponse, Role } from "../network/APITypes";
import { useChooseRolePermissionsType } from "./useChooseRolePermissionsType";
import { useManagePermissionsDialog } from "./useManagePermissionsDialog";
import { useStepFlow } from "./useStepFlow";
import { useCopyRolePermissionsDialog } from "./useCopyRolePermissionsDialog";

const STEP_CHOOSE_PERMISSIONS_TYPE = 0;
const STEP_COPY_ROLE = 1;
const STEP_MANAGE_PERMISSIONS = 2;
const STEP_COUNT = 3;

export const useManagePermissionsFlow = ({
    role,
    reloadRoles,
}: { role?: Role | GetRoleDetailsResponse | null; reloadRoles?: () => void } = {}) => {
    const flow = useStepFlow(STEP_COUNT, { [STEP_MANAGE_PERMISSIONS]: STEP_CHOOSE_PERMISSIONS_TYPE });
    const [open, setOpen] = useState(false);

    const choosePermissionsType = useChooseRolePermissionsType({
        title: t("editPermissions.title"),

        onClose: () => {
            setOpen(false);
            flow.reset();
        },

        onSubmit(permissionsType) {
            if (permissionsType === "copy") {
                copyPermissionsDialog.open();
                flow.setStep(STEP_COPY_ROLE);
            } else {
                managePermissionsDialog.open();
                flow.setStep(STEP_MANAGE_PERMISSIONS);
            }
        },

        stepper: {
            activePage: STEP_CHOOSE_PERMISSIONS_TYPE,
            pages: 2,
            description: t("addRole.step.managePermissions"),
        },
    });

    const copyPermissionsDialog = useCopyRolePermissionsDialog({
        targetRoleId: role?.id,
        onSubmit: () => {
            // Need this so that roles table is up to date
            reloadRoles?.();

            managePermissionsDialog.open();
            flow.setStep(STEP_MANAGE_PERMISSIONS);
        },
        onBack: () => {
            choosePermissionsType.open();
            flow.back();
        },
    });

    const managePermissionsDialog = useManagePermissionsDialog({
        roleId: role?.id,
        onClose: () => {
            setOpen(false);
        },
        onBack() {
            choosePermissionsType.open();
            flow.back();
        },
        stepper: {
            activePage: 1,
            pages: 2,
            description: t("addRole.step.managePermissions"),
        },
        onSubmit() {
            setOpen(false);
            reloadRoles?.();
        },
    });

    const handleOpen = () => {
        setOpen(true);
        flow.reset();
        choosePermissionsType.open();
    };

    let component = choosePermissionsType.component;
    if (flow.step === STEP_COPY_ROLE) {
        component = copyPermissionsDialog.component;
    } else if (flow.step === STEP_MANAGE_PERMISSIONS) {
        component = managePermissionsDialog.component;
    }

    return {
        open: handleOpen,
        component: open ? component : null,
    };
};
