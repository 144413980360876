import { Button, IconButton, Tooltip, styled } from "@mui/material";
import * as React from "react";
import { Colors } from "../util/Colors";
import { Icon } from "../util/Icon";
import { CustomDialog } from "./CustomDialog";
import { SingleClickButton } from "./SingleClickButton";

const HeaderContainer = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
});

const MessageContainer = styled("div")({
    textAlign: "left",
    color: Colors.BODY_2,
});

const ButtonContainer = styled("div")({
    display: "flex",
    alignItems: "center",
    gap: 16,
});

const CancelLabel = styled("p")({
    color: Colors.PRIMARY_500,
    fontWeight: 600,
    lineHeight: "16px",
});

export const ConfirmationDialog = ({
    open,
    title,
    message,
    submitLabel,
    submitTooltip,
    secondaryActionLabel,
    secondaryActionTooltip,
    cancelLabel,
    onSubmit,
    onSecondaryAction,
    onCancel,
    hideCancel,
}: {
    open?: boolean;
    title: string;
    message: React.ReactNode;
    submitLabel?: string;
    submitTooltip?: string;
    secondaryActionLabel?: string;
    secondaryActionTooltip?: string;
    cancelLabel?: string;
    onSubmit?: () => void;
    onSecondaryAction?: () => void;
    onCancel: () => void;
    hideCancel?: boolean;
}) => {
    return (
        <CustomDialog
            open={!!open}
            fullWidth
            sx={
                secondaryActionLabel
                    ? {
                          "& .MuiPaper-root": {
                              maxWidth: 800,
                          },
                      }
                    : undefined
            }
        >
            <HeaderContainer>
                <h1 style={{ fontSize: 32 }}>{title}</h1>
                <IconButton onClick={onCancel}>
                    <Icon name="close" />
                </IconButton>
            </HeaderContainer>
            <MessageContainer style={{ marginTop: 16 }}>{message}</MessageContainer>
            <ButtonContainer style={{ marginTop: 32 }}>
                {!hideCancel && (
                    <Button onClick={onCancel} style={{ backgroundColor: "transparent" }}>
                        <CancelLabel>{cancelLabel}</CancelLabel>
                    </Button>
                )}
                <Tooltip title={submitTooltip}>
                    <div style={{ width: "100%" }}>
                        <SingleClickButton variant="contained" fullWidth onClick={onSubmit} disabled={!onSubmit}>
                            {submitLabel}
                        </SingleClickButton>
                    </div>
                </Tooltip>
                {secondaryActionLabel && (
                    <Tooltip title={secondaryActionTooltip}>
                        <div style={{ width: "100%" }}>
                            <SingleClickButton
                                variant="contained"
                                fullWidth
                                onClick={onSecondaryAction}
                                disabled={!onSecondaryAction}
                            >
                                {secondaryActionLabel}
                            </SingleClickButton>
                        </div>
                    </Tooltip>
                )}
            </ButtonContainer>
        </CustomDialog>
    );
};
