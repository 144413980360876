import * as queryString from "query-string";
import * as Config from "../config";
import { t } from "../i18n/util";
import { authStore } from "../stores/AuthStore";
import { generalStore } from "../stores/GeneralStore";
import * as NetworkStapler from "./NetworkStapler";
import { IAPITarget, IDownloadAPITarget, urljoin } from "./NetworkStapler";
import FileSaver from "file-saver";

export const STATUS_CODE_BAD_REQUEST = 400;
export const STATUS_CODE_UNAUTHORIZED = 401;
export const STATUS_CODE_NOT_ALLOWED = 403;

const injectHeaders = (target: NetworkStapler.IAPITarget) => {
    const ret: any = {
        ...target.headers,
        "Content-Type": target.headers?.["Content-Type"] ?? "application/json",
        Accept: "application/json",
        "Accept-Language": generalStore.locale,
    };

    if (authStore.credentials?.access_token) {
        ret.Authorization = `Bearer ${authStore.credentials?.access_token}`;
    }

    return ret;
};

class APIClient {
    apiClient: NetworkStapler.APIClient;

    constructor() {
        this.apiClient = new NetworkStapler.APIClient({
            baseUrl: Config.API_BASE_URL,
            injectHeaders: injectHeaders,
            throwOnErrorStatusCodes: true,
        });
    }

    async request(target: NetworkStapler.IAPITarget): Promise<Response> {
        try {
            return await this.apiClient.request(target);
        } catch (err) {
            const retry = await this.checkStatusCodeError(err);
            if (retry) {
                return await this.apiClient.request(target);
            }
            throw err;
        }
    }

    async requestJSON(target: NetworkStapler.IAPITarget): Promise<object> {
        try {
            return await this.apiClient.requestJSON(target);
        } catch (err) {
            const retry = await this.checkStatusCodeError(err);
            if (retry) {
                return await this.apiClient.requestJSON(target);
            }
            throw err;
        }
    }

    async requestType<T>(target: NetworkStapler.ITypedAPITarget<T>, isTokenRefresh = false): Promise<T> {
        try {
            return await this.apiClient.requestType(target);
        } catch (err) {
            const retry = await this.checkStatusCodeError(err, isTokenRefresh);
            if (retry) {
                return await this.apiClient.requestType(target);
            }
            throw err;
        }
    }

    async uploadFormData(target: IAPITarget) {
        const headers = injectHeaders(target);

        // FormData with fetch must not have Content-Type set. This gets set automatically by
        // fetch()
        delete headers["Content-Type"];

        let requestUrl = urljoin(this.apiClient.options.baseUrl, target.url);

        if (target.queryParameters) {
            const query = queryString.stringify(target.queryParameters, { arrayFormat: "bracket" });
            if (query) {
                requestUrl = urljoin(requestUrl, `?${query}`);
            }
        }

        const options: any = {
            method: target.method || "GET",
            body: target.body,
            headers: headers as any,
        };

        return this.apiClient.performFetch(requestUrl, options);
    }

    async uploadFormDataJSON(target: IAPITarget): Promise<object> {
        return (await this.uploadFormData(target)).json();
    }

    async downloadFile(target: IDownloadAPITarget) {
        try {
            const res = await this.apiClient.request(target);
            const blob = await res.blob();
            FileSaver.saveAs(blob, target.fileName);
        } catch (err) {
            const retry = await this.checkStatusCodeError(err);
            if (retry) {
                return await this.apiClient.request(target);
            }
            throw err;
        }
    }

    async checkStatusCodeError(error: any, isTokenRefresh = false): Promise<boolean> {
        let retry = false;
        if (error instanceof NetworkStapler.APIClientStatusCodeError) {
            if (error.statusCode) {
                switch (error.statusCode) {
                    case STATUS_CODE_UNAUTHORIZED:
                        if (!isTokenRefresh) {
                            retry = await authStore.handleUnauthorized();
                        }
                        break;
                    case STATUS_CODE_NOT_ALLOWED:
                        generalStore.setError(t("error.notAllowed"), error);
                        break;
                    default:
                        break;
                }
            }
        } else {
            console.error("### APIClient.checkStatusCodeError() network error:", error);
        }

        return retry;
    }
}

export const apiClient = new APIClient();
