import { styled } from "@mui/system";
import * as React from "react";
import { SEARCH_DEBOUNCE_MS } from "../../config";
import { Colors } from "../util/Colors";
import { SearchField } from "./SearchField";

const GenericItemSearchContainer = styled("div")({
    display: "flex",
    width: 437,
    flexDirection: "column",
    alignItems: "flex-start",
    borderRadius: 8,
    border: `1px solid ${Colors.GREY_200}`,
    backgroundColor: "#FFFFFF",
});

const GenericItemSearchHeaderContainer = styled("div")({
    alignSelf: "stretch",
    padding: "40px 24px 24px 24px",
});

const GenericItemContainer = <T,>({
    item,
    onClick,
    isSelected,
    itemRenderer,
    style,
}: {
    item: T;
    onClick: (item: T) => void;
    isSelected?: boolean;
    itemRenderer: (item: T, hoverStyle: React.CSSProperties) => React.ReactNode;
    style?: React.CSSProperties;
}) => {
    const [isHovered, setIsHovered] = React.useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const isActive = isHovered || isSelected;
    const hoverStyle = isActive ? { color: Colors.PRIMARY_500 } : {};

    return (
        <div
            style={{
                backgroundColor: isActive ? "#F2F6F9" : undefined,
                borderTop: `1px solid ${Colors.GREY_200}`,
                cursor: "pointer",
                padding: "12px 24px",
                ...style,
            }}
            onClick={() => onClick(item)}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {itemRenderer(item, hoverStyle)}
        </div>
    );
};

export const GenericItemSearch = <T extends { id: string }>({
    title,
    placeholder,
    onChangeSearch,
    onClickItem,
    items,
    itemRenderer,
    itemStyle,
    style,
}: {
    title: string;
    placeholder: string;
    onChangeSearch: (search: string) => void;
    onClickItem: (item: T) => void;
    items: T[];
    itemRenderer: (item: T, hoverStyle: React.CSSProperties) => React.ReactNode;
    itemStyle?: React.CSSProperties;
    style?: React.CSSProperties;
}) => {
    const [selectedItem, setSelectedItem] = React.useState<T>();

    const handleClick = (item: T) => {
        setSelectedItem(item);
        onClickItem(item);
    };

    const handleSearchChange = (search: string) => {
        setSelectedItem(undefined);
        onChangeSearch(search);
    };

    return (
        <GenericItemSearchContainer style={style}>
            <GenericItemSearchHeaderContainer>
                <div className="body1Bold" style={{ color: Colors.BODY_2 }}>
                    {title}
                </div>
                <SearchField
                    fullWidth
                    placeholder={placeholder}
                    debounceMs={SEARCH_DEBOUNCE_MS}
                    onChange={handleSearchChange}
                    style={{ marginTop: 24 }}
                />
            </GenericItemSearchHeaderContainer>
            <div style={{ width: "100%", overflow: "auto" }}>
                {items.map((item) => (
                    <GenericItemContainer
                        item={item}
                        onClick={handleClick}
                        isSelected={selectedItem?.id === item.id}
                        itemRenderer={itemRenderer}
                        key={item.id}
                        style={itemStyle}
                    />
                ))}
            </div>
        </GenericItemSearchContainer>
    );
};
