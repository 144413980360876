import { useState } from "react";
import { t } from "../i18n/util";
import { Role, RolePayload } from "../network/APITypes";
import { useChooseRolePermissionsType } from "./useChooseRolePermissionsType";
import { useCopyRolePermissionsDialog } from "./useCopyRolePermissionsDialog";
import { useManagePermissionsDialog } from "./useManagePermissionsDialog";
import { useRoleDetailsForm } from "./useRoleDetailsForm";
import { useStepFlow } from "./useStepFlow";
import { useRoleGroupDetailsForm } from "./useRoleGroupDetailsForm";
import { RoleDetailsFormData } from "../components/forms/addRole/RoleDetailsForm";
import { generalStore } from "../stores/GeneralStore";
import { API } from "../network/API";

const STEP_ROLE_DETAILS = 0;
const STEP_CHOOSE_PERMISSIONS_TYPE = 1;
const STEP_COPY_ROLE = 2;
const STEP_MANAGE_PERMISSIONS = 3;
const STEP_ADD_ROLE_GROUP = 4;
const STEP_COUNT = 5;

export const useAddRoleFlow = ({ reloadRoles }: { reloadRoles?: () => void } = {}) => {
    const flow = useStepFlow(STEP_COUNT, {
        [STEP_MANAGE_PERMISSIONS]: STEP_CHOOSE_PERMISSIONS_TYPE,
        [STEP_ADD_ROLE_GROUP]: STEP_ROLE_DETAILS,
    });
    const [open, setOpen] = useState(false);
    const [role, setRole] = useState<Role>();

    // Since we leave the form, when the user clicks on "new group", we need to store the form data
    // so that we can restore it later.
    const [detailsFormData, setDetailsFormData] = useState<RoleDetailsFormData>();

    const roleDetailsForm = useRoleDetailsForm({
        role,
        formData: detailsFormData,
        async onSubmit(formData) {
            if (formData) {
                try {
                    generalStore.setIsLoading(true);
                    const result = await API.postRole(formData as RolePayload);
                    setRole(result);
                    generalStore.setSuccessMessage(t("success.addRole"));
                    reloadRoles?.();
                    choosePermissionsType.open();
                    flow.next();
                } catch (error) {
                    generalStore.setError(t("error.addRole"), error);
                } finally {
                    generalStore.setIsLoading(false);
                }
            }
        },
        onNewGroup(params) {
            // Store values to restore later
            setDetailsFormData(params.formData);

            roleGroupDetailsForm.open(params?.initialGroupName);
            flow.setStep(STEP_ADD_ROLE_GROUP);
        },
        stepper: {
            activePage: STEP_ROLE_DETAILS,
            pages: 3,
            description: t("addRole.step.roleInformation"),
        },
    });

    const roleGroupDetailsForm = useRoleGroupDetailsForm({
        onSubmit(result) {
            if (result && "id" in result) {
                // Restore old form with new role group id
                const newFormData = {
                    name: "",
                    description: "",
                    accountType: "",
                    ...detailsFormData,
                    roleGroupId: result.id,
                };
                setDetailsFormData(newFormData);
            }
            flow.back();
        },
        onBack() {
            flow.back();
        },
    });

    const choosePermissionsType = useChooseRolePermissionsType({
        title: t("manageRoleForm.add.title"),

        onClose: () => {
            setOpen(false);
            flow.reset();
        },

        onSubmit(permissionsType) {
            if (permissionsType === "copy") {
                copyPermissionsDialog.open();
                flow.setStep(STEP_COPY_ROLE);
            } else {
                managePermissionsDialog.open();
                flow.setStep(STEP_MANAGE_PERMISSIONS);
            }
        },

        onBack() {
            roleDetailsForm.open();
            flow.back();
        },

        stepper: {
            activePage: STEP_CHOOSE_PERMISSIONS_TYPE,
            pages: 3,
            description: t("addRole.step.managePermissions"),
        },
    });

    const copyPermissionsDialog = useCopyRolePermissionsDialog({
        targetRoleId: role?.id,
        onSubmit: () => {
            // Need this so that roles table is up to date
            reloadRoles?.();

            managePermissionsDialog.open();
            flow.setStep(STEP_MANAGE_PERMISSIONS);
        },
        onBack: () => {
            choosePermissionsType.open();
            flow.back();
        },
    });

    const managePermissionsDialog = useManagePermissionsDialog({
        roleId: role?.id,
        title: t("manageRoleForm.add.title"),
        onClose: () => {
            setOpen(false);
        },
        onBack() {
            choosePermissionsType.open();
            flow.back();
        },
        stepper: {
            activePage: 2,
            pages: 3,
            description: t("addRole.step.managePermissions"),
        },
        submitButtonLabel: t("screen.settings.roles.button.addRole.text"),
        onSubmit() {
            setOpen(false);
            reloadRoles?.();
        },
    });

    const handleOpen = () => {
        setOpen(true);
        setRole(undefined);
        setDetailsFormData(undefined);
        flow.reset();
        roleDetailsForm.open();
    };

    let component = roleDetailsForm.component;
    if (flow.step === STEP_CHOOSE_PERMISSIONS_TYPE) {
        component = choosePermissionsType.component;
    } else if (flow.step === STEP_COPY_ROLE) {
        component = copyPermissionsDialog.component;
    } else if (flow.step === STEP_MANAGE_PERMISSIONS) {
        component = managePermissionsDialog.component;
    } else if (flow.step === STEP_ADD_ROLE_GROUP) {
        component = roleGroupDetailsForm.component;
    }

    return {
        open: handleOpen,
        component: open ? component : null,
    };
};
