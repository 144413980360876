import { Colors } from "../util/Colors";
import { CmsTableCell, TableLabel } from "./Primitives";
import { StatusProps } from "./Status";

// Add more statuses and colors if necessary
export type CellStatus = "active" | "disabled" | "primary";
export const statusToColor = {
    active: Colors.ACTIVE,
    disabled: Colors.GREY_500,
    primary: Colors.PRIMARY_500,
};

export const TableStatusCell = ({ color, label }: StatusProps) => {
    return (
        <CmsTableCell>
            <TableLabel
                style={{
                    maxWidth: "100%",
                    padding: "4px 0px",
                    minWidth: 100,
                    backgroundColor: color,
                    color: Colors.LIGHT,
                    textAlign: "center",
                    borderRadius: 4,
                }}
            >
                {label}
            </TableLabel>
        </CmsTableCell>
    );
};
