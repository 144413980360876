import { Route } from "react-router-dom";
import { CustomSwitch } from "../../app/router/CustomSwitch";
import { UsersSite } from "../sites/UsersSite";
import { UsersRoutes } from "./UsersRoutes";

export const UsersRouter = () => (
    <CustomSwitch>
        <Route exact path={[UsersRoutes.ROOT]}>
            <UsersSite />
        </Route>
    </CustomSwitch>
);
