import * as React from "react";
import { t } from "../i18n/util";
import { API } from "../network/API";
import { LegalDocumentConsent } from "../network/APITypes";
import { generalStore } from "../stores/GeneralStore";

export const useLegalDocumentConsents = (userId: string) => {
    const [response, setResponse] = React.useState<LegalDocumentConsent[] | null>(null);

    const load = React.useCallback(async () => {
        generalStore.setIsLoading(true);

        try {
            const res = await API.getLegalDocumentConsents(userId);
            setResponse(res.documents);
        } catch (error) {
            generalStore.setError(t("error.loadUsers"), error);
        } finally {
            generalStore.setIsLoading(false);
        }
    }, [userId]);

    React.useEffect(() => {
        load();
    }, [load]);

    return { legalDocumentConsents: response, reloadLegalDocumentConsents: load };
};
