import { t } from "../i18n/util";
import { RoleStatusEnum } from "../network/APITypes";
import { useMultiSelectionFilter } from "./useMultiSelectionFilter";

export const useRoleStatusFilter = () => {
    return useMultiSelectionFilter<RoleStatusEnum>({
        name: "status",
        label: t("filter.label.status"),
        options: [
            {
                value: "active",
                label: t("role.status.active"),
            },
            {
                value: "deactivated",
                label: t("role.status.deactivated"),
            },
            {
                value: "system",
                label: t("role.status.system"),
            },
        ],
    });
};
