import * as React from "react";
import { ConfirmationDialog } from "../components/ui/ConfirmationDialog";
import { Colors } from "../components/util/Colors";
import { getFormattedDate } from "../components/util/Helpers";
import { t } from "../i18n/util";
import { useLegalDocumentConsents } from "./useLegalDocumentConsents";

type LegalDocumentConsentsProps = {
    userId: string;
};

export const useLegalDocumentConsentsDialog = ({ userId }: LegalDocumentConsentsProps) => {
    const [open, setOpen] = React.useState(false);

    const { legalDocumentConsents } = useLegalDocumentConsents(userId);

    const handleSubmit = async () => {
        setOpen(false);
    };

    const message = (
        <table style={{ minWidth: 400 }}>
            <thead>
                <tr style={{ paddingBottom: 16 }}>
                    <th style={{ textAlign: "left", padding: "0 108px 8px 0" }}>
                        <p className="body1Bold" style={{ color: Colors.DARK }}>
                            {t("user.action.legalDocumentConsentsDialog.documentName")}
                        </p>
                    </th>
                    <th style={{ textAlign: "left", paddingBottom: 8 }}>
                        <p className="body1Bold" style={{ color: Colors.DARK }}>
                            {t("user.action.legalDocumentConsentsDialog.acceptedDate")}
                        </p>
                    </th>
                </tr>
            </thead>
            <tbody>
                <>
                    {legalDocumentConsents && legalDocumentConsents?.length > 0 ? (
                        legalDocumentConsents.map((consent) => (
                            <tr key={consent.documentId}>
                                <td style={{ paddingRight: 16 }}>
                                    <p style={{ wordBreak: "break-all", maxWidth: 360 }}>{consent.documentTitle}</p>
                                </td>
                                <td>
                                    <p>{getFormattedDate(consent.acceptedAt)}</p>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={2}>{t("user.action.legalDocumentConsentsDialog.noConsents")}</td>
                        </tr>
                    )}
                </>
            </tbody>
        </table>
    );

    const component = (
        <ConfirmationDialog
            open={open}
            title={t("user.action.legalDocumentConsentsDialog.title")}
            message={message}
            submitLabel={t("common.close")}
            onSubmit={handleSubmit}
            onCancel={() => setOpen(false)}
            hideCancel
        />
    );

    return {
        open() {
            setOpen(true);
        },
        component,
    };
};
