import { observer } from "mobx-react";
import React from "react";
import { SUCCESS_AUTOHIDE_MS } from "../../../config";
import { generalStore } from "../../../stores/GeneralStore";
import { Colors } from "../../util/Colors";
import { Icon } from "../../util/Icon";
import { Banner } from "../Primitives";

export const SuccessBanner = observer(() => {
    const successMessage = generalStore.successMessage;

    React.useEffect(() => {
        if (successMessage) {
            const timeout = setTimeout(() => closeSuccessBanner(), SUCCESS_AUTOHIDE_MS);
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [successMessage]);

    const closeSuccessBanner = () => {
        generalStore.setSuccessMessage(undefined);
    };

    return (
        <Banner style={{ backgroundColor: Colors.SECONDARY_600, position: "fixed" }}>
            <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                <Icon name="checkGreen" />
                <p className="body2" style={{ color: Colors.LIGHT }}>
                    {successMessage}
                </p>
            </div>
        </Banner>
    );
});
