import { FormControlLabel, Radio } from "@mui/material";
import { Colors } from "../util/Colors";

export const BorderRadio = ({
    value,
    label,
    selectedValue,
    style,
}: {
    value: number | string;
    label: string;
    selectedValue: number | string;
    style?: React.CSSProperties;
}) => {
    const frameStyle =
        selectedValue !== value
            ? { marginLeft: 0, height: 48, borderRadius: 4, border: `1px solid ${Colors.GREY_200}` }
            : {
                  marginLeft: 0,
                  height: 48,
                  borderRadius: 4,
                  border: `1px solid ${Colors.PRIMARY_500}`,
                  backgroundColor: Colors.PRIMARY_50,
              };

    return (
        <FormControlLabel
            value={value}
            control={<Radio />}
            label={
                <div className="body1" style={{ width: "100%" }}>
                    {label}
                </div>
            }
            style={{ ...frameStyle, ...style }}
        />
    );
};
