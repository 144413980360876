import { Field, useFormikContext } from "formik";
import { t } from "../../../i18n/util";
import { RoleGroup } from "../../../network/APITypes";
import { FieldDefinition } from "../../../types";
import { CustomInputField } from "../../ui/CustomInputField";

export const RoleGroupDetailsFields = (props: { roleGroup?: RoleGroup }) => {
    const { values } = useFormikContext<any>();

    const fields: FieldDefinition[] = [
        {
            label: "roleGroupDetails.name",
            name: "name",
            maxLength: 50,
        },
        {
            label: "roleGroupDetails.description",
            name: "description",
            multiline: true,
            minRows: 5,
            maxRows: 5,
            maxLength: 100,
            type: "textarea",
        },
    ];

    return (
        <>
            {fields.map((field) => {
                return (
                    <Field
                        key={field.name}
                        component={field.component ?? CustomInputField}
                        label={t(field.label)}
                        name={field.name}
                        type={field.type ?? "input"}
                        required={field.required}
                        options={field.options}
                        disabled={field.disabled}
                        multiline={field.multiline}
                        minRows={field.minRows}
                        maxRows={field.maxRows}
                        maxLength={field.maxLength}
                    />
                );
            })}
        </>
    );
};
