import * as React from "react";
import { useLocation } from "react-router-dom";
import { pushRoute, withQuery } from "../components/app/router/history";
import { RemoveFilterButton } from "../components/ui/filters/RemoveFilterButton";
import { ToggleFilterButton } from "../components/ui/filters/ToggleFilterButton";
import { useQuery } from "./useQuery";

export const useToggleFilter = ({ name, label }: { name: string; label: string }) => {
    const location = useLocation();
    const queryParams = useQuery();

    const handleToggle = (value?: boolean) => {
        pushRoute(withQuery(location.pathname, { ...queryParams, page: 1, [name]: value }));
    };

    const handleRemove = () => {
        pushRoute(withQuery(location.pathname, { ...queryParams, page: 1, [name]: undefined }));
    };

    const component = (
        <ToggleFilterButton key={name} value={!!queryParams[name]} label={label} onChange={handleToggle} />
    );

    const resetComponent = !!queryParams[name] && (
        <RemoveFilterButton key={name} label={label} onRemove={handleRemove} />
    );

    return {
        name,
        value: queryParams[name],
        component,
        resetComponent,
    };
};
