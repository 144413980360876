import { styled } from "@mui/material";
import { IFilter } from "../../../hooks/useMultiSelectionFilter";
import { compact } from "lodash";
import { CSSProperties } from "react";
import { Colors } from "../../util/Colors";

export const Filters = styled("div")({
    display: "flex",
    gap: 8,
    flexWrap: "wrap",
});

export const FilterComponents = ({ filters, style }: { filters: IFilter[]; style?: React.CSSProperties }) => (
    <Filters style={style}>{filters.map((filter) => filter.component)}</Filters>
);

export const FilterResetComponents = ({ filters, style }: { filters: IFilter[]; style?: React.CSSProperties }) => {
    const resetComponents = compact(filters.map((filter) => filter.resetComponent));
    return resetComponents.length > 0 ? (
        <Filters style={style}>{filters.map((filter) => filter.resetComponent)}</Filters>
    ) : null;
};

export const overlayPaperStyle: CSSProperties = {
    borderRadius: 8,
    minWidth: 264,
    backgroundColor: Colors.LIGHT,
    border: `1px solid ${Colors.GREY_200}`,
    boxShadow: "0px 4px 8px rgba(50, 50, 71, 0.15)",
};
