import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { FieldInputProps, FormikState, getIn } from "formik";
import * as React from "react";
import { Icon } from "../util/Icon";
import { FieldError } from "./FieldError";
import { Colors } from "../util/Colors";

type IProps = TextFieldProps & {
    field: FieldInputProps<string>;
    form: FormikState<any>;
    showValidationErrorText?: boolean;
    maxLength?: number;
    showLength?: boolean;
};

export const CustomInputField = ({
    style,
    label,
    type,
    autoComplete,
    required,
    form,
    field,
    disabled,
    multiline,
    minRows,
    maxRows,
    maxLength,
    "aria-label": ariaLabel,
    showValidationErrorText = true,
    inputProps,
    placeholder,
    onChange,
    showLength,
}: IProps) => {
    const [showPassword, setShowPassword] = React.useState(false);
    const fieldError = getIn(form.errors, field.name);
    const showError = getIn(form.touched, field.name) && !!fieldError;

    const handleToggleShowPassword = () => setShowPassword((prevState) => !prevState);

    const passwordProps =
        type === "password"
            ? {
                  InputProps: {
                      endAdornment: (
                          <InputAdornment position="end">
                              <Icon
                                  name={showPassword ? "passwordVisible" : "passwordHidden"}
                                  onClick={handleToggleShowPassword}
                              />
                          </InputAdornment>
                      ),
                  },
              }
            : {};

    const passwordType = showPassword ? "text" : "password";

    return (
        <div style={style}>
            <TextField
                label={required ? `${label} *` : label}
                value={field.value}
                name={field.name}
                onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    onChange?.(event);
                    field.onChange(event);
                }}
                onBlur={field.onBlur}
                fullWidth
                type={type === "password" ? passwordType : type}
                autoComplete={autoComplete}
                error={showError}
                margin="dense"
                aria-label={ariaLabel}
                variant="outlined"
                disabled={disabled}
                multiline={multiline}
                minRows={minRows}
                maxRows={maxRows}
                inputProps={{ maxLength, ...inputProps }}
                placeholder={placeholder}
                {...passwordProps}
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                {showValidationErrorText && <FieldError>{showError ? fieldError : ""}</FieldError>}
                {showLength && maxLength && (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            color: Colors.BODY_2,
                        }}
                    >
                        {field.value.length}/{maxLength}
                    </div>
                )}
            </div>
        </div>
    );
};
