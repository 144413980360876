import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { getIn, useFormikContext } from "formik";
import * as React from "react";
import { t } from "../../../i18n/util";
import { Region } from "../../../network/APITypes";
import { SelectionFormContainer } from "../../ui/SelectionFormContainer";

type RegionSelectionProps = {
    onClose: () => void;
    regions: Region[];
    name: string;
    isUserEdit?: boolean;
};

export const RegionSelection = ({ onClose, regions, name, isUserEdit }: RegionSelectionProps) => {
    const { setFieldValue, values, setFieldTouched } = useFormikContext<any>();

    const initialValue = getIn(values, name);

    const [selectedRegion, setSelectedRegion] = React.useState<string>(initialValue ?? "");

    const handleChangeRegion = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedRegion(event.target.value);
    };

    const handleSubmit = () => {
        if (initialValue !== selectedRegion && !isUserEdit) {
            setFieldValue("distributionChannelID", "");
            setFieldTouched("distributionChannelID", false);
        }
        setFieldValue(name, selectedRegion);
        onClose();
    };

    return (
        <SelectionFormContainer
            title={t("screen.userDetails.edit.form.region.title")}
            onSubmit={handleSubmit}
            onClose={onClose}
        >
            <RadioGroup name={name} value={selectedRegion} onChange={handleChangeRegion}>
                {regions.map((region) => (
                    <FormControlLabel
                        key={region.id}
                        value={region.id}
                        name={name}
                        control={<Radio />}
                        label={
                            <p>
                                {region.externalID} - {region.description}
                            </p>
                        }
                        style={{ height: 56 }}
                    />
                ))}
            </RadioGroup>
        </SelectionFormContainer>
    );
};
