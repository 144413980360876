import { IOSSwitch } from "./IOSSwitch";
import { FieldInputProps } from "formik";
import * as React from "react";
import { SwitchProps } from "@mui/material";

type CustomSwitchProps = SwitchProps & {
    field: FieldInputProps<boolean>;
    onClick: () => void;
};

export const CustomSwitch = ({ onClick, field }: CustomSwitchProps) => (
    <IOSSwitch
        checked={field.value}
        name={field.name}
        onChange={field.onChange}
        onClick={onClick}
        onBlur={field.onBlur}
    />
);
