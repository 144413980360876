import { observer } from "mobx-react";
import { useCallback, useState } from "react";
import { BaseCMSCustomer, PostPartnerPortalUserRequestPayload } from "../../../network/APITypes";
import { Form, Formik, FormikHelpers } from "formik";
import { t } from "../../../i18n/util";
import * as Yup from "yup";
import { getUserPhoneAndEmailValidationSchema } from "../editUser/EditUserForm";
import { cloneDeep } from "lodash";
import { Button, Step, StepContent, StepLabel, Stepper, Tooltip } from "@mui/material";
import { Colors } from "../../util/Colors";
import { FieldError } from "../../ui/FieldError";
import { PartnerPortalCustomerNumberSelection } from "./AddPartnerPortalCustomerNumberSelection";
import { PartnerPortalUserDetailsFields } from "./PartnerPortalUserDetailsFields";
import { isValidPhoneNumber, normalizePhoneNumber } from "../../util/Helpers";
import { generalStore } from "../../../stores/GeneralStore";
import { API } from "../../../network/API";
import { DialogHeader } from "../../ui/DialogHeader";

type AddUserFormProps = {
    onSubmit?: () => void | Promise<void>;
    onClose: () => void;
};
type MultiFormStepConfig = {
    name: string;
    title?: string;
    step: string;
    validationSchema?: any;
    content?: React.ReactNode;
    autoOverflow?: boolean;
};

const getPartnerPortalUserValidationSchema = () => {
    const { email, phone } = getUserPhoneAndEmailValidationSchema();
    return Yup.object().shape({
        corporatePositionID: Yup.string().required(t("validationError.corporatePosition")),
        salutation: Yup.string().required(t("validationError.title")),
        firstName: Yup.string().required(t("validationError.firstName")),
        lastName: Yup.string().required(t("validationError.lastName")),
        email,
        phone,
    });
};

export const createPartnerPortalUser = async (
    model: PostPartnerPortalUserRequestPayload,
    onUserCreated?: () => void,
) => {
    try {
        generalStore.setIsLoading(true);
        await API.postPartnerPortalUserRequest(model, model.customerID);
        generalStore.setSuccessMessage(t("success.addUser"));
    } catch (error) {
        generalStore.setError(t("error.addUser"), error);
    } finally {
        await onUserCreated?.();
        generalStore.setIsLoading(false);
        return;
    }
};

const getPages = ({
    onSelectCustomer,
    distributorId,
}: {
    onSelectCustomer: (customer?: BaseCMSCustomer) => void;
    distributorId: string;
}): MultiFormStepConfig[] => [
    {
        name: "customer",
        step: t("addUserForm.step.customerNumber.description"),
        validationSchema: Yup.object().shape({
            customerID: Yup.string().required(t("validationError.customerNumber")),
        }),
        content: <PartnerPortalCustomerNumberSelection onSelectCustomer={onSelectCustomer} />,
        autoOverflow: true,
    },
    {
        name: "details",
        step: t("addUserForm.step.userData.description"),
        validationSchema: getPartnerPortalUserValidationSchema,
        content: <PartnerPortalUserDetailsFields distributorId={distributorId} />,
        autoOverflow: true,
    },
];

const initialValues: PostPartnerPortalUserRequestPayload = {
    corporatePositionID: "",
    customerID: "",
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    salutation: "",
};
export const AddPartnerPortalUserForm = observer(({ onClose, onSubmit }: AddUserFormProps) => {
    const [activeStep, setActiveStep] = useState(0);
    const [selectedCustomer, setSelectedCustomer] = useState<BaseCMSCustomer | undefined>();

    const handleClickNext = (
        model: PostPartnerPortalUserRequestPayload,
        helpers: FormikHelpers<PostPartnerPortalUserRequestPayload>,
    ) => {
        helpers.setTouched({});
        helpers.setSubmitting(false);

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleClickBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSubmit = async (model: PostPartnerPortalUserRequestPayload) => {
        const userData = cloneDeep(model);
        userData.phone = normalizePhoneNumber(userData.phone);
        if (!isValidPhoneNumber(userData.phone)) {
            // For removal -> don't send empty string
            userData.phone = undefined;
        }

        if (!userData.email) {
            // For removal -> don't send empty string
            userData.email = undefined;
        }
        await createPartnerPortalUser(userData, async () => {
            await onSubmit?.();
            onClose();
        });
    };

    const handleSelectCustomer = useCallback((customer?: BaseCMSCustomer) => {
        setSelectedCustomer(customer);
    }, []);

    const getOptionalStepTitle = (index: number) => {
        if (index === 0) {
            return selectedCustomer?.externalId;
        }
    };

    const pages = getPages({
        onSelectCustomer: handleSelectCustomer,
        distributorId: selectedCustomer?.distributorId ?? "",
    });
    const { content, validationSchema, autoOverflow } = pages[activeStep];
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={activeStep < pages.length - 1 ? handleClickNext : handleSubmit}
            validationSchema={validationSchema}
            validateOnChange
        >
            {({ errors, isSubmitting, touched }) => (
                <Form
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        overflow: "hidden",
                        height: "100%",
                    }}
                    noValidate
                >
                    <div>
                        <DialogHeader
                            title={t("addUserForm.partner.title")}
                            onClose={onClose}
                            onBack={
                                activeStep > 0
                                    ? () => {
                                          handleClickBack();
                                      }
                                    : undefined
                            }
                        />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            flexGrow: 1,
                            overflow: autoOverflow ? "auto" : "hidden",
                            marginTop: 16,
                            paddingTop: 8,
                        }}
                    >
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {pages.map((page, index) => (
                                <Step key={page.step}>
                                    <StepLabel
                                        optional={
                                            index < activeStep ? (
                                                <p style={{ color: Colors.GREY_500, fontSize: 14 }}>
                                                    {getOptionalStepTitle(index)}
                                                </p>
                                            ) : null
                                        }
                                    >
                                        {page.step}
                                    </StepLabel>
                                    <StepContent>{content}</StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    </div>
                    <div>
                        {errors.customerID && activeStep === 0 && touched.customerID && (
                            <FieldError>{errors.customerID}</FieldError>
                        )}
                        {activeStep === pages.length - 1 && (
                            <Button
                                type="submit"
                                fullWidth
                                style={{ marginBottom: 16 }}
                                disabled={isSubmitting}
                                variant="contained"
                            >
                                {t("addUserForm.button.save")}
                            </Button>
                        )}
                        {activeStep < pages.length - 1 && (
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                disabled={isSubmitting || (selectedCustomer && !selectedCustomer.optIn)}
                            >
                                {t("button.next")}
                            </Button>
                        )}
                    </div>
                </Form>
            )}
        </Formik>
    );
});
