import { t } from "../../i18n/util";
import { Stepper } from "../../types";

export const DialogStepper = ({ stepper }: { stepper: Stepper }) => {
    return (
        <p style={{ fontSize: 14, marginTop: 8 }}>
            {t("form.stepper", { ...stepper, activePage: stepper.activePage + 1 })}
        </p>
    );
};
