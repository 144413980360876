import { Button, TextField, styled } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import dayjs from "dayjs";
import "dayjs/locale/de";
import { Field, Form, Formik } from "formik";
import { isEqual } from "lodash";
import { observer } from "mobx-react";
import * as React from "react";
import { useDeepCompareEffect } from "use-deep-compare";
import * as Yup from "yup";
import { useDistributors } from "../../../hooks/useDistributors";
import { MessageIDS, t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { Distributor, PutDistributorPayload } from "../../../network/APITypes";
import { generalStore } from "../../../stores/GeneralStore";
import { FieldDefinition } from "../../../types";
import { CardHeader } from "../../ui/CardHeader";
import { CustomDateTimePicker } from "../../ui/CustomDateTimePicker";
import { CustomInputField } from "../../ui/CustomInputField";
import { CustomSettingInputField } from "../../ui/CustomSettingInputField";
import { CustomSwitch } from "../../ui/CustomSwitch";
import { LeaveSiteDialog } from "../../ui/LeaveSiteDialog";
import { Card, CardColumn, CardContainer, SettingSubSiteContainer } from "../../ui/Primitives";
import { Colors } from "../../util/Colors";
import { ENABLE_GENERAL_SETTINGS_RETURNS } from "../../../config";

type SettingName = keyof PutDistributorPayload;

type Setting = {
    title: MessageIDS;
    subinfo?: MessageIDS;
    field: FieldDefinition & { name: SettingName };
};

type Category = {
    title: MessageIDS;
    settings: Setting[];
};

const settings: Record<string, Category> = {
    returns: {
        title: "screen.settings.general.returns.title",
        settings: [
            {
                title: "screen.settings.general.option.minimumValueOfGoods",
                subinfo: "screen.settings.general.option.subinfo.minimumValueOfGoods",
                field: {
                    label: "screen.settings.general.option.minimumValueOfGoods",
                    name: "minimumValueOfGoods",
                    unitAdornment: "€",
                    type: "number",
                },
            },
            {
                title: "screen.settings.general.option.maximumInvoiceAge",
                subinfo: "screen.settings.general.option.subinfo.maximumInvoiceAge",
                field: {
                    label: "screen.settings.general.option.maximumInvoiceAge",
                    name: "maximumInvoiceAge",
                    type: "number",
                },
            },
        ],
    },
    receipts: {
        title: "screen.settings.general.receipts.title",
        settings: [
            // FBM-190 - hide setting
            // {
            //     title: "screen.settings.general.option.completeDelivery",
            //     subinfo: "screen.settings.general.option.subinfo.completeDelivery",
            //     field: {
            //         label: "screen.settings.general.option.completeDelivery",
            //         name: "completeDelivery",
            //         component: CustomSwitch,
            //     },
            // },
            {
                title: "screen.settings.general.option.maximumDepositPlaceDistance",
                subinfo: "screen.settings.general.option.subinfo.maximumDepositPlaceDistance",
                field: {
                    label: "screen.settings.general.option.maximumDepositPlaceDistance",
                    name: "maximumDepositPlaceDistance",
                    unitAdornment: "m",
                    type: "number",
                },
            },
        ],
    },
    delivery: {
        title: "screen.settings.general.deliveryInformations.title",
        settings: [
            {
                title: "screen.settings.general.option.maximumPreferredDeliveryDateDays",
                subinfo: "screen.settings.general.option.subinfo.maximumPreferredDeliveryDateDays",
                field: {
                    label: "screen.settings.general.option.maximumPreferredDeliveryDateDays",
                    name: "maximumPreferredDeliveryDateDays",
                    unitAdornment: "d",
                    type: "number",
                },
            },
        ],
    },
    isz: {
        title: "screen.settings.general.isz.title",
        settings: [
            {
                title: "screen.settings.general.option.iszExpressShoppingRadius",
                subinfo: "screen.settings.general.option.subinfo.iszExpressShoppingRadius",
                field: {
                    label: "screen.settings.general.option.iszExpressShoppingRadius",
                    name: "iszExpressShoppingRadius",
                    unitAdornment: "m",
                    type: "number",
                },
            },
        ],
    },
};

const SettingLabel = styled("p")({
    color: Colors.HEADINGS_DARK,
    fontWeight: "600",
    whiteSpace: "nowrap",
});

const SettingsCard = ({ title, settings }: Category) => {
    return (
        <Card>
            <CardHeader title={t(title)} />
            {settings.map((setting) => (
                <div
                    key={setting.field.name}
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: 24,
                        marginTop: 48,
                    }}
                >
                    <div style={{ maxWidth: 339 }}>
                        <SettingLabel>{t(setting.title)}</SettingLabel>
                        {setting.subinfo && <p style={{ maxWidth: 412, marginTop: 8 }}>{t(setting.subinfo)}</p>}
                    </div>
                    <Field
                        key={setting.field.name}
                        component={setting.field.component ?? CustomSettingInputField}
                        label={t(setting.field.label)}
                        name={setting.field.name}
                        type={setting.field.type ?? "input"}
                        options={setting.field.options}
                        style={{ maxWidth: 183, marginTop: 32 }}
                        unitAdornment={setting.field.unitAdornment}
                    />
                </div>
            ))}
        </Card>
    );
};

const SettingsLock = ({
    isActive,
    title,
    name,
    maxLength,
}: {
    isActive: boolean;
    title: string;
    name: string;
    maxLength?: number;
}) => {
    return (
        <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <SettingLabel>{title}</SettingLabel>
                <Field component={CustomSwitch} name={name} type="checkbox" />
            </div>
            <Field
                component={CustomInputField}
                name={`${name}Message`}
                type="input"
                multiline
                minRows={3}
                maxLength={maxLength}
                inputProps={{
                    style: { backgroundColor: Colors.GREY_50_BACKGROUND, borderRadius: 8 },
                }}
                placeholder={t("screen.settings.general.lockMessage.placeholder")}
                showLength
                disabled={!isActive}
            />
            <Field
                component={CustomDateTimePicker}
                name={`${name}Until`}
                textFieldProps={{
                    fullWidth: true,
                    placeholder: t("screen.settings.general.lockUntil"),
                    inputProps: {
                        style: { backgroundColor: Colors.GREY_50_BACKGROUND, borderRadius: 8 },
                    },
                }}
                disabled={!isActive}
            />
        </>
    );
};

const validationSchema = Yup.object().shape({
    orderLockMessage: Yup.string()
        .max(120, t("validationError.maxLength", { maxLength: 120 }))
        .when("orderLock", {
            is: true,
            then: Yup.string().required(t("validation.required.message")),
        }),
    globalLockMessage: Yup.string()
        .max(400, t("validationError.maxLength", { maxLength: 400 }))
        .when("globalLock", {
            is: true,
            then: Yup.string().required(t("validation.required.message")),
        }),
    minimumValueOfGoods: Yup.number()
        .integer(t("validationError.integer"))
        .min(1, t("validationError.amount"))
        .required(t("validationError.required.amount")),
    maximumInvoiceAge: Yup.number()
        .integer(t("validationError.integer"))
        .min(1, t("validationError.invoiceAge"))
        .required(t("validationError.required.invoiceAge")),
    maximumDepositPlaceDistance: Yup.number()
        .integer(t("validationError.integer"))
        .min(1, t("validationError.distance"))
        .required(t("validationError.required.distance")),
    maximumPreferredDeliveryDateDays: Yup.number()
        .integer(t("validationError.integer"))
        .min(4, t("validationError.maximumPreferredDeliveryDateDays"))
        .required(t("validationError.required.maximumPreferredDeliveryDateDays")),
    iszExpressShoppingRadius: Yup.number()
        .integer(t("validationError.integer"))
        .min(1, t("validationError.iszExpressShoppingRadius"))
        .required(t("validationError.required.iszExpressShoppingRadius")),
});

export const SettingsGeneralSite = observer(() => {
    const [selectedDistributor, setSelectedDistributor] = React.useState<Distributor>();
    const { distributors, reloadDistributors } = useDistributors();
    const [isSaving, setIsSaving] = React.useState(false);

    useDeepCompareEffect(() => {
        if (distributors) {
            if (!selectedDistributor) {
                setSelectedDistributor(distributors[0]);
            } else {
                setSelectedDistributor(distributors?.find((distributor) => distributor.id === selectedDistributor?.id));
            }
        }
    }, [distributors, selectedDistributor]);

    const initialValues = {
        globalLock: selectedDistributor?.lockInfo.globalLock ?? false,
        globalLockMessage: selectedDistributor?.lockInfo.globalLockMessage ?? "",
        globalLockUntil: selectedDistributor?.lockInfo.globalLockUntil
            ? dayjs(selectedDistributor?.lockInfo.globalLockUntil)
            : null,
        orderLock: selectedDistributor?.lockInfo.orderLock ?? false,
        orderLockMessage: selectedDistributor?.lockInfo.orderLockMessage ?? "",
        orderLockUntil: selectedDistributor?.lockInfo.orderLockUntil
            ? dayjs(selectedDistributor?.lockInfo.orderLockUntil)
            : null,
        minimumValueOfGoods: selectedDistributor?.minimumValueOfGoods ?? 0,
        maximumInvoiceAge: selectedDistributor?.maximumInvoiceAge ?? 0,
        completeDelivery: selectedDistributor?.completeDelivery ?? false,
        maximumDepositPlaceDistance: selectedDistributor?.maximumDepositPlaceDistance ?? 0,
        maximumPreferredDeliveryDateDays: selectedDistributor?.maximumPreferredDeliveryDateDays ?? 0,
        iszExpressShoppingRadius: selectedDistributor?.iszExpressShoppingRadius ?? 0,
        defaultUserPreferencesId: selectedDistributor?.defaultUserPreferencesId,
        guestUserPreferencesId: selectedDistributor?.guestUserPreferencesId,
    };

    const handleChangeSelectedDistributor = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const selectedDistributorId = event.target.value;
        setSelectedDistributor(distributors?.find((distributor) => distributor.id === selectedDistributorId));
    };

    const handleSubmit = async (model: typeof initialValues) => {
        if (selectedDistributor) {
            setIsSaving(true);
            try {
                generalStore.setIsLoading(true);

                const payload: PutDistributorPayload = {
                    minimumValueOfGoods: model.minimumValueOfGoods,
                    maximumInvoiceAge: model.maximumInvoiceAge,
                    completeDelivery: model.completeDelivery,
                    maximumDepositPlaceDistance: model.maximumDepositPlaceDistance,
                    maximumPreferredDeliveryDateDays: model.maximumPreferredDeliveryDateDays,
                    iszExpressShoppingRadius: model.iszExpressShoppingRadius,
                    lockInfo: {
                        globalLock: model.globalLock,
                        globalLockMessage: model.globalLockMessage,
                        globalLockUntil: model.globalLockUntil?.toDate(),
                        orderLock: model.orderLock,
                        orderLockMessage: model.orderLockMessage,
                        orderLockUntil: model.orderLockUntil?.toDate(),
                    },
                    defaultUserPreferencesId: model.defaultUserPreferencesId,
                    guestUserPreferencesId: model.guestUserPreferencesId,
                };

                await API.putDistributor(selectedDistributor?.id, payload);
                await reloadDistributors();
                generalStore.setSuccessMessage(t("common.success"));
            } catch (error) {
                generalStore.setError(t("error.saveSettings"), error);
            } finally {
                generalStore.setIsLoading(false);
                setIsSaving(false);
            }
        }
    };

    if (!distributors) {
        return null;
    }

    return (
        <SettingSubSiteContainer>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                enableReinitialize
            >
                {({ values }) => (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <div>
                            {distributors && (
                                <TextField
                                    variant="outlined"
                                    label={t("screen.settings.general.select.vko.label")}
                                    value={selectedDistributor?.id ?? ""}
                                    onChange={handleChangeSelectedDistributor}
                                    select
                                    fullWidth
                                    style={{ maxWidth: 240 }}
                                >
                                    {distributors?.map((distributor) => (
                                        <MenuItem value={distributor.id} key={distributor.id}>
                                            {distributor.shortName}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                            {selectedDistributor && (
                                <Form>
                                    <CardContainer>
                                        <CardColumn>
                                            <Card>
                                                <CardHeader
                                                    title={t("screen.settings.general.lockSite")}
                                                    style={{ marginBottom: 48 }}
                                                />
                                                <SettingsLock
                                                    isActive={values.globalLock}
                                                    title={t("screen.settings.general.globalLock")}
                                                    name="globalLock"
                                                    maxLength={400}
                                                />
                                                <SettingsLock
                                                    isActive={values.orderLock}
                                                    title={t("screen.settings.general.orderLock")}
                                                    name="orderLock"
                                                    maxLength={120}
                                                />
                                            </Card>
                                            {ENABLE_GENERAL_SETTINGS_RETURNS && <SettingsCard {...settings.returns} />}
                                        </CardColumn>
                                        <CardColumn>
                                            <SettingsCard {...settings.receipts} />
                                            <SettingsCard {...settings.delivery} />
                                            <SettingsCard {...settings.isz} />
                                        </CardColumn>
                                    </CardContainer>
                                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            disabled={isEqual(values, initialValues)}
                                        >
                                            {t("screen.settings.general.submit")}
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </div>
                        <LeaveSiteDialog blockRouting={!isEqual(values, initialValues) && !isSaving} />
                    </div>
                )}
            </Formik>
        </SettingSubSiteContainer>
    );
});
