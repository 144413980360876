import { createTheme, iconButtonClasses } from "@mui/material";
import { Colors } from "./Colors";
import { Icon } from "./Icon";

export const theme = createTheme({
    typography: {
        fontFamily: "Open Sans, sans-serif",
        fontSize: 16,
    },
    palette: {
        primary: {
            main: Colors.PRIMARY_500,
        },
        secondary: {
            main: Colors.SECONDARY_500,
        },
    },
    mixins: {
        toolbar: {
            minHeight: 80,
            "@media (min-width:0px)": {
                paddingLeft: 108,
                paddingRight: 108,
            },
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    color: "unset",
                    minHeight: 80,
                    backgroundColor: "#FFFFFF",
                    boxShadow: "inset 0px -1px 0px #EDEDED",
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    minHeight: 80,
                    paddingLeft: 24,
                    paddingRight: 24,
                },
            },
        },
        MuiButton: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    textTransform: "unset",
                },
                contained: {
                    padding: "16px 32px",
                    fontSize: 16,
                    lineHeight: "16px",
                    fontWeight: 600,
                    borderRadius: 6,
                    boxShadow: "none",
                    "&:hover": {
                        boxShadow: "none",
                    },
                    backgroundColor: Colors.PRIMARY_500,
                    color: Colors.LIGHT,
                },
                text: {
                    padding: 0,
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    borderBottom: `4px solid ${Colors.PRIMARY_500}`,
                },
            },
        },
        MuiCheckbox: {
            defaultProps: {
                icon: <Icon name="checkboxOff" />,
                indeterminateIcon: <Icon name="checkboxIndeterminate" />,
                checkedIcon: <Icon name="checkboxOn" />,
            },
        },
        MuiTextField: {
            defaultProps: {
                size: "small",
            },
        },
        // MuiInputBase: {
        //     styleOverrides: {
        //         root: {
        //             borderRadius: 6,
        //             border: `1px solid ${Colors.GREY_200}`,
        //         },
        //     },
        // },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "& fieldset": {
                        borderColor: Colors.GREY_200,
                        borderRadius: 6,
                    },
                },
                input: {
                    padding: "11px 12px 10px 12px",
                },
                multiline: {
                    padding: "10px 0 0 0",
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: "#EDEDED",
                },
            },
        },
        MuiTablePagination: {
            defaultProps: {
                // TODO these icons do not work
                backIconButtonProps: {
                    children: <Icon name="chevronLeft" />,
                },
                nextIconButtonProps: {
                    children: <Icon name="chevronRight" />,
                },
            },
            styleOverrides: {
                actions: {
                    [`& .${iconButtonClasses.root}:not(.${iconButtonClasses.disabled})`]: {
                        color: Colors.PRIMARY_500,
                    },
                },
                displayedRows: {
                    fontSize: 14,
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paperAnchorLeft: {
                    width: 407,
                },
            },
        },
        MuiSkeleton: {
            styleOverrides: {
                root: {
                    backgroundColor: Colors.GREY_200,
                },
            },
        },
        // MuiRadio: {
        //     styleOverrides: {
        //         root: {
        //             "&&:hover": {
        //                 backgroundColor: "transparent",
        //             },
        //         },
        //     },
        //     defaultProps: {
        //         disableFocusRipple: true,
        //         disableRipple: true,
        //         disableTouchRipple: true,
        //     },
        // },
        // MuiOutlinedInput: {
        //     styleOverrides: {
        //         input: {
        //             fontSize: 16,
        //             padding: "0 12px",
        //             height: 48,
        //         },
        //     },
        // },
        // MuiTextField: {
        //     styleOverrides: {
        //         root: {
        //             [`& .${formLabelClasses.root}`]: {
        //                 top: -3,
        //             },
        //             [`& .${formLabelClasses.root}:not(.${formLabelClasses.focused}):not(.${formLabelClasses.filled})`]:
        //                 {
        //                     fontSize: 16,
        //                 },
        //         },
        //     },
        // },
    },
});
