import "react-international-phone/style.css";

import { BaseTextFieldProps, InputAdornment, MenuItem, Select, TextField } from "@mui/material";
import { FieldInputProps, FormikState, getIn, useFormikContext } from "formik";
import { CountryCode } from "libphonenumber-js/types";
import { useState } from "react";
import { CountryIso2, FlagImage, defaultCountries, parseCountry, usePhoneInput } from "react-international-phone";
import { CountryData } from "react-international-phone/build/types";
import * as Yup from "yup";
import "yup-phone-lite";
import { MessageIDS, t } from "../../i18n/util";
import { FieldError } from "./FieldError";
import { isEmptyOrOnlyCountryDialCode } from "../util/Helpers";
import { SUPPORTED_MOBILE_PHONE_COUNTRIES } from "../../config";

function countrySort(a: CountryData, b: CountryData): number {
    const countryA = parseCountry(a);
    const countryB = parseCountry(b);

    // Austria in front of Germany
    if (countryA.iso2 === "at" && countryB.iso2 === "de") {
        return -1;
    } else if (countryA.iso2 === "de" && countryB.iso2 === "at") {
        return 1;
    }

    // Austria and Germany in front of other countries
    if (countryA.iso2 === "at" || countryA.iso2 === "de") {
        return -1;
    } else if (countryB.iso2 === "at" || countryB.iso2 === "de") {
        return 1;
    } else {
        const nameA = t(`common.country.${countryA.iso2}` as MessageIDS);
        const nameB = t(`common.country.${countryB.iso2}` as MessageIDS);
        return nameA.localeCompare(nameB);
    }
}

const countries = defaultCountries.filter((country) => {
    const { iso2 } = parseCountry(country);
    return SUPPORTED_MOBILE_PHONE_COUNTRIES.includes(iso2);
});

export interface CustomPhoneInputProps extends BaseTextFieldProps {
    onChange?: (phone: string) => void;
    disableError?: boolean;
    noErrorMargin?: boolean;
    error?: boolean;
    field: FieldInputProps<string>;
    form: FormikState<any>;
    isTouched?: boolean;
    showHint?: boolean; // just to show a hint to the user that phone number could be incorrect - but it does not block submitting (same logic as mobile apps)
}

export const CustomPhoneInput = ({
    onChange,
    disableError,
    noErrorMargin,
    field,
    form,
    error,
    showHint,
    ...rest
}: CustomPhoneInputProps) => {
    const [hint, setHint] = useState("");

    const { setFieldValue } = useFormikContext();

    const fieldError = getIn(form.errors, field.name);
    const fieldTouched = getIn(form.touched, field.name);

    const showError = fieldTouched && !!fieldError;

    const showPhoneHint = showHint && fieldTouched && !!hint;

    const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } = usePhoneInput({
        countries,
        defaultCountry: "at",
        value: field.value,
        onChange: (data) => {
            onChange?.(data.phone);

            setFieldValue(field.name, data.phone);

            setHint("");
            // Don't show hint if only country dial code was entered
            if (!isEmptyOrOnlyCountryDialCode(data.phone, data.country.dialCode)) {
                const countryCode = data.country.iso2.toUpperCase();

                const phoneSchema = Yup.string()
                    .phone(countryCode as CountryCode)
                    .required();

                try {
                    phoneSchema.validateSync(data.phone);
                } catch (error) {
                    setHint(t("validation.phone.hint"));
                }
            }
        },
    });

    return (
        <div style={{ position: "relative", ...rest.style }}>
            <TextField
                variant="outlined"
                label={t("screen.userDetails.edit.form.phone")}
                color="primary"
                placeholder={t("common.phone.placeholder")}
                value={inputValue}
                margin="dense"
                fullWidth
                onChange={handlePhoneValueChange}
                onBlur={field.onBlur}
                name={field.name}
                error={showError || error}
                type="tel"
                inputRef={inputRef}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start" style={{ marginRight: "2px", marginLeft: "-8px" }}>
                            <Select
                                MenuProps={{
                                    style: {
                                        height: "300px",
                                        width: "360px",
                                        top: "10px",
                                        left: "-34px",
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left",
                                    },
                                }}
                                sx={{
                                    width: "max-content",
                                    // Remove default outline (display only on focus)
                                    fieldset: {
                                        display: "none",
                                    },
                                    ".MuiSelect-select": {
                                        padding: "8px",
                                        paddingRight: "24px !important",
                                    },
                                    svg: {
                                        right: 0,
                                    },
                                }}
                                value={country.iso2}
                                onChange={(e) => setCountry(e.target.value as CountryIso2)}
                                renderValue={(value) => <FlagImage iso2={value} style={{ display: "flex" }} />}
                                disabled={rest.disabled}
                            >
                                {countries.sort(countrySort).map((c) => {
                                    const country = parseCountry(c);
                                    return (
                                        <MenuItem key={country.iso2} value={country.iso2}>
                                            <FlagImage iso2={country.iso2} style={{ marginRight: "8px" }} />
                                            <p style={{ marginRight: "8px" }}>
                                                {t(`common.country.${country.iso2}` as MessageIDS)}
                                            </p>
                                            <p>+{country.dialCode}</p>
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </InputAdornment>
                    ),
                }}
                {...rest}
            />
            {(!disableError || showHint) && (
                <FieldError style={{ marginTop: noErrorMargin ? 0 : 6 }}>
                    {showError ? fieldError : showPhoneHint ? hint : ""}
                </FieldError>
            )}
        </div>
    );
};
