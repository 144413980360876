import { Divider } from "@mui/material";
import { Colors } from "../util/Colors";
import { LinkButton } from "./LinkButton";
import { StyledTooltip } from "./Primitives";

// Action on details page within a card
export const CardAction = ({
    title,
    tooltip,
    children,
    color = Colors.PRIMARY_500,
    onClick,
    disabled,
    dividerStyle,
}: {
    title: string;
    tooltip?: string;
    children?: React.ReactNode;
    color?: string;
    onClick: () => void;
    disabled?: boolean;
    dividerStyle?: React.CSSProperties;
}) => {
    const component = (
        <LinkButton color={disabled ? Colors.GREY_500 : color} onClick={onClick} disabled={disabled}>
            {title}
        </LinkButton>
    );
    return (
        <>
            <Divider style={{ marginTop: 8, marginBottom: 8, ...dividerStyle }} />
            {tooltip && (
                <StyledTooltip title={tooltip} style={{ maxWidth: "fit-content" }} placement="right">
                    <div>{component}</div>
                </StyledTooltip>
            )}
            {!tooltip && component}
            {children}
        </>
    );
};
