import { observer } from "mobx-react";
import React from "react";
import { t } from "../../../i18n/util";
import { Colors } from "../../util/Colors";
import { Icon } from "../../util/Icon";
import { Banner } from "../Primitives";

type CustomerLockedBannerProps = {
    style?: React.CSSProperties;
};

export const CustomerLockedBanner = observer((props: CustomerLockedBannerProps) => {
    return (
        <Banner style={{ backgroundColor: Colors.WARNING_2, ...props.style }}>
            <div style={{ display: "flex", alignItems: "center", gap: 24 }}>
                <Icon name="warning" color={Colors.LIGHT} />
                <p className="body2" style={{ color: Colors.LIGHT }}>
                    {t("screen.userDetails.customerLocked.banner.message")}
                </p>
            </div>
        </Banner>
    );
});
