import { IconButton } from "@mui/material";
import { Icon } from "../util/Icon";
import { Colors } from "../util/Colors";

export const CardHeader = ({
    title,
    onEdit,
    editDisabled,
    style,
}: {
    title: string;
    onEdit?: () => void;
    editDisabled?: boolean;
    style?: React.CSSProperties;
}) => (
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", ...style }}>
        <span className="body1Bold" style={{ color: Colors.BODY_2 }}>
            {title}
        </span>
        {onEdit && (
            <IconButton onClick={onEdit} disabled={editDisabled} color="primary">
                <Icon name="edit" useCurrentColor />
            </IconButton>
        )}
    </div>
);
