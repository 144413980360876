import * as React from "react";
import { useDeepCompareEffect } from "use-deep-compare";

export const useTableSelection = (elements: { id: string }[], onSelect?: (selected: string[]) => void) => {
    const [selectedIds, setSelectedIds] = React.useState<string[]>([]);

    // reset state when elements change - eg on page change or filters influencing the current page on a table
    useDeepCompareEffect(() => {
        setSelectedIds([]);
    }, [elements]);

    const toggleSelection = (id: string) => {
        const index = selectedIds.indexOf(id);
        let newSelected: string[] = [];
        if (index < 0) {
            newSelected = newSelected.concat(selectedIds, id);
        } else {
            newSelected = newSelected.concat(selectedIds.slice(0, index), selectedIds.slice(index + 1));
        }

        setSelectedIds(newSelected);
        if (onSelect) {
            onSelect(newSelected);
        }
    };

    const toggleSelectAll = () => {
        let selected: string[] = [];
        if (selectedIds.length === elements.length) {
            selected = [];
        } else {
            selected = elements.map((element) => element.id);
        }

        setSelectedIds(selected);
        if (onSelect) {
            onSelect(selected);
        }
    };

    const isSelected = (id: string) => selectedIds.indexOf(id) >= 0;
    const someSelected = selectedIds.length > 0 && selectedIds.length < elements.length;
    const allSelected = selectedIds.length === elements.length && elements.length > 0;
    const clearSelection = React.useCallback(() => setSelectedIds([]), []);

    const getSelectedElements = <T,>() => {
        return elements.filter((e) => selectedIds.indexOf(e.id) >= 0) as unknown as T[];
    };

    return {
        selectedIds,
        getSelectedElements,
        toggleSelection,
        toggleSelectAll,
        isSelected,
        someSelected,
        allSelected,
        clearSelection,
    };
};

export type TableSelection = ReturnType<typeof useTableSelection>;
