import { Button, Dialog } from "@mui/material";
import * as React from "react";
import { DialogHeader } from "../components/ui/DialogHeader";
import { EmptyState } from "../components/ui/EmptyState";
import { GenericItemSearch } from "../components/ui/GenericItemSearch";
import { GrantedPermissionServicesList } from "../components/ui/GrantedPermissionServicesList";
import { PermissionsContainer, TwoColumnLayoutContainer } from "../components/ui/Primitives";
import { Colors } from "../components/util/Colors";
import { MAX_PAGE_SIZE } from "../config";
import { t } from "../i18n/util";
import { API } from "../network/API";
import { Role } from "../network/APITypes";
import { generalStore } from "../stores/GeneralStore";
import { useRole } from "./useRole";
import { useRoles } from "./useRoles";

const RoleItem = ({ role, style }: { role: Role; style?: React.CSSProperties }) => {
    return (
        <>
            <div className="body1Bold" style={{ color: Colors.HEADINGS_DARK, ...style }}>
                {role.name}
            </div>
            <div className="body1" style={{ fontSize: 14, marginTop: 4, ...style }}>
                {role.description || "-"}
            </div>
            {role.roleGroup?.name && (
                <div className="body1" style={{ fontSize: 14, marginTop: 4, ...style }}>
                    {`${t("common.roleGroup")}: ${role.roleGroup?.name}`}
                </div>
            )}
        </>
    );
};

export const useCopyRolePermissionsDialog = ({
    targetRoleId,
    onBack,
    onSubmit,
}: {
    targetRoleId?: string;
    onBack?: () => void;
    onSubmit?: () => void;
}) => {
    const [open, setOpen] = React.useState(false);
    const [search, setSearch] = React.useState<string>();
    const [selectedRoleId, setSelectedRoleId] = React.useState<string>();

    const { role, reset: resetRole } = useRole({
        id: selectedRoleId,
        options: {
            offset: 0,
            limit: MAX_PAGE_SIZE,
        },
    });

    const { rolesResponse } = useRoles({ status: ["active", "system"], limit: 20, search }, open);

    React.useEffect(() => {
        setSelectedRoleId(undefined);
        resetRole();
    }, [search, resetRole]);

    React.useEffect(() => {
        setSearch(undefined);
        setSelectedRoleId(undefined);
        resetRole();
    }, [open, resetRole]);

    const handleClose = () => {
        setOpen(false);
        onBack?.();
    };

    const handleSubmit = async () => {
        if (!targetRoleId || !selectedRoleId || !role) {
            return;
        }
        try {
            generalStore.isLoading = true;
            await API.putRoleServices(targetRoleId, {
                permissionServices: role.permissionServices?.filter((s) => s.isGranted).map((s) => s.identifier) ?? [],
            });
            setOpen(false);
            onSubmit?.();
        } catch (error) {
            generalStore.setError(t("error.editRole"), error);
        } finally {
            generalStore.isLoading = false;
        }
    };

    const hasPermissionServices =
        role &&
        role.permissionServices &&
        role.permissionServices.filter((permissionService) => permissionService.isGranted).length > 0;

    const component = (
        <Dialog fullScreen open={open} onClose={handleClose} PaperProps={{ style: { padding: 32 } }}>
            <DialogHeader title={t("copyRolePermissionsDialog.title")} onBack={handleClose} onClose={handleClose} />
            <TwoColumnLayoutContainer style={{ marginTop: 32 }}>
                <GenericItemSearch
                    title={t("common.role")}
                    placeholder={t("common.search.placeholder")}
                    items={rolesResponse?.roles?.filter((r) => r.id !== targetRoleId) ?? []}
                    onChangeSearch={setSearch}
                    onClickItem={(role) => setSelectedRoleId(role.id)}
                    itemRenderer={(role, itemStyle) => <RoleItem role={role} style={itemStyle} />}
                />
                <PermissionsContainer>
                    {role && hasPermissionServices && (
                        <>
                            <div className="body1Bold" style={{ color: Colors.BODY_2 }}>
                                {t("common.permissions")}
                            </div>
                            <GrantedPermissionServicesList
                                permissionServices={role.permissionServices}
                                style={{ marginTop: 24 }}
                            />
                        </>
                    )}
                    {role && !hasPermissionServices && (
                        <EmptyState
                            iconName="sadFace"
                            title={t("copyRolePermissionsDialog.emptyScreen.noPermissions.title")}
                            description={t("copyRolePermissionsDialog.emptyScreen.noPermissions.description")}
                        />
                    )}
                    {!role && (
                        <div style={{ display: "flex", flexGrow: 1, justifyContent: "center" }}>
                            <EmptyState
                                iconName="search"
                                title={t("copyRolePermissionsDialog.emptyScreen.noSelectedRole.title")}
                                description={t("copyRolePermissionsDialog.emptyScreen.noSelectedRole.description")}
                            />
                        </div>
                    )}
                </PermissionsContainer>
            </TwoColumnLayoutContainer>
            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 32 }}>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={!role || !hasPermissionServices}
                >
                    {t("copyRolePermissionsDialog.button")}
                </Button>
            </div>
        </Dialog>
    );

    return {
        open() {
            setOpen(true);
        },
        component,
    };
};
