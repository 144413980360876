import { AppBar, Button, MenuItem, Tab, tabClasses, TabProps, Tabs, Toolbar, ToolbarProps } from "@mui/material";
import { styled } from "@mui/system";
import { observer } from "mobx-react";
import * as React from "react";
import { Link, LinkProps, useLocation } from "react-router-dom";
import { t } from "../../i18n/util";
import { authStore } from "../../stores/AuthStore";
import { generalStore } from "../../stores/GeneralStore";
import { pushRoute } from "../app/router/history";
import { Routes } from "../app/router/Routes";
import { UsersRoutes } from "../users/router/UsersRoutes";
import { Colors } from "../util/Colors";
import { Icon } from "../util/Icon";
import { ImageLogo } from "../util/Images";
import { CmsMenu, NeutralButton } from "./Primitives";

const TOOLBAR_HEIGHT = 80;

const StyledToolbar = styled(Toolbar)<ToolbarProps>(() => ({
    display: "flex",
    justifyContent: "space-between",
}));

type LinkTabProps = TabProps & LinkProps;

const LinkTab = (props: LinkTabProps) => {
    return <Tab disableRipple component={Link} {...props} />;
};

export const StyledLinkTab = styled(LinkTab)(() => ({
    color: Colors.BODY_2,
    height: TOOLBAR_HEIGHT,
    textTransform: "unset",
    padding: 0,
    margin: "0px 28px",

    [`&.${tabClasses.selected}`]: {
        fontWeight: 700,
    },
}));

const StyledButton = styled(Button)({
    "&:hover": {
        backgroundColor: "transparent",
    },
});

const StyledMenuItem = styled(MenuItem)({
    backgroundColor: Colors.LIGHT,
    padding: 16,
    minWidth: 264,
    cursor: "default",
    userSelect: "auto",
});

const Badge = styled("div")({
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 20,
    height: 20,
    color: Colors.LIGHT,
    fontSize: 10,
    fontWeight: 600,
    borderRadius: 20,
    backgroundColor: Colors.PRIMARY_500,
});

const getNavigationValue = (pathname: string) => {
    if (pathname.startsWith(UsersRoutes.ROOT)) {
        return 0;
    } else if (pathname.startsWith(Routes.REQUESTS)) {
        return 1;
    } else if (pathname.startsWith(Routes.INTERFACES)) {
        return 2;
    } else if (pathname.startsWith(Routes.SETTINGS)) {
        return 3;
    } else if (pathname.startsWith(Routes.EXPO_TICKETS)) {
        return 4;
    }
};

export const Navigation = observer(() => {
    const location = useLocation();
    const [userMenuAnchorElement, setUserMenuAnchorElement] = React.useState<null | HTMLElement>(null);

    const handleClickUserMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setUserMenuAnchorElement(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setUserMenuAnchorElement(null);
    };

    const handleClickLogout = async () => {
        handleCloseUserMenu();
        await authStore.logout();
    };

    const handleClickLogo = () => {
        pushRoute(Routes.USERS);
    };

    const fullName =
        `${authStore.userProfile?.given_name ?? ""} ${authStore.userProfile?.family_name ?? ""}`.trim() || undefined;

    return (
        <React.Fragment>
            <AppBar position="fixed">
                <StyledToolbar>
                    <ImageLogo onClick={handleClickLogo} style={{ cursor: "pointer" }} />
                    <div>
                        <Tabs value={getNavigationValue(location.pathname)}>
                            <StyledLinkTab to={Routes.USERS} label={t("navigation.tab.users")} />
                            <StyledLinkTab
                                to={Routes.REQUESTS}
                                label={t("navigation.tab.requests")}
                                iconPosition="end"
                                icon={
                                    generalStore.dashboardInfo && generalStore.dashboardInfo.openRequests > 0 ? (
                                        <Badge>{generalStore.dashboardInfo.openRequests}</Badge>
                                    ) : undefined
                                }
                            />
                            <StyledLinkTab to={Routes.INTERFACES} label={t("screen.interfaces.title")} />
                            <StyledLinkTab to={Routes.SETTINGS} label={t("navigation.tab.settings")} />
                            <StyledLinkTab to={Routes.EXPO_TICKETS} label={t("navigation.tab.expoTickets")} />
                        </Tabs>
                    </div>
                    <div>
                        <StyledButton
                            aria-controls="user-menu"
                            aria-haspopup="true"
                            disableRipple
                            onClick={handleClickUserMenu}
                        >
                            <span style={{ whiteSpace: "nowrap", color: Colors.BODY_2 }}>
                                {fullName ?? authStore.username}
                            </span>{" "}
                            <Icon name="caretDown" color="currentColor" />
                        </StyledButton>
                        <CmsMenu
                            anchorEl={userMenuAnchorElement}
                            open={!!userMenuAnchorElement}
                            onClose={handleCloseUserMenu}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                        >
                            <StyledMenuItem disableRipple style={{ backgroundColor: Colors.LIGHT }}>
                                <div style={{ width: "100%" }}>
                                    {fullName && <p style={{ color: Colors.DARK, fontWeight: 600 }}>{fullName}</p>}
                                    {fullName && authStore.username && <p className="body2">{authStore.username}</p>}
                                    <NeutralButton
                                        fullWidth
                                        onClick={handleClickLogout}
                                        style={{ marginTop: fullName ? 16 : undefined }}
                                    >
                                        {t("common.logout")}
                                    </NeutralButton>
                                </div>
                            </StyledMenuItem>
                        </CmsMenu>
                    </div>
                </StyledToolbar>
            </AppBar>
            <Toolbar />
        </React.Fragment>
    );
});
