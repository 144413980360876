import { DateTimePicker, DateTimePickerProps, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import "dayjs/locale/de";
import { FieldInputProps, FormikProps, FormikValues, getIn } from "formik";
import * as React from "react";
import { FieldError } from "./FieldError";
import { TextFieldProps } from "@mui/material";

type IProps<Values extends FormikValues> = DateTimePickerProps<Dayjs> & {
    field: FieldInputProps<Dayjs | null>;
    form: FormikProps<Values>;
    showValidationErrorText?: boolean;
    style?: React.CSSProperties;
    required?: boolean;
    textFieldProps?: TextFieldProps;
};

export const CustomDateTimePicker = <Values extends FormikValues>({
    style,
    label,
    required,
    form,
    field,
    disabled,
    showValidationErrorText = true,
    textFieldProps,
}: IProps<Values>) => {
    const fieldError = getIn(form.errors, field.name);
    const showError = getIn(form.touched, field.name) && !!fieldError;

    return (
        <div style={style}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                <DateTimePicker
                    slotProps={{
                        textField: { variant: "outlined", name: field.name, ...textFieldProps },
                        field: { clearable: true },
                    }}
                    label={required ? `${label} *` : label}
                    value={field.value}
                    onChange={(date: Dayjs | null) => {
                        form.setFieldValue(field.name, date ?? null, true);
                    }}
                    ampm={false}
                    disablePast
                    disabled={disabled}
                />
            </LocalizationProvider>
            {showValidationErrorText && <FieldError>{showError ? fieldError : ""}</FieldError>}
        </div>
    );
};
