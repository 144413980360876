import { AppRouter } from "../router/AppRouter";
import * as React from "react";
import { observer } from "mobx-react";
import { authStore } from "../../../stores/AuthStore";
import { generalStore } from "../../../stores/GeneralStore";
export const AppContainerSite = observer(() => {
    const isAuthenticated = authStore.isAuthenticated;

    React.useEffect(() => {
        const loadData = async () => {
            if (isAuthenticated) {
                await authStore.getUserProfile();
                await generalStore.loadDashboardInfo();
            }
        };

        loadData();
    }, [isAuthenticated]);

    return <AppRouter />;
});
