import * as React from "react";
import { t } from "../i18n/util";
import { API } from "../network/API";
import { UserRequest } from "../network/APITypes";
import { generalStore } from "../stores/GeneralStore";

export const useUserDetails = (userId?: string) => {
    const [response, setResponse] = React.useState<UserRequest | null>(null);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);

    const load = React.useCallback(async () => {
        if (!userId) {
            return;
        }

        try {
            generalStore.setIsLoading(true);
            setIsLoading(true);
            const response = await API.getUserDetails(userId);
            setResponse(response);
        } catch (err) {
            generalStore.setError(t("error.userDetails"), err);
        } finally {
            generalStore.setIsLoading(false);
            setIsLoading(false);
        }
    }, [userId]);

    React.useEffect(() => {
        load();
    }, [load]);

    return { user: response, setUser: setResponse, reloadUser: load, userIsLoading: isLoading };
};
