import * as React from "react";
import { ManageDomainForm } from "../components/forms/addDomain/ManageDomainForm";
import { CustomDrawer } from "../components/ui/CustomDrawer";
import { AccountTypeDomain, UserRequest } from "../network/APITypes";

export const useManageDomainForm = ({
    onSubmit,
    onClose,
    domain,
}: {
    onSubmit?: () => void | Promise<void>;
    onClose?: () => void;
    domain?: AccountTypeDomain;
}) => {
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        onClose?.();
        setOpen(false);
    };

    const component = open ? (
        <CustomDrawer open={open} onClose={handleClose}>
            <ManageDomainForm onClose={handleClose} onSubmit={onSubmit} domain={domain} />
        </CustomDrawer>
    ) : null;

    return {
        open() {
            setOpen(true);
        },

        component,
    };
};
