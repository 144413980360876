import { Skeleton, SkeletonProps } from "@mui/material";
import { CSSProperties, ComponentType, ReactNode } from "react";

export const TableCellSkeleton = ({
    CellComponent,
    children,
    style,
    skeletonStyle,
    padding,
    variant,
}: {
    CellComponent: ComponentType<{ children: ReactNode; style?: CSSProperties; padding?: string }>;
    children?: ReactNode;
    style?: CSSProperties;
    skeletonStyle?: CSSProperties;
    padding?: string;
    variant?: SkeletonProps["variant"];
}) => {
    return (
        <CellComponent style={{ ...style }} padding={padding}>
            <Skeleton animation="wave" variant={variant ? variant : "text"} style={{ ...skeletonStyle }}>
                {children}
            </Skeleton>
        </CellComponent>
    );
};
