import { IconButton, Table, TableBody, TableCell, TableRow, Tooltip } from "@mui/material";
import { Distributor, GetCMSCustomersResponse } from "../../../../../network/APITypes";
import { CmsTableContainer } from "../../../../ui/Primitives";
import { TableHeader } from "../../../../ui/TableHeader";
import { Icon } from "../../../../util/Icon";
import { Colors } from "../../../../util/Colors";
import { t } from "../../../../../i18n/util";

export function IdsCustomersTable(props: {
    customers: GetCMSCustomersResponse | null;
    distributors: Distributor[] | null;
    handleDeleteAlternativeMapping: (id: string) => void;
    pagination: {
        component: (total: number) => JSX.Element;
    };
}) {
    return (
        <>
            {props.pagination.component(props.customers?.total ?? 0)}
            <CmsTableContainer>
                <Table>
                    <TableHeader
                        headerFields={[
                            { column: "customerNumber", label: "table.label.customerNumber" },
                            { column: "vkoShortName", label: "table.label.vko" },
                            { column: "companyName", label: "table.label.companyName" },
                            { column: "actions", style: { width: 0 } },
                        ]}
                    />
                    <TableBody>
                        {props.customers?.customers && props.customers.customers.length < 1 ? (
                            <p style={{ margin: 8 }}>{t("screen.users.emptyScreen.description")}</p>
                        ) : (
                            <>
                                {props.customers?.customers?.map((customer) => (
                                    <TableRow key={customer.id}>
                                        <TableCell>{customer.externalId}</TableCell>
                                        <TableCell>
                                            {
                                                props.distributors?.find((vko) => vko.id === customer.distributorId)
                                                    ?.shortName
                                            }
                                        </TableCell>
                                        <TableCell>{customer.name}</TableCell>
                                        <TableCell>
                                            <Tooltip title={t("modal.delete.alternativeidsmapping.title")}>
                                                <IconButton
                                                    onClick={() => props.handleDeleteAlternativeMapping(customer.id)}
                                                >
                                                    <Icon color={Colors.ERROR} name="delete" size={32} />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </>
                        )}
                    </TableBody>
                </Table>
            </CmsTableContainer>
        </>
    );
}
