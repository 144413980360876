import { Button, styled } from "@mui/material";
import { Field, Form, Formik } from "formik";
import * as React from "react";
import * as Yup from "yup";
import { useQuery } from "../../../hooks/useQuery";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { generalStore } from "../../../stores/GeneralStore";
import { pushRoute } from "../../app/router/history";
import { Routes } from "../../app/router/Routes";
import { CustomInputField } from "../../ui/CustomInputField";
import { Colors } from "../../util/Colors";
import { passwordRegex } from "../../util/Helpers";
import { AuthContainerSite } from "./AuthContainerSite";

type ForgotPasswordResetValues = {
    password: string;
    repeatPassword: string;
};

const SuccessMessageContainer = styled("div")({
    display: "flex",
    alignItems: "center",
    color: Colors.SUCCESS,
    fontSize: 16,
    height: 50,
    padding: "8px 0",
    marginBottom: 16,
});

export const AuthForgotPasswordResetSite = () => {
    const [success, setSuccess] = React.useState(false);

    const { token }: { token: string } = useQuery();

    if (!token) {
        pushRoute(Routes.ROOT);
        return null;
    }

    const handleSubmit = async (values: ForgotPasswordResetValues) => {
        try {
            generalStore.isLoading = true;
            await API.postChangePassword(values.password, token);
            setSuccess(true);
            setTimeout(() => {
                pushRoute(Routes.ROOT);
            }, 5000);
        } catch (error) {
            generalStore.setError(t("screen.forgotPassword.reset.error"), error);
        } finally {
            setSuccess(false);
            generalStore.isLoading = false;
        }
    };

    return (
        <AuthContainerSite>
            <h1>{t("screen.forgotPassword.reset.title")}</h1>
            <p style={{ color: Colors.BODY_2, marginTop: 8 }}>{t("screen.forgotPassword.reset.description")}</p>
            <Formik
                initialValues={{
                    password: "",
                    repeatPassword: "",
                }}
                onSubmit={handleSubmit}
                validationSchema={Yup.object().shape({
                    password: Yup.string()
                        .matches(passwordRegex, t("screen.forgotPassword.reset.form.error.passwordWeak"))
                        .required(t("screen.forgotPassword.reset.form.error.passwordNotSet")),
                    repeatPassword: Yup.string()
                        .oneOf([Yup.ref("password")], t("screen.forgotPassword.reset.error.passwordsDoNotMatch"))
                        .required(t("screen.forgotPassword.reset.form.error.passwordNotSet")),
                })}
            >
                <Form style={{ marginTop: 24 }}>
                    <Field
                        component={CustomInputField}
                        label={t("screen.forgotPassword.reset.form.newPassword")}
                        name="password"
                        type="password"
                    />
                    <Field
                        component={CustomInputField}
                        label={t("screen.forgotPassword.reset.form.repeatPassword")}
                        name="repeatPassword"
                        type="password"
                    />
                    {success && (
                        <SuccessMessageContainer>{t("screen.forgotPassword.reset.success")}</SuccessMessageContainer>
                    )}
                    <Button variant="contained" color="primary" type="submit" fullWidth>
                        {t("screen.forgotPassword.reset.form.button")}
                    </Button>
                </Form>
            </Formik>
        </AuthContainerSite>
    );
};
