import * as React from "react";
import { useBlockRouting } from "../../hooks/useBlockRouting";
import { t } from "../../i18n/util";
import { ConfirmationDialog } from "./ConfirmationDialog";
import { useFormikContext } from "formik";

type LeaveSiteDialogProps = {
    blockRouting?: boolean;
};

export const LeaveSiteDialog = ({ blockRouting }: LeaveSiteDialogProps) => {
    const [open, setOpen] = React.useState(false);
    const { submitForm } = useFormikContext();
    const handleClose = () => setOpen(false);
    const handleBlock = () => setOpen(true);

    const blockRoutingHook = useBlockRouting(blockRouting, handleBlock);

    const handleSubmit = async () => {
        handleClose();
        await submitForm();

        if (blockRouting) {
            blockRoutingHook.unblockRouting();
        }
    };

    const handleCancel = () => {
        handleClose();

        if (blockRouting) {
            blockRoutingHook.unblockRouting();
        }
    };

    return (
        <ConfirmationDialog
            open={open}
            title={t("user.action.unsavedChangesDialog.title")}
            message={t("user.action.unsavedChangesDialog.message")}
            submitLabel={t("user.action.unsavedChangesDialog.submitLabel")}
            onSubmit={handleSubmit}
            cancelLabel={t("user.action.unsavedChangesDialog.cancelLabel")}
            onCancel={handleCancel}
        />
    );
};
