import { styled } from "@mui/material";
import { isNumber } from "lodash";
import * as React from "react";
import { t } from "../../i18n/util";
import { BaseUserRequest } from "../../network/APITypes";
import { Colors } from "./Colors";
import { getFormattedDate } from "./Helpers";

const StyledMessageContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: 16,
});

type ConfirmationOptions = {
    user?: BaseUserRequest | null;
    count?: number;
    countWithoutPhoneNumber?: number;
    countWithoutEmail?: number;
    multi?: boolean;
};

// https://stackoverflow.com/a/57300713
const getSingleOrMultiKey = <T extends string>(singleKey: T, { count, multi }: ConfirmationOptions = {}) =>
    multi && count && count > 1 ? (`${singleKey}.multi` as `${T}.multi`) : singleKey;

const getUserDialogConfigurations = (options?: ConfirmationOptions) => ({
    delete: {
        title: t(getSingleOrMultiKey("user.action.deleteDialog.title", options)),
        message: t(getSingleOrMultiKey("user.action.deleteDialog.message", options), { count: options?.count }),
        submitLabel: t(getSingleOrMultiKey("user.action.deleteDialog.submitLabel", options)),
        cancelLabel: t("common.no"),
    },
    deactivate: {
        title: t(getSingleOrMultiKey("user.action.deactivateDialog.title", options)),
        message: t(getSingleOrMultiKey("user.action.deactivateDialog.message", options), { count: options?.count }),
        submitLabel: t(getSingleOrMultiKey("user.action.deactivateDialog.submitLabel", options), {
            count: options?.count,
        }),
        cancelLabel: t("common.no"),
    },
    activate: {
        title: t(getSingleOrMultiKey("user.action.activateDialog.title", options)),
        message: t(getSingleOrMultiKey("user.action.activateDialog.message", options), { count: options?.count }),
        submitLabel: t(getSingleOrMultiKey("user.action.activateDialog.submitLabel", options), {
            count: options?.count,
        }),
        cancelLabel: t("common.no"),
    },
    invite: {
        title: t(getSingleOrMultiKey("user.action.inviteDialog.title", options)),
        message: t(getSingleOrMultiKey("user.action.inviteDialog.message", options), { count: options?.count }),
        submitLabel: t("user.action.inviteDialog.submitLabel"),
        secondaryActionLabel: t("user.action.inviteDialog.secondaryActionLabel"),
        cancelLabel: t("common.no"),
    },
    reinvite: {
        title: t(getSingleOrMultiKey("user.action.reinviteDialog.title", options)),
        message:
            !options?.count || options?.count === 1
                ? t("user.action.reinviteDialog.message", {
                      name: `${options?.user?.firstName} ${options?.user?.lastName}`,
                  })
                : t("user.action.reinviteDialog.message.multi", { count: options?.count }),
        submitLabel: t("user.action.reinviteDialog.submitLabel"),
        secondaryActionLabel: t("user.action.reinviteDialog.secondaryActionLabel"),
        cancelLabel: t("common.no"),
    },
    approve: {
        title: t(getSingleOrMultiKey("user.action.approveUserDialog.title", options)),
        message: t(getSingleOrMultiKey("user.action.approveUserDialog.message", options), { count: options?.count }),
        submitLabel: t(getSingleOrMultiKey("user.action.approveUserDialog.submitLabel", options), {
            count: options?.count,
        }),
        cancelLabel: t("common.no"),
    },
    deny: {
        title: t(getSingleOrMultiKey("user.action.denyUserDialog.title", options)),
        message: t(getSingleOrMultiKey("user.action.denyUserDialog.message", options), { count: options?.count }),
        submitLabel: t(getSingleOrMultiKey("user.action.denyUserDialog.submitLabel", options), {
            count: options?.count,
        }),
        cancelLabel: t("common.no"),
    },
    deactivateUserManagement: {
        title: t("user.action.deactivateUserManagementDialog.title"),
        message: t("user.action.deactivateUserManagementDialog.message", {
            externalCustomerId: options?.user?.externalCustomerID,
        }),
        submitLabel: t("user.action.deactivateUserManagementDialog.submitLabel"),
        cancelLabel: t("common.no"),
    },
    requestUserManagement: {
        title: t("user.action.requestUserManagementDialog.title"),
        message: (
            <div>
                <p style={{ color: Colors.BODY_2 }}>{t("user.action.requestUserManagementDialog.message")}</p>

                <p className="body2">
                    {options?.user?.customer?.optInLastRequestedAt
                        ? t("common.lastRequested", {
                              date: getFormattedDate(options?.user?.customer?.optInLastRequestedAt),
                          })
                        : t("common.neverRequested")}
                </p>
            </div>
        ),
        submitLabel: t("user.action.requestUserManagementDialog.submitLabel"),
        cancelLabel: t("common.no"),
    },
    forceUserManagement: {
        title: t("user.action.forceUserManagementDialog.title"),
        message: t("user.action.forceUserManagementDialog.message", {
            externalCustomerId: options?.user?.externalCustomerID,
        }),
        submitLabel: t("user.action.forceUserManagementDialog.submitLabel"),
        cancelLabel: t("common.no"),
    },
    unprocessableUnknown: {
        title: t(getSingleOrMultiKey("user.action.notProcessableDialog.title", options)),
        message: t(getSingleOrMultiKey("user.action.notProcessableDialog.message", options), { count: options?.count }),
        submitLabel: t(getSingleOrMultiKey("user.action.notProcessableDialog.submitLabel", options)),
        hideCancel: true,
    },
    unprocessableInviteSms: {
        title: t(getSingleOrMultiKey("user.action.notProcessableDialog.title", options)),
        message: (
            <StyledMessageContainer>
                {options && isNumber(options?.count) && options.count > 0 && (
                    <p style={{ color: Colors.BODY_2 }}>
                        {t(getSingleOrMultiKey("user.action.notProcessableDialog.message", options), {
                            count: options?.count,
                        })}
                    </p>
                )}
                {options && isNumber(options?.countWithoutPhoneNumber) && options.countWithoutPhoneNumber > 0 && (
                    <p style={{ color: Colors.BODY_2 }}>
                        {t(
                            getSingleOrMultiKey("user.action.notProcessableInviteSmsDialog.message", {
                                ...options,
                                count: options.countWithoutPhoneNumber,
                            }),
                            {
                                count: options.countWithoutPhoneNumber,
                            },
                        )}
                    </p>
                )}
            </StyledMessageContainer>
        ),
        submitLabel: t(getSingleOrMultiKey("user.action.notProcessableDialog.submitLabel", options)),
        hideCancel: true,
    },
    unprocessableInviteEmail: {
        title: t(getSingleOrMultiKey("user.action.notProcessableDialog.title", options)),
        message: (
            <StyledMessageContainer>
                {options && isNumber(options?.count) && options.count > 0 && (
                    <p style={{ color: Colors.BODY_2 }}>
                        {t(getSingleOrMultiKey("user.action.notProcessableDialog.message", options), {
                            count: options?.count,
                        })}
                    </p>
                )}
                {options && isNumber(options?.countWithoutEmail) && options.countWithoutEmail > 0 && (
                    <p style={{ color: Colors.BODY_2 }}>
                        {t(
                            getSingleOrMultiKey("user.action.notProcessableInviteEmailDialog.message", {
                                ...options,
                                count: options.countWithoutEmail,
                            }),
                            {
                                count: options.countWithoutEmail,
                            },
                        )}
                    </p>
                )}
            </StyledMessageContainer>
        ),
        submitLabel: t(getSingleOrMultiKey("user.action.notProcessableDialog.submitLabel", options)),
        hideCancel: true,
    },
    resetPassword: {
        title: t("user.action.resetPasswordDialog.title"),
        message: t("user.action.resetPasswordDialog.message"),
        submitLabel: t("user.action.resetPasswordDialog.submitLabel"),
        cancelLabel: t("common.no"),
    },
    resetPasswordByMethod: {
        title: t("user.action.resetPasswordDialog.title"),
        message: t("user.action.resetPasswordByMethodDialog.message"),
        submitLabel: t("user.action.resetPasswordByMethodDialog.submitLabel"),
        secondaryActionLabel: t("user.action.resetPasswordByMethodDialog.secondaryActionLabel"),
        cancelLabel: t("common.no"),
    },
});

export type DialogVariant = keyof ReturnType<typeof getUserDialogConfigurations>;

export const getUserDialogConfiguration = (variant: DialogVariant, options?: ConfirmationOptions) => {
    return getUserDialogConfigurations(options)[variant];
};
