import { Button, IconButton } from "@mui/material";
import { Form, Formik } from "formik";
import { omit } from "lodash";
import * as React from "react";
import * as Yup from "yup";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { AccountTypeDomain, AccountTypeDomainPayload } from "../../../network/APITypes";
import { generalStore } from "../../../stores/GeneralStore";
import { Icon } from "../../util/Icon";
import { DomainDetailsFields } from "./DomainDetailsFields";

const validationSchema = Yup.object().shape({
    domain: Yup.string().required(t("validationError.required.domain")),
    accountType: Yup.string().required(t("validationError.required.accountType")),
    description: Yup.string().max(100, t("validationError.domainDescriptionLength", { maxLength: 100 })),
});

export const ManageDomainForm = ({
    onClose,
    onSubmit,
    domain,
}: {
    onClose: () => void;
    domain?: AccountTypeDomain;
    onSubmit?: () => void | Promise<void>;
}) => {
    const initialValues = {
        accountType: domain?.accountType ?? "",
        description: domain?.description ?? "",
        domain: domain?.domain ?? "",
        id: domain?.id ?? "",
    };

    const handleSubmit = async (model: typeof initialValues) => {
        try {
            generalStore.setIsLoading(true);
            const sanitizedModel = omit(model, "id") as AccountTypeDomainPayload;

            if (domain) {
                await API.putDomain(domain.id, sanitizedModel);
                generalStore.setSuccessMessage(t("success.editDomain"));
            } else {
                await API.postDomain(sanitizedModel);
                generalStore.setSuccessMessage(t("success.addDomain"));
            }
        } catch (error) {
            if (domain) {
                generalStore.setError(t("error.editDomain"), error);
            } else {
                generalStore.setError(t("error.addDomain"), error);
            }
        } finally {
            await onSubmit?.();
            onClose();
            generalStore.setIsLoading(false);
        }
    };

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
            <Form
                style={{
                    width: "100%",
                    height: "inherit",
                    overflow: "auto",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
                noValidate
            >
                <div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <h4>{domain ? t("manageDomainForm.edit.title") : t("manageDomainForm.add.title")}</h4>
                        <IconButton onClick={onClose}>
                            <Icon name="close" />
                        </IconButton>
                    </div>
                    <div style={{ marginTop: 40 }}>
                        <DomainDetailsFields domain={domain ?? undefined} />
                    </div>
                </div>
                <Button type="submit" variant="contained" style={{ marginTop: 32 }}>
                    {domain ? t("manageDomainForm.edit.button.save") : t("manageDomainForm.add.button.save")}
                </Button>
            </Form>
        </Formik>
    );
};
