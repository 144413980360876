import { makeAutoObservable, runInAction } from "mobx";
import { ILocales } from "../i18n/ILocales";
import { DEFAULT_LOCALE, t } from "../i18n/util";
import { API } from "../network/API";
import { DashboardInfo, UserRequest } from "../network/APITypes";
import { makePersistable } from "mobx-persist-store";
import localforage from "localforage";
import { ITab } from "../types";

class GeneralStore {
    locale: ILocales = DEFAULT_LOCALE;
    _isLoading = false;
    error?: { message: string; error?: any };
    successMessage?: string;
    customerLocked?: boolean;
    dashboardInfo?: DashboardInfo;
    user?: UserRequest = undefined;
    lastLocation?: string;
    forceReloadUser = false;
    loadingOverlayMs?: number;
    tabs: ITab[] = [];

    constructor() {
        makeAutoObservable(this);
        makePersistable(this, { name: "tabStore", properties: ["tabs"], storage: localforage });
    }

    set isLoading(value: boolean) {
        this._isLoading = value;
    }

    get isLoading() {
        return this._isLoading;
    }

    setIsLoading = (isLoading: boolean) => {
        this.isLoading = isLoading;
    };

    setLoadingOverlayMs = (ms?: number) => {
        this.loadingOverlayMs = ms;
    };

    setError = (message: string, error?: any) => {
        this.error = { message, error: error };
    };

    unsetError = () => {
        this.error = undefined;
    };

    setUser(user?: UserRequest) {
        this.user = user;
    }

    setSuccessMessage(message?: string) {
        this.successMessage = message;
    }

    setCustomerLocked(locked?: boolean) {
        this.customerLocked = locked;
    }

    loadDashboardInfo = async () => {
        try {
            const dashboardInfo = await API.getDashboardInfo();

            runInAction(() => {
                this.dashboardInfo = dashboardInfo;
            });
        } catch (error) {
            this.setError(t("error.dashboardInfo"), error);
        }
    };

    addTabs = (newTabs: ITab[]) => {
        this.setTabs([...this.tabs, ...this.filterNoneExistingTabs(newTabs)]);
    };

    removeTabs = (tabIds: string[]) => {
        this.setTabs(this.tabs.filter((tab) => !tabIds.includes(tab.id)));
    };

    updateTabValue = (iTab: ITab) => {
        this.setTabs(
            this.tabs.map((tab) => {
                if (tab.id === iTab.id) {
                    return iTab;
                }

                return tab;
            }),
        );
    };

    filterNoneExistingTabs = (newTabs: ITab[]) => {
        return newTabs.filter((newTab) => !this.doesTabExist(newTab.id));
    };

    doesTabExist = (tabId: string) => {
        return this.tabs.find((tab) => tab.id === tabId);
    };

    getTabs() {
        return this.tabs;
    }

    setTabs(tabs: ITab[]) {
        this.tabs = tabs;
    }
}

const generalStore = new GeneralStore();

export { generalStore };
