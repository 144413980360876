import { styled } from "@mui/system";
import * as React from "react";
import { Colors } from "../../util/Colors";
import { FilterContainer } from "./FilterContainer";

type ToggleFilterButtonProps = {
    label: string;
    value?: boolean;
    onChange: (selected?: boolean) => void;
};

export const ToggleFilterButton = ({ label, value, onChange }: ToggleFilterButtonProps) => {
    const [selected, setSelected] = React.useState(value);

    const handleClick = () => {
        const nextValue = selected === true ? undefined : !selected;

        setSelected(nextValue);
        onChange(nextValue);
    };

    return (
        <FilterContainer isSelected={!!value} onClick={handleClick}>
            <span>{label}</span>
        </FilterContainer>
    );
};
