import * as React from "react";
import { useDeepCompareCallback } from "use-deep-compare";
import { t } from "../i18n/util";
import { API } from "../network/API";
import { GetAccountTypeDomainsResponse } from "../network/APITypes";
import { generalStore } from "../stores/GeneralStore";
import { DomainPaginationOptions } from "../types";

export const useDomains = (options?: DomainPaginationOptions) => {
    const [response, setResponse] = React.useState<GetAccountTypeDomainsResponse | null>(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isInitialized, setIsInitialized] = React.useState(false);

    const load = useDeepCompareCallback(async () => {
        generalStore.setIsLoading(true);
        setIsLoading(true);

        try {
            const res = await API.getDomains(options);
            setResponse(res);
            setIsInitialized(true);
        } catch (error) {
            generalStore.setError(t("error.loadDomains"), error);
        } finally {
            generalStore.setIsLoading(false);
            setIsLoading(false);
        }
    }, [options]);

    React.useEffect(() => {
        load();
    }, [load]);

    return { domains: response, reloadDomains: load, isLoading, isInitialized };
};
