import { useParams } from "react-router";
import { useActivateRoleDialog } from "../../../hooks/useActivateRoleDialog";
import { useDeactivateRoleDialog } from "../../../hooks/useDeactivateRoleDialog";
import { useDeleteRoleDialog } from "../../../hooks/useDeleteRoleDialog";
import { useManagePermissionsFlow } from "../../../hooks/useManagePermissionsFlow";
import { useRole } from "../../../hooks/useRole";
import { useRoleDetailsForm } from "../../../hooks/useRoleDetailsForm";
import { t } from "../../../i18n/util";
import { generalStore } from "../../../stores/GeneralStore";
import { pushRoute } from "../../app/router/history";
import { CardAction } from "../../ui/CardAction";
import { CardHeader } from "../../ui/CardHeader";
import { DetailsHeader } from "../../ui/DetailsHeader";
import { GrantedPermissionServicesList } from "../../ui/GrantedPermissionServicesList";
import { InfoBlock } from "../../ui/InformationRow";
import { Card, CardColumn, CardContainer } from "../../ui/Primitives";
import { roleStatusToProps } from "../../ui/RoleStatus";
import { Status } from "../../ui/Status";
import { AccountTypes } from "../../util/AccountTypes";
import { Colors } from "../../util/Colors";
import { SettingsRoutes } from "../router/SettingsRoutes";
import { EmptyState } from "../../ui/EmptyState";
import { useRoleDetailsFlow } from "../../../hooks/useRoleDetailsFlow";
import { CARD_WIDTH } from "../../../config";

export const SettingsRoleDetailsSite = () => {
    const { roleId } = useParams<{ roleId: string }>();

    const { role, reload } = useRole({ id: roleId, options: { orderDir: "asc", orderBy: "serviceGroup" } });

    const roleDetails = useRoleDetailsFlow({
        reloadRoles: reload,
    });

    const managePermissionsFlow = useManagePermissionsFlow({ role, reloadRoles: reload });

    const handleBack = () => {
        pushRoute(generalStore.lastLocation ?? SettingsRoutes.ROLES);
    };

    const deleteDialog = useDeleteRoleDialog(role?.id, handleBack);
    const deactivateDialog = useDeactivateRoleDialog({ role, reload: reload });
    const activateDialog = useActivateRoleDialog(role?.id, reload);

    if (!role) {
        return null;
    }

    const canDelete = role.status === "active" || (role.status === "deactivated" && role.userCount === 0);
    const hasPermissions = role.permissionServices && role.permissionServices.filter((p) => p.isGranted).length > 0;

    const handleDelete = () => {
        if (role.userCount) {
            deactivateDialog.open({ isDelete: true });
        } else {
            deleteDialog.open();
        }
    };

    return (
        <div style={{ display: "flex", justifyContent: "center", marginTop: 32 }}>
            <div>
                <DetailsHeader title={role?.name ?? ""} onBack={handleBack} />

                <CardContainer>
                    <CardColumn>
                        <Card style={{ width: CARD_WIDTH }}>
                            <CardHeader
                                title={t("roleDetails.roleInformation")}
                                onEdit={() => roleDetails.open(role)}
                                editDisabled={role.status !== "active"}
                            />
                            <InfoBlock
                                rows={[
                                    { label: t("common.name"), value: role.name },
                                    { label: t("common.description"), value: role.description },
                                    { label: t("common.accountType"), value: t(AccountTypes[role.accountType]) },
                                    { label: t("common.roleGroup"), value: role.roleGroup?.name ?? "-" },
                                ]}
                            />
                            {roleDetails.component}
                        </Card>
                        <Card>
                            <CardHeader title={t("roleDetails.moreInformation")} />
                            <InfoBlock
                                rows={[
                                    {
                                        label: t("common.status"),
                                        value: <Status {...roleStatusToProps(role.status)} />,
                                    },
                                    { label: t("roleDetails.userCountForRole"), value: role.userCount ?? "0" },
                                ]}
                            />
                            {role.status === "active" && (
                                <CardAction title={t("button.deactivate")} onClick={() => deactivateDialog.open()}>
                                    {deactivateDialog.component}
                                </CardAction>
                            )}
                            {role.status === "deactivated" && (
                                <CardAction title={t("button.activate")} onClick={activateDialog.open}>
                                    {activateDialog.component}
                                </CardAction>
                            )}
                            {canDelete && (
                                <CardAction title={t("button.delete")} color={Colors.RED} onClick={handleDelete}>
                                    {deleteDialog.component}
                                </CardAction>
                            )}
                        </Card>
                    </CardColumn>
                    <Card>
                        <CardHeader
                            title={t("common.permissions")}
                            onEdit={managePermissionsFlow.open}
                            editDisabled={
                                !(role.status === "active" || (role.status === "system" && role.permissionsEditable))
                            }
                        />
                        {hasPermissions ? (
                            <GrantedPermissionServicesList permissionServices={role.permissionServices} />
                        ) : (
                            <EmptyState iconName="sadFace" title={t("roleDetails.noPermissions")} />
                        )}
                        {managePermissionsFlow.component}
                    </Card>
                </CardContainer>
            </div>
        </div>
    );
};
