import { Button, ButtonProps } from "@mui/material";
import { useRef } from "react";

export const SingleClickButton = ({
    delayMs = 500,
    onClick,
    children,
    ...rest
}: ButtonProps & {
    delayMs?: number;
}) => {
    const lastClick = useRef(0);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const now = Date.now();
        if (now - lastClick.current < delayMs) {
            return;
        }

        lastClick.current = now;
        onClick?.(e);
    };

    return (
        <Button onClick={handleClick} {...rest}>
            {children}
        </Button>
    );
};
