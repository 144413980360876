import * as React from "react";
import { CustomDrawer } from "../components/ui/CustomDrawer";
import { EditUserForm } from "../components/forms/editUser/EditUserForm";
import { UserPreferences, UserRequest } from "../network/APITypes";

export const useEditUserForm = ({
    user,
    reloadUser,
    userPreference,
}: {
    user: UserRequest | null;
    reloadUser: () => Promise<void>;
    userPreference: UserPreferences | null;
}) => {
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const component = (
        <CustomDrawer open={open} onClose={handleClose}>
            <EditUserForm onClose={handleClose} reloadUser={reloadUser} user={user} userPreference={userPreference} />
        </CustomDrawer>
    );

    return {
        open() {
            setOpen(true);
        },

        component,
    };
};
