import * as React from "react";
import { useDeepCompareCallback } from "use-deep-compare";
import { t } from "../i18n/util";
import { API } from "../network/API";
import { GetUserRequestPermissionsResponse } from "../network/APITypes";
import { generalStore } from "../stores/GeneralStore";
import { RoleDetailsOptions } from "../types";
import { cloneDeep } from "lodash";

export const useUserPermissions = ({
    id,
    options,
    loadCondition = true,
}: { id?: string; options?: RoleDetailsOptions; loadCondition?: boolean } = {}) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [isInitialized, setIsInitialized] = React.useState(false);
    const [lastId, setLastId] = React.useState<string>();

    // Contains the current user permissions with filters, sorting, search applied
    const [permissions, setPermissions] = React.useState<GetUserRequestPermissionsResponse | null>(null);

    // Raw permissions without any filters, sorting, search applied
    // (Can't use useUserDetails() because the permissionServices returned do not contain the grantedBy array, so
    // we don't know if the permission was granted by a role or explicitly)
    const [permissionsNoFilters, setPermissionsNoFilters] = React.useState<GetUserRequestPermissionsResponse | null>(
        null,
    );

    const load = useDeepCompareCallback(
        async ({ reloadAll = false }: { reloadAll?: boolean } = {}) => {
            if (!id) {
                return;
            }

            generalStore.setIsLoading(true);
            setIsLoading(true);
            try {
                const res = await API.getUserPermissions(id, options);
                setPermissions(res);

                if (!permissionsNoFilters || id !== lastId || reloadAll) {
                    if (
                        !options ||
                        (!options.offset &&
                            !options.search &&
                            (!options.serviceGroups || options.serviceGroups.length === 0) &&
                            options.limit === 0)
                    ) {
                        setPermissionsNoFilters(res);
                    } else {
                        const res = await API.getUserPermissions(id, { offset: 0, limit: 0 });
                        setPermissionsNoFilters(res);
                    }

                    setLastId(id);
                }

                setIsInitialized(true);
            } catch (error) {
                generalStore.setError(t("error.loadPermissionServices"), error);
                setPermissions(null);
            } finally {
                generalStore.setIsLoading(false);
                setIsLoading(false);
            }
        },
        [id, options],
    );

    const grantPermission = React.useCallback(
        (identifier: string, granted: boolean) => {
            if (!permissionsNoFilters) {
                return;
            }

            const permission = permissionsNoFilters.permissionServices?.find((p) => p.identifier === identifier);
            if (!permission) {
                return;
            }

            permission.isGranted = granted;
            setPermissionsNoFilters(cloneDeep(permissionsNoFilters));
        },
        [permissionsNoFilters],
    );

    const isPermissionGranted = React.useCallback(
        (identifier: string) => {
            if (!permissionsNoFilters) {
                return false;
            }

            const permission = permissionsNoFilters.permissionServices?.find((p) => p.identifier === identifier);
            if (!permission) {
                return false;
            }

            return permission.isGranted;
        },
        [permissionsNoFilters],
    );

    React.useEffect(() => {
        if (loadCondition) {
            load();
        }
    }, [load, loadCondition]);

    const reset = React.useCallback(() => {
        setLastId(undefined);
        setPermissions(null);
        setIsInitialized(false);
        load({ reloadAll: true });
    }, [load]);

    const granted = permissionsNoFilters?.permissionServices?.filter((p) => p.isGranted) ?? [];

    // When we grant a permission in the UI using grantPermission() only isGranted is set, but not isExplicitelyGranted
    // which comes only from BE nor do we modify grantedBy. So nonRoleGranted contains all permissions that
    // have been granted explicitly or are currently being granted in the UI (but have not been updated via API yet)
    const nonRoleGranted = granted.filter((p) => !p.grantedBy || p.grantedBy.length === 0 || p.isExplicitlyGranted);

    return {
        permissions: permissions?.permissionServices,
        reload: async () => {
            await load({ reloadAll: true });
        },
        isLoading,
        isInitialized,
        servicesEmpty: permissions && (!permissions.permissionServices || permissions.permissionServices.length === 0),
        grantPermission,
        isPermissionGranted,
        grantedPermissions: granted.map((p) => p.identifier),
        grantedNonRolePermissions: nonRoleGranted.map((p) => p.identifier),
        reset,
    };
};
