import * as React from "react";
import { useDeepCompareCallback } from "use-deep-compare";
import { t } from "../i18n/util";
import { API } from "../network/API";
import { GetRoleGroupsResponse } from "../network/APITypes";
import { generalStore } from "../stores/GeneralStore";
import { RoleGroupsOptions } from "../types";

export const useRoleGroups = (options?: RoleGroupsOptions) => {
    const [response, setResponse] = React.useState<GetRoleGroupsResponse | null>(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isInitialized, setIsInitialized] = React.useState(false);

    const load = useDeepCompareCallback(async () => {
        generalStore.setIsLoading(true);
        setIsLoading(true);

        try {
            const res = await API.getRoleGroups(options);
            setResponse(res);
            setIsInitialized(true);
        } catch (error) {
            generalStore.setError(t("error.loadRoles"), error);
        } finally {
            generalStore.setIsLoading(false);
            setIsLoading(false);
        }
    }, [options]);

    React.useEffect(() => {
        load();
    }, [load]);

    const roleGroups = response;

    return {
        roleGroups,
        reloadRoleGroups: load,
        isLoading,
        isInitialized,
        isEmpty: roleGroups && roleGroups.total === 0,
        roleGroupOptions: (
            roleGroups?.roleGroups?.map((roleGroup) => ({
                label: roleGroup.name,
                value: roleGroup.id,
            })) ?? []
        ).concat({ label: t("filter.roleGroups.none"), value: "none" }),
    };
};
