import { Mutex } from "async-mutex";
import localforage from "localforage";
import { makeAutoObservable, runInAction } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { t } from "../i18n/util";
import { API } from "../network/API";
import { STATUS_CODE_UNAUTHORIZED } from "../network/APIClient";
import { GetUserInfoResponse } from "../network/APITypes";
import { APIClientStatusCodeError } from "../network/NetworkStapler";
import { generalStore } from "./GeneralStore";

export interface ICredentials {
    access_token: string;
    refresh_token: string;
    expires_in: number;
    token_type: string;
}

export interface IProfile {
    uid: string;
    scope: string[];
    email: string;
}

export type AuthError = "PasswordWrong" | "Unknown";

const mutex = new Mutex();

class Auth {
    credentials: ICredentials | null = null;
    userProfile: GetUserInfoResponse | null = null;
    username = "";
    error: AuthError | null = null;
    isAuthenticated = false;
    isLoading = false;
    globalLegalUpdatedAt: string | null = null;
    isRehydrated = false;
    tokenTime: number | null = null;
    forgotPasswordEmail?: string;

    constructor() {
        makeAutoObservable(this);
        this.initPersistence();
    }

    initPersistence = async () => {
        try {
            await makePersistable(this, {
                name: "auth",
                properties: ["credentials", "userProfile", "username"],
                storage: localforage,
            });

            if (this.credentials !== null) {
                console.log("hydrate.auth: credentials are available, awaiting new token...");

                try {
                    await this.tokenExchange();
                    console.log("hydrate.auth: received new token!");
                } catch (error) {
                    console.log("hydrate.auth: failed to receive new token!");
                }
            } else {
                console.log("rehydrated, no credentials are available.");
            }
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.isRehydrated = true;
            });
        }
    };

    loginWithPassword = async (username: string, password: string) => {
        if (this.isLoading) {
            // bailout, noop
            return;
        }

        this.isLoading = true;

        try {
            const credentials = await API.loginWithPassword({
                username: username,
                password: password,
            });

            runInAction(() => {
                this.error = null;
                this.username = username;
                this.isLoading = false;
                this.credentials = credentials;

                // This has to be last! Because setting isAuthenticated to true triggers the <PublicRoute> component
                // to start redirecting in which case the credentials must be valid.
                this.isAuthenticated = true;
            });
        } catch (error) {
            runInAction(() => {
                this.isLoading = false;
            });

            if (error instanceof APIClientStatusCodeError) {
                if (error.statusCode === STATUS_CODE_UNAUTHORIZED) {
                    this.wipe("PasswordWrong");
                } else {
                    this.wipe("Unknown");
                }
            }
        }
    };

    getUserProfile = async () => {
        try {
            const userProfile = await API.getUserInfo();
            runInAction(() => {
                this.userProfile = userProfile;
            });
        } catch (error) {
            generalStore.setError(t("error.userProfile"), error);
        }
    };

    logout = async () => {
        try {
            if (this.credentials?.refresh_token) {
                await API.logout(this.credentials?.refresh_token);
            }
        } catch (error) {
            console.log(error);
            generalStore.setError(t("error.logout"), error);
        } finally {
            this.wipe(null);
        }
    };

    handleTokenExchange = async () => {
        return await mutex.runExclusive(async () => {
            if (!this.tokenTime || !this.credentials) {
                return this.tokenExchange();
            }

            const date = new Date();
            // expires_in is in seconds, so it needs to converted to ms
            const isTokenExpired = this.tokenTime + this.credentials.expires_in * 1000 < date.getTime();

            if (isTokenExpired) {
                return this.tokenExchange();
            }

            return true;
        });
    };

    handleUnauthorized = async () => {
        let tokenExchangeSuccess = false;
        if (this.credentials) {
            try {
                tokenExchangeSuccess = await this.handleTokenExchange();
            } catch {
                this.wipe(null);
            }
        } else {
            this.wipe(null);
        }

        return tokenExchangeSuccess;
    };

    tokenExchange = async () => {
        this.isLoading = true;
        let success = false;
        try {
            if (this.credentials === null) {
                throw new Error(`No valid credentials are available`);
            }

            const credentials = await API.tokenRefresh(this.credentials.refresh_token);
            const date = new Date();

            runInAction(() => {
                this.credentials = credentials;
                this.tokenTime = date.getTime();
                this.error = null;
                this.isAuthenticated = true;
                this.isLoading = false;
            });

            success = true;
        } catch (error) {
            generalStore.setError(t("error.tokenRefresh"), error);
            this.wipe("Unknown");
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }

        return success;
    };

    private wipe(error: AuthError | null) {
        this.credentials = null;
        this.error = error;
        this.isAuthenticated = false;
        this.isLoading = false;
        this.userProfile = null;
        this.forgotPasswordEmail = undefined;
    }
}

let authStore: Auth;
if (process.env.NODE_ENV === "test") {
    class MockAuth {
        credentials: any = null;
        isAuthenticated = false;
        error: any = null;
        isRehydrated = true;

        constructor() {
            makeAutoObservable(this);
        }

        loginWithPassword = () => undefined;
        dismissError = () => undefined;
        logout = () => undefined;
    }

    authStore = new MockAuth() as any; // no localstorage support in node env
} else {
    authStore = new Auth();
}

// development, make auth available on window object...
(window as any).auth = authStore;

// singleton, exposes an instance by default
export { authStore };
