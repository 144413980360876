import * as queryString from "query-string";
import { useLocation } from "react-router";
import { compact } from "lodash";

export const useQuery = (options?: { arrayKeys?: string[] }) => {
    const location = useLocation();
    const result = queryString.parse(location.search, {
        parseBooleans: true,
        arrayFormat: "bracket",
    }) as any;
    if (options?.arrayKeys) {
        options.arrayKeys.forEach((key) => {
            if (!Array.isArray(result[key])) {
                // Convert single elements to array
                result[key] = [result[key]];
            }
            result[key] = compact(result[key]);
        });
    }

    return result;
};
