import { FormControlLabel, Radio, RadioGroup, styled } from "@mui/material";
import { getIn, useFormikContext } from "formik";
import * as React from "react";
import { corporatePositionCategoryNames, corporatePositionNames } from "../../../constants";
import { t } from "../../../i18n/util";
import { Department } from "../../../network/APITypes";
import { SelectionFormContainer } from "../../ui/SelectionFormContainer";
import { Colors } from "../../util/Colors";
import { Icon } from "../../util/Icon";

const Category = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: 56,
    flexShrink: 0,
    cursor: "pointer",
});

type CorporatePositionSelectionProps = {
    onClose: () => void;
    positions: Department[];
    name: string;
};

export const CorporatePositionSelection = ({ onClose, positions, name }: CorporatePositionSelectionProps) => {
    const { setFieldValue, values, setFieldTouched } = useFormikContext<any>();

    const initialValue = getIn(values, name);
    const initialCategory = positions.find((category) => category.roles.find((role) => role.id === initialValue))?.name;

    const [selectedCategory, setSelectedCategory] = React.useState<string | undefined>(initialCategory);
    const [selectedPosition, setSelectedPosition] = React.useState<string>(initialValue ?? "");

    const handleChangePosition = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedPosition(event.target.value);
    };

    const handleClickCategory = (category: string) => {
        if (selectedCategory === category) {
            setSelectedCategory(undefined);
        } else {
            setSelectedCategory(category);
        }
    };

    const handleSubmit = () => {
        setFieldValue(name, selectedPosition);
        setFieldTouched(name, false);
        onClose();
    };

    return (
        <SelectionFormContainer
            title={t("screen.userDetails.edit.form.corporatePosition.title")}
            onSubmit={handleSubmit}
            onClose={onClose}
        >
            {positions.map((category) => (
                <React.Fragment key={category.id}>
                    <Category onClick={() => handleClickCategory(category.name)}>
                        <p style={{ fontWeight: 600, color: Colors.DARK }}>{category.name}</p>
                        <Icon name={selectedCategory === category.name ? "chevronUp" : "chevronDown"} />
                    </Category>
                    {selectedCategory === category.name && (
                        <div style={{ paddingLeft: 24 }}>
                            <RadioGroup name="position" value={selectedPosition} onChange={handleChangePosition}>
                                {category.roles.map((role) => (
                                    <FormControlLabel
                                        key={role.id}
                                        control={<Radio />}
                                        value={role.id}
                                        label={role.name}
                                        componentsProps={{
                                            typography: {
                                                style: { fontSize: 14, fontWeight: 400, color: Colors.DARK },
                                            },
                                        }}
                                        style={{ height: 40, gap: 8 }}
                                    />
                                ))}
                            </RadioGroup>
                        </div>
                    )}
                </React.Fragment>
            ))}
        </SelectionFormContainer>
    );
};
