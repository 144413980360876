import { Button, RadioGroup } from "@mui/material";
import { useState } from "react";
import { BorderRadio } from "../components/ui/BorderRadio";
import { CustomDrawer } from "../components/ui/CustomDrawer";
import { DialogHeader } from "../components/ui/DialogHeader";
import { t } from "../i18n/util";
import { Stepper } from "../types";
import { DialogStepper } from "../components/ui/DialogStepper";

type PermissionsType = "config" | "copy";

export const useChooseRolePermissionsType = ({
    onClose,
    onSubmit,
    onBack,
    stepper,
    title,
}: {
    onBack?: () => void;
    onClose?: () => void;
    onSubmit: (permissionsType: PermissionsType) => void;
    stepper: Stepper;
    title: string;
}) => {
    const [open, setOpen] = useState(false);
    const [permissionsType, setPermissionsType] = useState<PermissionsType>("config");

    const close = () => {
        setOpen(false);
        setPermissionsType("config");
    };

    const handleClose = () => {
        close();
        onClose?.();
    };

    const component = (
        <CustomDrawer open={open} onClose={handleClose}>
            <div
                style={{
                    width: "100%",
                    height: "inherit",
                    overflow: "auto",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
            >
                <div>
                    <DialogHeader title={title} onBack={onBack} onClose={handleClose} />
                    <DialogStepper stepper={stepper} />
                    <div style={{ marginTop: 40 }}>
                        <RadioGroup
                            value={permissionsType}
                            onChange={(event) => {
                                setPermissionsType(event.target.value as PermissionsType);
                            }}
                        >
                            <BorderRadio
                                value={"config"}
                                label={t("addRole.radio.configPermissions")}
                                selectedValue={permissionsType}
                            />
                            <BorderRadio
                                value={"copy"}
                                label={t("addRole.radio.copyPermissions")}
                                selectedValue={permissionsType}
                                style={{ marginTop: 12 }}
                            />
                        </RadioGroup>
                    </div>
                </div>
                <Button
                    variant="contained"
                    style={{ marginTop: 32 }}
                    onClick={() => {
                        onSubmit(permissionsType);
                        close();
                    }}
                >
                    {t("button.next")}
                </Button>
            </div>
        </CustomDrawer>
    );

    return {
        open() {
            setOpen(true);
        },
        component,
    };
};
