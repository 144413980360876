import * as React from "react";
import { Colors, UserStatusColors } from "../util/Colors";
import { MessageIDS, t } from "../../i18n/util";
import { CustomerUserRequestStatusEnum } from "../../network/APITypes";

type IProps = {
    status: CustomerUserRequestStatusEnum;
};

export const UserStatusMessages: { [key in CustomerUserRequestStatusEnum]: MessageIDS } = {
    created: "user.status.created",
    invited: "user.status.invited",
    active: "user.status.active",
    deactivated: "user.status.deactivated",
    waiting: "user.status.waiting",
    denied: "user.status.denied",
    deleted: "user.status.deleted",
};

export type StatusProps = {
    color: string;
    label: string;
};

export const Status = ({ color, label }: StatusProps) => (
    <div
        style={{
            display: "flex",
            justifyContent: "center",
            width: 70,
            height: 24,
            padding: 4,
            backgroundColor: color,
            color: Colors.LIGHT,
            borderRadius: 4,
        }}
        className="caption"
    >
        {label}
    </div>
);

export const UserStatus = ({ status }: IProps) => (
    <Status color={UserStatusColors[status]} label={t(UserStatusMessages[status])} />
);
