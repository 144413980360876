import { FormControlLabel, Radio, RadioGroup, RadioProps } from "@mui/material";
import { FieldInputProps, FormikState, getIn } from "formik";
import * as React from "react";
import { FieldError } from "./FieldError";

type Option = {
    value: string | number;
    label: React.ReactNode;
};

type CustomRadioGroupProps = RadioProps & {
    options: Option[];
    field: FieldInputProps<string>;
    form: FormikState<any>;
    style?: React.CSSProperties;
    showValidationError?: boolean;
    focusFirstInput?: boolean;
};

export const CustomRadioGroup = ({
    options,
    field,
    form,
    style,
    showValidationError = true,
    focusFirstInput,
}: CustomRadioGroupProps) => {
    const fieldError = getIn(form.errors, field.name);
    const showError = getIn(form.touched, field.name) && !!fieldError;

    return (
        <div style={style}>
            <RadioGroup name={field.name} value={field.value} onChange={field.onChange} onBlur={field.onBlur}>
                {options.map((option, index) => (
                    <FormControlLabel
                        key={option.value}
                        value={option.value}
                        name={field.name}
                        control={<Radio autoFocus={focusFirstInput && index === 0} />}
                        label={
                            <div className="body2" style={{ width: "100%" }}>
                                {option.label}
                            </div>
                        }
                        // marginLeft: 0 fixes cut off hover effect in selection forms
                        style={{ height: 56, marginLeft: 0 }}
                        disableTypography
                    />
                ))}
            </RadioGroup>
            {showValidationError && <FieldError>{showError ? fieldError : ""}</FieldError>}
        </div>
    );
};
