import { useState } from "react";

export function useStepFlow(numSteps: number, previousMapping?: Record<number, number>) {
    const [step, setStep] = useState(0);

    return {
        step,
        setStep,
        reset: () => setStep(0),
        next: () =>
            setStep((step) => {
                return Math.min(step + 1, numSteps - 1);
            }),
        back: () =>
            setStep((step) => {
                if (previousMapping && step in previousMapping) {
                    return previousMapping[step];
                }
                return Math.max(step - 1, 0);
            }),
    };
}
