import * as React from "react";
import { useDeepCompareCallback } from "use-deep-compare";
import { t } from "../i18n/util";
import { API } from "../network/API";
import { GetRoleDetailsResponse } from "../network/APITypes";
import { generalStore } from "../stores/GeneralStore";
import { RoleDetailsOptions } from "../types";

export const useRole = ({
    id,
    options,
    loadCondition = true,
}: { id?: string; options?: RoleDetailsOptions; loadCondition?: boolean } = {}) => {
    // Contains the current role permissions with filters, sorting, search applied
    const [role, setRole] = React.useState<GetRoleDetailsResponse | null>(null);

    // Raw role without any filters, sorting, search applied
    const [roleNoFilters, setRoleNoFilters] = React.useState<GetRoleDetailsResponse | null>(null);

    const [isLoading, setIsLoading] = React.useState(false);
    const [isInitialized, setIsInitialized] = React.useState(false);

    const load = useDeepCompareCallback(async () => {
        if (!id) {
            return;
        }

        generalStore.setIsLoading(true);
        setIsLoading(true);
        try {
            const res = await API.getRole(id, options);
            setRole(res);

            if (!roleNoFilters || roleNoFilters.id !== id) {
                if (
                    !options ||
                    (!options.offset &&
                        !options.search &&
                        (!options.serviceGroups || options.serviceGroups.length === 0) &&
                        options.limit === 0)
                ) {
                    setRoleNoFilters(res);
                } else {
                    const res = await API.getRole(id, { offset: 0, limit: 0 });
                    setRoleNoFilters(res);
                }
            }

            setIsInitialized(true);
        } catch (error) {
            generalStore.setError(t("error.loadRole"), error);
            setRole(null);
            setRoleNoFilters(null);
        } finally {
            generalStore.setIsLoading(false);
            setIsLoading(false);
        }
    }, [id, options, roleNoFilters]);

    React.useEffect(() => {
        if (loadCondition) {
            load();
        }
    }, [load, loadCondition]);

    const reset = React.useCallback(() => {
        setRole(null);
        setRoleNoFilters(null);
        setIsInitialized(false);
    }, []);

    return {
        role,
        permissions: role?.permissionServices,
        reload: load,
        isLoading,
        isInitialized,
        servicesEmpty: role && (!role.permissionServices || role.permissionServices.length === 0),
        grantPermission: (identifier: string, granted: boolean) => {
            if (!roleNoFilters) {
                return;
            }

            const permission = roleNoFilters.permissionServices?.find((p) => p.identifier === identifier);
            if (!permission) {
                return;
            }

            permission.isGranted = granted;
            setRoleNoFilters({ ...roleNoFilters });
        },
        isPermissionGranted: (identifier: string) => {
            if (!roleNoFilters) {
                return false;
            }

            const permission = roleNoFilters.permissionServices?.find((p) => p.identifier === identifier);
            if (!permission) {
                return false;
            }

            return permission.isGranted;
        },
        grantedPermissions: (roleNoFilters?.permissionServices?.filter((p) => p.isGranted) ?? []).map(
            (p) => p.identifier,
        ),
        reset,
    };
};
