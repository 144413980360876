import { Route } from "react-router-dom";
import { CustomSwitch } from "../../app/router/CustomSwitch";
import { ExpoTicketsSite } from "../sites/ExpoTicketsSite";
import { ExpoTicketsRoutes } from "./ExpoTicketsRoutes";

export const ExpoTicketsRouter = () => (
    <CustomSwitch>
        <Route exact path={ExpoTicketsRoutes.ROOT}>
            <ExpoTicketsSite />
        </Route>
    </CustomSwitch>
);
