import { IconButton } from "@mui/material";
import { CmsTableCellWithCheckbox } from "./Primitives";
import { Icon } from "../util/Icon";
import { Colors } from "../util/Colors";

export const TableContextButton = ({ onClick }: { onClick: React.MouseEventHandler<HTMLElement> }) => {
    return (
        <CmsTableCellWithCheckbox padding="checkbox">
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    gap: 24,
                    paddingLeft: 8,
                }}
            >
                <IconButton onClick={onClick}>
                    <Icon name="more" color={Colors.GREY_700} />
                </IconButton>
            </div>
        </CmsTableCellWithCheckbox>
    );
};
