import React from "react";
import { FilterContainer } from "./FilterContainer";
import { Icon } from "../../util/Icon";
import dayjs from "dayjs";
import { DateRangeFilterPopover } from "./DateRangeFilterPopover";
export const DateRangeFilterButton = ({
    label,
    onApply,
    dateFrom,
    dateTo,
}: {
    label: string;
    onApply: (dateFrom: dayjs.Dayjs | null, dateTo: dayjs.Dayjs | null) => void;
    dateFrom: dayjs.Dayjs | null;
    dateTo: dayjs.Dayjs | null;
}) => {
    const [menuAnchorElement, setMenuAnchorElement] = React.useState<null | HTMLElement>(null);

    const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorElement(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setMenuAnchorElement(null);
    };

    return (
        <>
            <FilterContainer isSelected={dateFrom || dateTo} onClick={handleClickMenu}>
                <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                    <span>{label}</span>
                    <div>
                        <Icon name="chevronDown" />
                    </div>
                </div>
            </FilterContainer>
            {!!menuAnchorElement && (
                <DateRangeFilterPopover
                    anchorElement={menuAnchorElement}
                    onClose={handleCloseMenu}
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                    onApply={onApply}
                />
            )}
        </>
    );
};
