import * as React from "react";
import { FileUpload } from "../components/ui/FileUpload";

export const useFileUpload = () => {
    const [files, setFiles] = React.useState<File[]>([]);

    const reset = () => {
        setFiles([]);
    };

    const component = ({ onChange, ...props }: Omit<React.ComponentProps<typeof FileUpload>, "values">) => {
        const handleChangeFiles = (files: File[]) => {
            setFiles(files);
            onChange(files);
        };

        return <FileUpload {...props} values={files} onChange={handleChangeFiles} />;
    };

    return { files, reset, component };
};
