import { Button, Divider, Table, TableBody, TableRow } from "@mui/material";
import { observer } from "mobx-react";
import { useState } from "react";
import { useLocation } from "react-router";
import { SEARCH_FIELD_MAX_WIDTH } from "../../../config";
import { useAddRoleGroupFlow } from "../../../hooks/useAddRoleGroupFlow";
import { useContextMenu } from "../../../hooks/useContextMenu";
import { useDeleteRoleGroupDialog } from "../../../hooks/useDeleteRoleGroupDialog";
import { useManageRolesDialog } from "../../../hooks/useManageRolesDialog";
import { usePagination } from "../../../hooks/usePagination";
import { useRoleGroupDetailsForm } from "../../../hooks/useRoleGroupDetailsForm";
import { useRoleGroups } from "../../../hooks/useRoleGroups";
import { t } from "../../../i18n/util";
import { RoleGroup } from "../../../network/APITypes";
import { generalStore } from "../../../stores/GeneralStore";
import { pushRoute, withParams } from "../../app/router/history";
import { EmptyState } from "../../ui/EmptyState";
import { CmsTableCell, CmsTableContainer, SettingSubSiteContainer, TableLabel } from "../../ui/Primitives";
import { SearchField } from "../../ui/SearchField";
import { TableContextButton } from "../../ui/TableContextButton";
import { TableHeader, TableHeaderConfig } from "../../ui/TableHeader";
import { SettingsRoutes } from "../router/SettingsRoutes";

export const SettingsRoleGroupsSite = observer(() => {
    const pagination = usePagination();
    const [contextRoleGroup, setContextRoleGroup] = useState<RoleGroup>();
    const location = useLocation();

    const { roleGroups, reloadRoleGroups, isLoading, isInitialized, isEmpty } = useRoleGroups({
        offset: pagination.offset,
        limit: pagination.pageSize,
        orderBy: pagination.orderBy,
        orderDir: pagination.orderDir,
        search: pagination.search,
    });

    const deleteDialog = useDeleteRoleGroupDialog(contextRoleGroup?.id, reloadRoleGroups);

    const headerFields: TableHeaderConfig[] = [
        { column: "name", label: "screen.settings.roleGroups.table.group", style: { width: "20%" } },
        { column: "description", label: "screen.settings.roleGroups.table.description" },
        { column: "roleCount", label: "screen.settings.roleGroups.table.rolesCount", style: { width: 0 } },
        { column: "contextMenu", style: { width: 0 } },
    ];

    const contextMenu = useContextMenu<RoleGroup>((roleGroup) => {
        if (roleGroup) {
            return [
                {
                    title: t("manageRoleGroupForm.edit.title"),
                    onClick: () => {
                        setContextRoleGroup(roleGroup);
                        roleGroupDetailsForm.open();
                    },
                },
                {
                    title: t("manageRoles.title"),
                    onClick: () => {
                        setContextRoleGroup(roleGroup);
                        manageRolesDialog.open();
                    },
                },
                {
                    title: t("button.delete"),
                    onClick: () => {
                        setContextRoleGroup(roleGroup);
                        deleteDialog.open();
                    },
                },
            ];
        }

        return [];
    });

    const addRoleGroupFlow = useAddRoleGroupFlow({ reloadRoleGroups });

    const roleGroupDetailsForm = useRoleGroupDetailsForm({
        onSubmit() {
            reloadRoleGroups();
        },
        roleGroup: contextRoleGroup,
    });

    const manageRolesDialog = useManageRolesDialog({
        title: t("manageRoles.title"),
        roleGroupId: contextRoleGroup?.id,
        onSubmit() {
            reloadRoleGroups();
        },
    });

    const openDetailsSite = (roleGroupId: string) => {
        generalStore.lastLocation = `${location.pathname}${location.search}`;
        pushRoute(withParams(SettingsRoutes.ROLE_GROUP_DETAILS, { roleGroupId }));
    };

    return (
        <>
            <SettingSubSiteContainer>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: 8,
                        width: "100%",
                    }}
                >
                    <div style={{ maxWidth: SEARCH_FIELD_MAX_WIDTH }}>
                        <SearchField
                            placeholder={t("screen.settings.roleGroups.search.placeholder")}
                            onChange={pagination.onChangeSearch}
                            value={pagination.search}
                            style={{ width: "100%" }}
                        />
                    </div>

                    <Button
                        onClick={() => {
                            addRoleGroupFlow.open();
                        }}
                        variant="contained"
                        style={{ flexShrink: 0 }}
                    >
                        {t("screen.settings.roleGroups.button.addGroup.text")}
                    </Button>
                </div>

                <Divider style={{ marginTop: 16, marginBottom: 16 }} />

                {pagination.component(roleGroups?.total ?? 0)}

                {isInitialized && !isLoading && !isEmpty && (
                    <CmsTableContainer>
                        <Table>
                            <TableHeader
                                headerFields={headerFields}
                                orderBy={pagination.orderBy}
                                orderDir={pagination.orderDir}
                                onChangeOrder={pagination.onChangeOrder}
                                allowSort
                            />
                            <TableBody>
                                {roleGroups?.roleGroups?.map((roleGroup) => {
                                    return (
                                        <TableRow
                                            key={roleGroup.id}
                                            onClick={() => openDetailsSite(roleGroup.id)}
                                            style={{ cursor: "pointer" }}
                                        >
                                            {headerFields.map(({ column }) => {
                                                const label = roleGroup[column];

                                                if (column === "contextMenu") {
                                                    return (
                                                        <TableContextButton
                                                            key={column}
                                                            onClick={(event) => {
                                                                contextMenu.open(event, roleGroup);
                                                                event.stopPropagation();
                                                            }}
                                                        />
                                                    );
                                                }

                                                return (
                                                    <CmsTableCell
                                                        key={column}
                                                        style={{ maxWidth: column !== "name" ? 200 : undefined }}
                                                    >
                                                        <TableLabel style={{ maxWidth: "100%" }}>{label}</TableLabel>
                                                    </CmsTableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </CmsTableContainer>
                )}
                {isInitialized && !isLoading && isEmpty && (
                    <EmptyState
                        iconName="sadFace"
                        title={t("screen.settings.roleGroups.emptyScreen.title")}
                        description={t("screen.settings.roleGroups.emptyScreen.description", {
                            buttonText: t("screen.settings.roleGroups.button.addGroup.text"),
                        })}
                    />
                )}
            </SettingSubSiteContainer>

            {contextMenu.component}
            {addRoleGroupFlow.component}
            {deleteDialog.component}
            {roleGroupDetailsForm.component}
            {manageRolesDialog.component}
        </>
    );
});
