import * as React from "react";
import Drawer from "@mui/material/Drawer";

export const CustomDrawer = ({
    open,
    children,
    onClose,
    hideBackdrop,
    style,
}: {
    open?: boolean;
    children: React.ReactNode;
    onClose: () => void;
    hideBackdrop?: boolean;
    style?: React.CSSProperties;
}) => {
    return (
        <Drawer
            anchor="left"
            open={open}
            onClose={onClose}
            ModalProps={{
                onClose: (_, reason) => {
                    if (reason === "backdropClick" || reason === "escapeKeyDown") {
                        return;
                    }
                },
            }}
            hideBackdrop={hideBackdrop}
        >
            <div
                style={{
                    padding: 32,
                    height: "100%",
                    ...style,
                }}
            >
                {children}
            </div>
        </Drawer>
    );
};
