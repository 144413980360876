import { MessageIDS } from "../../i18n/util";
import { UserRequestSource } from "../../network/APITypes";

export type UserRequestSourcesType = {
    // TODO: include crm again when actually wanted
    [key in Exclude<UserRequestSource, "crm">]: MessageIDS;
};

export const UserRequestSources: UserRequestSourcesType = {
    fis: "user.source.fis",
    employee: "user.source.employee",
    fbpm: "user.source.fbpm",
    app_registered: "user.source.appRegistered",
    app_invited: "user.source.appInvited",
    partner_portal: "user.source.partner_portal",
};
