import { ReactNode } from "react";
import { Colors } from "../util/Colors";
import { styled } from "@mui/material";

const StyledContent = styled("div")({
    fontWeight: 400,
    lineHeight: "24px",
    color: Colors.HEADINGS_DARK,
    textAlign: "right",
    overflowWrap: "anywhere", // fix for FBP-1483
});

type InformationRowProps = {
    children: ReactNode;
    label: string;
};

export const InformationRow = ({ children, label }: InformationRowProps) => (
    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <p style={{ color: Colors.BODY_2, paddingRight: 16 }}>{label}</p>
        <StyledContent>{children}</StyledContent>
    </div>
);

export const InfoBlock = ({ rows }: { rows: { label: string; value: ReactNode }[] }) => (
    <>
        {rows.map((row) => (
            <InformationRow key={row.label} label={row.label}>
                {row.value}
            </InformationRow>
        ))}
    </>
);
