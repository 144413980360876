import { runInAction } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { useConfirmationDialog } from "../../../hooks/useConfirmationDialog";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { generalStore } from "../../../stores/GeneralStore";
import { Colors } from "../../util/Colors";
import { getUserDialogConfiguration } from "../../util/Dialogs";
import { getFormattedDate } from "../../util/Helpers";
import { Icon } from "../../util/Icon";
import { LinkButton } from "../LinkButton";
import { Banner } from "../Primitives";

export const OptInBanner = observer(() => {
    const optIn = generalStore.user?.customer?.optIn;
    const lastRequestedAt = generalStore.user?.customer?.optInLastRequestedAt;
    const customerId = generalStore.user?.customerID;

    const handleOpenUserManagementDialog = () => {
        userManagementDialog.open();
    };

    const handleDeactivateUserManagement = async () => {
        if (customerId) {
            try {
                generalStore.setIsLoading(true);
                const response = await API.postCustomerOptOut(customerId);
                generalStore.setSuccessMessage(t("common.success"));
                runInAction(() => {
                    generalStore.forceReloadUser = true;
                });
            } catch (error) {
                generalStore.setError(t("error.customerOptOut"), error);
            } finally {
                generalStore.setIsLoading(false);
            }
        }
    };

    const handleRequestUserManagement = async () => {
        if (customerId) {
            try {
                generalStore.setIsLoading(true);
                const response = await API.postCustomerRequestOptIn(customerId);
                generalStore.setSuccessMessage(t("common.success"));
                runInAction(() => {
                    generalStore.forceReloadUser = true;
                });
            } catch (error) {
                generalStore.setError(t("error.customerOptIn"), error);
            } finally {
                generalStore.setIsLoading(false);
            }
        }
    };

    const optInOptions = {
        ...getUserDialogConfiguration("deactivateUserManagement", { user: generalStore.user }),
        onSubmit: handleDeactivateUserManagement,
    };

    const optOutOptions = {
        ...getUserDialogConfiguration("requestUserManagement", { user: generalStore.user }),
        onSubmit: handleRequestUserManagement,
    };

    const userManagementDialog = useConfirmationDialog(optIn ? optInOptions : optOutOptions);

    return (
        <Banner>
            <div style={{ display: "flex", alignItems: "center", gap: 24 }}>
                <Icon name="warning" />
                <p className="body2">
                    {optIn && t("screen.userDetails.banner.userManagement.description.deactivate")}
                    {!optIn && (
                        <>
                            {t("screen.userDetails.banner.userManagement.description.request")}{" "}
                            {lastRequestedAt
                                ? t("common.lastRequested", {
                                      date: getFormattedDate(lastRequestedAt),
                                  })
                                : t("common.neverRequested")}
                        </>
                    )}
                </p>
            </div>
            <LinkButton style={{ color: Colors.GREY_500 }} onClick={handleOpenUserManagementDialog}>
                <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                    <p className="captionSemi" style={{ color: Colors.PRIMARY_500, fontSize: 14 }}>
                        {!optIn && t("button.requestUserManagement")}
                        {optIn && t("button.deactivateUserManagement")}
                    </p>
                    <Icon name="arrowForward" />
                </div>
            </LinkButton>
            {userManagementDialog.component}
        </Banner>
    );
});
