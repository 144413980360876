import * as React from "react";
import { DistributionChannelSelection } from "../components/forms/shared/DistributionChannelSelecton";
import { CustomDrawer } from "../components/ui/CustomDrawer";
import { useDistributionChannels } from "./useDistributionChannels";
import { DistributionChannel } from "../network/APITypes";

export interface DistributionChannelsGroup {
    distributionChannels: DistributionChannel[];
    title?: string;
    isCollapsible?: boolean;
}
export const useDistributionChannelSelection = ({
    name,
    hideBackdrop,
    distributionChannelsGroups,
}: {
    name: string;
    hideBackdrop?: boolean;
    distributionChannelsGroups: DistributionChannelsGroup[];
}) => {
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };
    const component = open ? (
        <CustomDrawer open={open} onClose={handleClose} hideBackdrop={hideBackdrop}>
            <DistributionChannelSelection
                name={name}
                onClose={handleClose}
                channelsGroups={distributionChannelsGroups}
            />
        </CustomDrawer>
    ) : null;

    const distributionChannels = distributionChannelsGroups.reduce(
        (groupA, groupB) => {
            return { distributionChannels: groupA.distributionChannels.concat(groupB.distributionChannels) };
        },
        { distributionChannels: [] },
    ).distributionChannels;
    return {
        open() {
            setOpen(true);
        },
        component,
        options:
            distributionChannels?.map((channel) => ({
                value: channel.id,
                label: `${channel.externalID} - ${channel.description}`,
            })) ?? [],
    };
};
