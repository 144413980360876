import { Button } from "@mui/material";
import { observer } from "mobx-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { t } from "../../../i18n/util";
import { authStore } from "../../../stores/AuthStore";
import { pushRoute } from "../../app/router/history";
import { Routes } from "../../app/router/Routes";
import { Colors } from "../../util/Colors";
import { Icon } from "../../util/Icon";
import { AuthContainerSite } from "./AuthContainerSite";

export const AuthForgotPasswordConfirmSite = observer(() => {
    React.useEffect(() => {
        return () => {
            authStore.forgotPasswordEmail = undefined;
        };
    }, []);

    const handleBack = () => {
        pushRoute(Routes.ROOT);
    };

    return (
        <AuthContainerSite>
            <div style={{ textAlign: "center" }}>
                <Icon name="confirm" size={64} />
                <h1 style={{ marginTop: 32 }}>{t("screen.forgotPassword.confirm.title")}</h1>
                <p style={{ marginTop: 8, color: Colors.BODY_2 }}>
                    <FormattedMessage
                        id="screen.forgotPassword.confirm.description"
                        values={{ email: <b style={{ color: Colors.DARK }}>{authStore.forgotPasswordEmail}</b> }}
                    />
                </p>
                <Button color="primary" style={{ marginTop: 16 }} onClick={handleBack}>
                    {t("screen.forgotPassword.confirm.button.text")}
                </Button>
            </div>
        </AuthContainerSite>
    );
});
