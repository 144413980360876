import { runInAction } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { useLocation } from "react-router";
import { generalStore } from "../../../stores/GeneralStore";
import { history } from "./history";

export const RoutingManager = observer(({ children }: { children: React.ReactNode }) => {
    React.useEffect(() => {
        history.listen((location, action) => {
            window.scrollTo(0, 0);

            console.log(
                `%cSwitching url to "${location.pathname}${location.search}${location.hash}" by action ${action}.`,
                "background: #eee; color: #666;",
            );
        });
    }, []);

    return <>{children}</>;
});
