import { Button, IconButton } from "@mui/material";
import { Form, Formik } from "formik";
import { cloneDeep } from "lodash";
import * as Yup from "yup";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { PutUserRequestPayloadV2, UserPreferences, UserRequest } from "../../../network/APITypes";
import { generalStore } from "../../../stores/GeneralStore";
import {
    hasUserManagement,
    isErrorOfType,
    isEmptyOrOnlyCountryDialCode,
    isValidPhoneNumber,
    normalizePhoneNumber,
} from "../../util/Helpers";
import { Icon } from "../../util/Icon";
import { UserDetailsFields } from "../shared/UserDetailsFields";
import { VALID_PHONE_NUMBER_LENGTH } from "../../../config";
import { useUserPreferences } from "../../../hooks/useUserPreferences";

export const getUserPhoneAndEmailValidationSchema = (user?: UserRequest | null) => {
    const userManagement = hasUserManagement(user);

    // Email is required if user has user management or if user already has an email
    const emailRequired = hasUserManagement(user) || !!user?.email || !!user?.appUserProfile?.email;

    // If user already has a phone number -> stays required
    const phoneRequired = (!user?.appUserProfile && !!user?.phone) || !!user?.appUserProfile?.phoneNumber;

    const emailRequiredMessage = userManagement
        ? t("validationError.userManagementNeedsEmail")
        : t("validationError.email");

    let email = Yup.string().email(t("validationError.email"));
    if (emailRequired) {
        email = email.required(emailRequiredMessage);
    } else if (!phoneRequired) {
        // xor validation for email or phone

        // Taken from https://github.com/jquense/yup/issues/79
        // and https://github.com/jquense/yup/issues/222
        email = email.test(function (value) {
            const { phone } = this.parent;
            if ((phone?.length ?? 0) < VALID_PHONE_NUMBER_LENGTH && !value) {
                return this.createError({
                    message: t("validationError.emailOrPhone"),
                    path: "email",
                });
            }
            return true;
        });
    }

    // Phone has to be valid if phone is required. If not required than it has to be
    // valid if it's not only a country code
    let phone = Yup.string().test(function (value) {
        // We only validate the phone if it is required or if it is not only a country code and not empty
        const phoneMustBeValid = phoneRequired || !isEmptyOrOnlyCountryDialCode(value);
        if (!isValidPhoneNumber(value) && phoneMustBeValid) {
            return this.createError({
                message: t("validationError.phone"),
                path: "phone",
            });
        }
        return true;
    });

    // xor validation for email or phone, if none is required -> add new user case
    if (!phoneRequired && !emailRequired) {
        phone = phone.test(function (value) {
            const { email } = this.parent;
            if (!email && !isValidPhoneNumber(value)) {
                return this.createError({
                    message: t("validationError.emailOrPhone"),
                    path: "phone",
                });
            }
            return true;
        });
    }
    return { email, phone };
};
export const userValidationSchema = (user?: UserRequest | null) => {
    const { email, phone } = getUserPhoneAndEmailValidationSchema(user);

    return Yup.object().shape({
        salutation: Yup.string().required(t("validationError.title")),
        firstName: Yup.string().required(t("validationError.firstName")),
        lastName: Yup.string().required(t("validationError.lastName")),
        accountType: Yup.string().required(t("validationError.required.accountType")),
        corporatePositionID: Yup.string().required(t("validationError.corporatePosition")),
        distributionChannelID: Yup.string().required(t("validationError.vtChannel")),
        regionID: Yup.string().required(t("validationError.region")),
        email,
        phone,
        userPreferencesId: Yup.string().optional(),
    });
};

export const EditUserForm = ({
    onClose,
    reloadUser,
    user,
    userPreference,
}: {
    onClose: () => void;
    user: UserRequest | null;
    reloadUser: () => Promise<void>;
    userPreference: UserPreferences | null;
}) => {
    const { userPreferences, userPreferencesIsLoading } = useUserPreferences({ distributorId: user?.distributorID });
    const initialValues = {
        salutation: user?.salutation ?? "",
        firstName: user?.firstName ?? "",
        lastName: user?.lastName ?? "",
        accountType: user?.accountType ?? "",
        corporatePositionID: user?.corporatePosition?.role.id ?? "",
        distributionChannelID: user?.distributionChannel?.id ?? "",
        regionID: user?.region?.id ?? "",
        email: user?.email ?? "",
        phone: user?.phone ?? "",
        userPreferencesId: userPreference?.id ?? "",
        customerID: user?.customerID ?? "",
    };

    if (user?.appUserProfile) {
        initialValues.salutation = user.appUserProfile.salutation;
        initialValues.firstName = user.appUserProfile.firstName;
        initialValues.lastName = user.appUserProfile.lastName;
        initialValues.email = user.appUserProfile.email ?? "";
        initialValues.phone = user.appUserProfile.phoneNumber ?? "";

        if (user.appUserProfile.role) {
            initialValues.corporatePositionID = user.appUserProfile.role.id;
        }
    }

    const handleSubmit = async (model: Omit<PutUserRequestPayloadV2, "accountType"> & { accountType?: string }) => {
        try {
            generalStore.setIsLoading(true);

            const userData = cloneDeep(model);
            userData.phone = normalizePhoneNumber(userData.phone);

            if (!isValidPhoneNumber(userData.phone)) {
                // For removal -> don't send empty string
                userData.phone = undefined;
            }

            if (!userData.email) {
                // For removal -> don't send empty string
                userData.email = undefined;
            }

            if (
                !userData.userPreferencesId || // For removal -> don't send empty string
                (!user?.areExplicitUserPreferences && user?.userPreferencesId === userData.userPreferencesId)
            ) {
                userData.userPreferencesId = undefined;
            }

            await API.putUserRequest(user?.id ?? "", userData as PutUserRequestPayloadV2);
            await reloadUser();
            generalStore.setSuccessMessage(t("success.editUser"));
        } catch (error) {
            if (isErrorOfType(error, "CONFLICT_USERNAME_ALREADY_EXISTS")) {
                generalStore.setError(t("error.usernameInUse"), error);
            }
        } finally {
            onClose();
            generalStore.setIsLoading(false);
        }
    };

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={userValidationSchema(user)}>
            <Form
                style={{
                    width: "100%",
                    height: "inherit",
                    overflow: "auto",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
                noValidate
            >
                <div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <h4>{t("screen.userDetails.edit.title")}</h4>
                        <IconButton onClick={onClose}>
                            <Icon name="close" />
                        </IconButton>
                    </div>
                    <div style={{ marginTop: 40 }}>
                        {!userPreferencesIsLoading && (
                            <UserDetailsFields
                                user={user ?? undefined}
                                userPreferences={userPreferences ?? undefined}
                            />
                        )}
                    </div>
                </div>
                <Button type="submit" variant="contained" style={{ marginTop: 32 }}>
                    {t("screen.userDetails.edit.button.text")}
                </Button>
            </Form>
        </Formik>
    );
};
