import { Alert, Button, Divider } from "@mui/material";
import { observer } from "mobx-react";
import { t } from "../../../../i18n/util";
import { SiteContainer } from "../../../ui/Primitives";
import { usePagination } from "../../../../hooks/usePagination";
import { useDistributors } from "../../../../hooks/useDistributors";
import { useMultiSelectionFilter } from "../../../../hooks/useMultiSelectionFilter";
import { uniqBy } from "lodash";
import { useCustomers } from "../../../../hooks/useCustomers";
import { Filters } from "../../../ui/filters/Filters";
import { SEARCH_FIELD_MAX_WIDTH } from "../../../../config";
import { SearchField } from "../../../ui/SearchField";
import { useState } from "react";
import { API } from "../../../../network/API";
import { IdsDeleteMappingConfirmationDialog } from "./components/IdsDeleteMappingConfirmationDialog";
import { IdsAddCustomerNumberDrawer } from "./components/IdsAddCustomerNumberDrawer";
import { IdsCustomersTable } from "./components/IdsCustomersTable";

const ALL_CUSTOMERS_LIST_LIMIT = 15;
const CUSTOMERS_TABLE_PAGESIZE = 10;

export const IdsManagementSite = observer(() => {
    const pagination = usePagination({
        pageSize: CUSTOMERS_TABLE_PAGESIZE,
    });
    const distributors = useDistributors();
    const [showAddIdsForm, setShowAddIdsForm] = useState(false);
    const [deleteMappingDialogOpen, setDeleteMappingDialogOpen] = useState(false);
    const [deleteMappingCustomerId, setDeleteMappingCustomerId] = useState<string | null>(null);
    const [search, setSearch] = useState("");
    const distributorFilter = useMultiSelectionFilter({
        name: "distributors",
        label: t("filter.label.distributor"),
        options:
            uniqBy(distributors.distributors, (distributor) => distributor.externalVkorgID).map((distributor) => ({
                value: distributor.externalVkorgID,
                label: distributor.shortName,
            })) ?? [],
    });
    const { customers, isLoading, reloadCustomers } = useCustomers({
        limit: pagination.pageSize,
        offset: pagination.offset,
        search: pagination.search,
        distributors: distributors.getIDsByExternalVkorgIDs(distributorFilter.values),
        alternativeIDS: true,
    });
    const { customers: allCustomers } = useCustomers({
        limit: ALL_CUSTOMERS_LIST_LIMIT,
        offset: 0,
        search: search,
    });

    const handleChangeSearch = (value: string) => {
        setSearch(value);
    };

    const handleEnter = (value: string, event?: React.KeyboardEvent<HTMLInputElement>) => {
        event?.preventDefault();
        handleChangeSearch(value);
    };

    const deleteAlternativeMapping = async () => {
        try {
            if (deleteMappingCustomerId) {
                await API.putIdsAlternativeMapping(deleteMappingCustomerId, false);

                setDeleteMappingDialogOpen(false);
                reloadCustomers();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleSubmit = async (
        setSubmitting: (isSubmitting: boolean) => void,
        values: { customerId: string },
        alternativeIDSMapping: boolean,
    ) => {
        setSubmitting(true);

        try {
            await API.putIdsAlternativeMapping(values.customerId, alternativeIDSMapping);
            setShowAddIdsForm(false);
            reloadCustomers();
        } catch (error) {
            console.error(error);
        }

        setSubmitting(false);
    };

    const handleDeleteAlternativeMapping = (customerId: string) => {
        setDeleteMappingCustomerId(customerId);
        setDeleteMappingDialogOpen(true);
    };

    return (
        <SiteContainer>
            <IdsDeleteMappingConfirmationDialog
                title={t("modal.delete.alternativeidsmapping.title")}
                message={t("modal.delete.alternativeidsmapping.body")}
                cancelLabel={t("common.no")}
                submitLabel={t("modal.delete.alternativeidsmapping.deleteButton")}
                open={deleteMappingDialogOpen}
                onSubmit={() => deleteAlternativeMapping()}
                onCancel={() => setDeleteMappingDialogOpen(false)}
                hideCancel={false}
            />
            <IdsAddCustomerNumberDrawer
                allCustomers={allCustomers}
                handleChangeSearch={handleChangeSearch}
                handleEnter={handleEnter}
                handleSubmit={handleSubmit}
                setShowAddIdsForm={setShowAddIdsForm}
                showAddIdsForm={showAddIdsForm}
            />
            <div
                style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 32,
                }}
            >
                <h1>{t("common.ids")}</h1>
                <Button color="primary" onClick={() => setShowAddIdsForm(true)} variant="contained">
                    {t("alternativeidsmapping.customerNumber.add")}
                </Button>
            </div>
            <Divider style={{ margin: "16px 0px" }} />
            <div
                style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 32,
                }}
            >
                <Filters>{distributorFilter.component}</Filters>
                <div style={{ maxWidth: SEARCH_FIELD_MAX_WIDTH, width: "100%" }}>
                    <SearchField
                        onChange={pagination.onChangeSearch}
                        placeholder={t("screen.users.search.placeholder")}
                        readOnly={isLoading}
                        style={{ width: "100%" }}
                        value={pagination.search}
                    />
                </div>
            </div>
            <Divider style={{ margin: "16px 0px 16px 0px" }} />
            <Alert severity="info">{t("alternativeidsmapping.infobox")}</Alert>
            <IdsCustomersTable
                customers={customers}
                distributors={distributors.distributors}
                handleDeleteAlternativeMapping={handleDeleteAlternativeMapping}
                pagination={pagination}
            />
        </SiteContainer>
    );
});
