export const Colors = {
    PRIMARY_50: "#F2F6F9",
    PRIMARY_300: "#2191D9",
    PRIMARY_400: "#1480C4",
    PRIMARY_500: "#004990",
    PRIMARY_600: "#003A73",
    PRIMARY_700: "#002549",
    SECONDARY_300: "#D5E4A8",
    SECONDARY_400: "#D0E09D",
    SECONDARY_500: "#CBDD92",
    SECONDARY_600: "#53AF32",
    SECONDARY_700: "#428C28",
    LIGHT: "#FFFFFF",
    GREY_50_BACKGROUND: "#FCFCFC",
    GREY_200: "#EDEDED",
    GREY_500: "#ABAFB2",
    GREY_700: "#4F4F4F",
    HEADINGS_LIGHT: "#FFFFFF",
    HEADINGS_DARK: "#121212",
    BODY_LIGHT: "#D7D7D7",
    BODY_1: "#4F4F4F",
    BODY_2: "#9B9B9B",
    RED: "#E75A7C",
    ERROR: "#E7333F",
    WARNING: "#F9C03A",
    WARNING_2: "#F08213",
    SUCCESS: "#53AF32",
    DARK: "#000000",
    ACTIVE: "#3AB186",
    DISABLED: "#CDD2DA",
} as const;

//TODO - colors not defined yet
export const UserStatusColors = {
    created: "#F9C03A",
    invited: "#F9C03A",
    active: "#53AF32",
    deactivated: "#ABAFB2",
    waiting: "#F9C03A",
    denied: "#ABAFB2",
    deleted: "#ABAFB2",
} as const;
