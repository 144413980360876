export const Routes = {
    ROOT: "/",
    FORGOT_PASSWORD: {
        EMAIL: "/forgot-password",
        CONFIRM: "/forgot-password/confirm",
        RESET: "/forgot-password/reset",
    },
    USERS: "/users",
    REQUESTS: "/requests",
    SETTINGS: "/settings",
    EXPO_TICKETS: "/expo-tickets",
    INTERFACES: "/interfaces",
};
