import { Button, ListItemIcon, Menu, MenuItem, PopoverOrigin } from "@mui/material";
import * as React from "react";
import { CmsMenu, NeutralButton } from "../components/ui/Primitives";
import { Colors } from "../components/util/Colors";

export type ContextMenuItem = {
    iconSrc?: string;
    selected?: boolean;
    title: string;
    description?: string;
    onClick?: () => void;
    disabled?: boolean;
};

export function useContextMenu<T>(createContextMenuItems: (contextElement?: T) => ContextMenuItem[]) {
    const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);
    const [contextMenuItems, setContextMenuItems] = React.useState<ContextMenuItem[]>([]);

    const open = (event: React.MouseEvent<HTMLElement>, element?: T) => {
        setAnchor(event.currentTarget);
        const items = createContextMenuItems(element);
        setContextMenuItems(items);
    };

    const close = () => {
        setAnchor(null);
        setContextMenuItems([]);
    };

    const component = (
        <ContextMenu
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            config={{
                anchor,
                close,
            }}
            items={contextMenuItems}
        />
    );

    return {
        open,
        component,
    };
}

const ContextMenuLine = (props: { item: ContextMenuItem }) => {
    return (
        <MenuItem
            key={props.item.title}
            selected={props.item.selected}
            onClick={props.item.onClick}
            disabled={props.item.disabled}
            data-id={props.item["data-id"]}
            style={{ backgroundColor: Colors.LIGHT, color: Colors.DARK, padding: "8px 16px" }}
            disableRipple
        >
            {props.item.iconSrc && (
                <ListItemIcon>
                    <img src={props.item.iconSrc} alt="" />
                </ListItemIcon>
            )}
            <NeutralButton fullWidth>{props.item.title}</NeutralButton>
        </MenuItem>
    );
};

const ContextMenu = (props: {
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
    items: ContextMenuItem[];
    maxWidth?: number;
    maxHeight?: number;
    config: {
        anchor: Element | null;
        close?: () => void;
    };
}) => {
    const menuOpen = !!props.config.anchor;
    const minWidth = Math.min(160, props.maxWidth ?? 160);
    return (
        <CmsMenu
            data-id={props["data-id"]}
            open={menuOpen}
            // getContentAnchorEl={null}
            anchorOrigin={
                props.anchorOrigin ?? {
                    vertical: "bottom",
                    horizontal: "center",
                }
            }
            transformOrigin={
                props.transformOrigin ?? {
                    vertical: "top",
                    horizontal: "center",
                }
            }
            anchorEl={props.config.anchor}
            onClose={props.config.close}
            PaperProps={{
                style: {
                    minHeight:0, //Prevents flickering
                    minWidth,
                    maxWidth: props.maxWidth ?? 264,
                    width: "100%",
                },
            }}
        >
            <div style={props.maxHeight ? { maxHeight: props.maxHeight, overflow: "auto" } : undefined}>
                {props.items.map((item, index) => {
                    const clickHandler = item.onClick;
                    item.onClick = () => {
                        clickHandler?.();
                        // Close context menu
                        props.config.close?.();
                    };
                    return <ContextMenuLine key={`${item.title}_${index}`} item={item} />;
                })}
            </div>
        </CmsMenu>
    );
};
