import { Skeleton, TableHead, TableRow } from "@mui/material";
import { CmsTableCell, CmsTableCellWithCheckbox } from "./Primitives";
import { Colors } from "../util/Colors";
import { TableCellSkeleton } from "./TableCellSkeleton";

export const TableHeaderSkeleton = ({ columns }: { columns: number }) => {
    return (
        <TableHead>
            <TableRow>
                {[...Array(columns)].map((_, index) => {
                    return index !== 0 ? (
                        <TableCellSkeleton
                            CellComponent={CmsTableCellWithCheckbox}
                            key={index}
                            skeletonStyle={{ height: 24, backgroundColor: Colors.BODY_LIGHT }}
                        />
                    ) : (
                        <CmsTableCellWithCheckbox key={index} />
                    );
                })}
            </TableRow>
        </TableHead>
    );
};
