import * as React from "react";
import { t } from "../i18n/util";
import { API } from "../network/API";
import { Distributor } from "../network/APITypes";
import { generalStore } from "../stores/GeneralStore";
import { compact } from "lodash";

export const useDistributors = (preventGlobalLoadingStateChange?: boolean) => {
    const [response, setResponse] = React.useState<Distributor[] | null>(null);
    const [isPending, setIsPending] = React.useState<boolean>(false);

    const load = React.useCallback(async () => {
        if (!preventGlobalLoadingStateChange) {
            generalStore.setIsLoading(true);
        }

        setIsPending(true);
        try {
            const res = await API.getDistributors();
            setResponse(res.distributors ?? []);
        } catch (error) {
            generalStore.setError(t("error.loadUsers"), error);
        } finally {
            if (!preventGlobalLoadingStateChange) {
                generalStore.setIsLoading(false);
            }
            setIsPending(false);
        }
    }, [preventGlobalLoadingStateChange]);

    React.useEffect(() => {
        load();
    }, [load]);

    return {
        distributors: response,
        reloadDistributors: load,
        getExternalVkorgID(id?: string) {
            return response?.find((distributor) => distributor.id === id)?.externalVkorgID;
        },
        getIDsByExternalVkorgIDs(externalIDs?: string[]) {
            return (
                compact(
                    externalIDs?.map((distributor) => response?.find((d) => d.externalVkorgID === distributor)?.id),
                ) || []
            );
        },
        getDistributorShortName(id?: string) {
            return response?.find((distributor) => distributor.id === id)?.shortName;
        },
        distributorsIsPending: isPending,
    };
};

export type DistributorsHook = ReturnType<typeof useDistributors>;
