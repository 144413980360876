import { Button } from "@mui/material";
import { Form, Formik } from "formik";
import * as React from "react";
import { CustomerDetailsFields } from "../components/forms/customer/CustomerDetailsFields";
import {
    CustomerDetailsOCIFields,
    isOciOverrideValue,
    ociToFormikName,
} from "../components/forms/customer/CustomerDetailsOCIFields";
import { CustomDrawer } from "../components/ui/CustomDrawer";
import { DialogHeader } from "../components/ui/DialogHeader";
import { t } from "../i18n/util";
import { API } from "../network/API";
import { OCIOverride } from "../network/APITypes";
import { generalStore } from "../stores/GeneralStore";
import { useCustomer } from "./useCustomer";
import { useCustomerOciOverrides } from "./useCustomerOciOverrides";
import { DistributorsHook } from "./useDistributors";

export const useCustomerDetailsForm = ({
    distributorsHook,
    onSubmit,
}: {
    distributorsHook: DistributorsHook;
    onSubmit?: (changed: boolean) => void;
}) => {
    const [customerId, setCustomerId] = React.useState<string>();
    const open = !!customerId;

    const { customer } = useCustomer(customerId);
    const { ociOverrides, reset } = useCustomerOciOverrides(customerId);

    const handleClose = () => {
        setCustomerId(undefined);
        reset();
    };

    const handleSubmit = async (values: any) => {
        if (!customerId) {
            return;
        }

        if (ociOverrides) {
            generalStore.isLoading = true;
            let anyMappingChanged = false;
            // No forEach so we have sequential requests for more user friendly error handling
            for (let i = 0; i < ociOverrides.ociOverrides.length; i++) {
                const override = ociOverrides.ociOverrides[i];
                const newValue = values[ociToFormikName(override.field)];
                const valueChanged = newValue !== override.valueKey && newValue !== override.constantValue;
                if (valueChanged) {
                    anyMappingChanged = true;
                    const newOverride: OCIOverride = { id: override.id, field: override.field };
                    let putOrPost = false;
                    if (isOciOverrideValue(newValue)) {
                        newOverride.valueKey = newValue;
                        putOrPost = true;
                    } else if (!!newValue) {
                        newOverride.constantValue = newValue;
                        putOrPost = true;
                    }

                    try {
                        if (putOrPost) {
                            await API.updateOverride(customerId, newOverride);
                        } else if (override.id) {
                            await API.deleteCustomerOciOverride(customerId, override.id);
                        }
                    } catch (error) {
                        generalStore.setError(
                            t(putOrPost ? "error.updateOciOverride" : "error.deleteOciOverride", {
                                key: override.field,
                            }),
                            error,
                        );
                    }
                }
            }

            generalStore.isLoading = false;

            onSubmit?.(anyMappingChanged);
            handleClose();
        }
    };

    const getInitialValues = React.useCallback(() => {
        const initialValues: any = {
            customerNumber: customer?.externalId,
            distributor: distributorsHook.getDistributorShortName(customer?.distributorId),
            name: customer?.name,
            address: `${customer?.address?.streetAddress}, ${customer?.address?.postalCode} ${customer?.address?.city}`,
        };

        if (ociOverrides) {
            ociOverrides.ociOverrides.forEach((override) => {
                if (override.valueKey || override.constantValue) {
                    initialValues[ociToFormikName(override.field)] = override.valueKey ?? override.constantValue;
                }
            });
        }

        return initialValues;
    }, [distributorsHook, customer, ociOverrides]);

    const customerLoaded = customerId && customer?.id === customerId && !!ociOverrides;

    const component = open ? (
        <CustomDrawer open={open} onClose={handleClose} style={{ paddingRight: 0 }}>
            {customerLoaded && (
                <Formik initialValues={getInitialValues()} onSubmit={handleSubmit} enableReinitialize>
                    {({ values }) => (
                        <Form
                            style={{
                                width: "100%",
                                height: "inherit",
                                overflow: "auto",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                            }}
                            noValidate
                        >
                            <DialogHeader
                                title={t("customerDetailsForm.title")}
                                onClose={handleClose}
                                style={{ marginRight: 32 }}
                            />
                            <div style={{ flex: 1, overflowY: "scroll" }}>
                                <div style={{ marginTop: 40, marginRight: 32 }}>
                                    <CustomerDetailsFields />
                                    <h4 style={{ marginBottom: 16 }}>{t("customerDetails.ociConfiguration")}</h4>
                                    <CustomerDetailsOCIFields overrides={ociOverrides} />
                                </div>
                            </div>
                            <Button variant="contained" style={{ marginTop: 32, marginRight: 32 }} type="submit">
                                {t("button.saveChanges")}
                            </Button>
                        </Form>
                    )}
                </Formik>
            )}
        </CustomDrawer>
    ) : null;

    return {
        open(customerId: string) {
            setCustomerId(customerId);
        },

        component,
    };
};
