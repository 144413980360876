import { MessageIDS, t } from "../../i18n/util";
import { RoleStatusEnum } from "../../network/APITypes";
import { CellStatus, statusToColor } from "./TableStatusCell";

const roleStatusToCellStatus: Record<RoleStatusEnum, CellStatus> = {
    active: "active",
    deactivated: "disabled",
    system: "primary",
};

export const roleStatusToProps = (status: RoleStatusEnum) => ({
    label: t(("role.status." + status) as MessageIDS),
    color: statusToColor[roleStatusToCellStatus[status]],
});
