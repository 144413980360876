import { Button, Popover } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React from "react";
import { overlayPaperStyle } from "./Filters";
import { FILTER_DATE_DISPLAY_FORMAT, FILTER_DEFAULT_MIN_DATE } from "../../../hooks/useDateRangeFilter";
import { t } from "../../../i18n/util";
import { StyledButton } from "./MultiSelectionFilterButton";

interface CurrentDateProperties {
    date: dayjs.Dayjs | null;
    error?: string;
}
export const DateRangeFilterPopover = ({
    dateFrom,
    dateTo,
    anchorElement,
    onApply,
    onClose,
}: {
    dateFrom: dayjs.Dayjs | null;
    dateTo: dayjs.Dayjs | null;
    anchorElement: any;
    onApply: (dateFrom: dayjs.Dayjs | null, dateTo: dayjs.Dayjs | null) => void;
    onClose: () => void;
}) => {
    const [currentDateFrom, setCurrentDateFrom] = React.useState<CurrentDateProperties>({ date: dateFrom });
    const [currentDateTo, setCurrentDateTo] = React.useState<CurrentDateProperties>({ date: dateTo });
    dayjs.locale("de");

    const handleClickReset = () => {
        onApply(null, null);
        onClose();
    };

    const handleClickApply = () => {
        onApply(currentDateFrom.date, currentDateTo.date);
        onClose();
    };

    const Shortcuts = () => {
        const shortcutsoptions: { label: string; onClick: () => void }[] = [
            {
                label: t("filter.date.last_month"),
                onClick: () => {
                    const today = dayjs();
                    setCurrentDateFrom({ date: today.subtract(30, "day") });
                    setCurrentDateTo({ date: today });
                },
            },
            {
                label: t("filter.date.last_six_months"),
                onClick: () => {
                    const today = dayjs();
                    setCurrentDateFrom({ date: today.subtract(180, "day") });
                    setCurrentDateTo({ date: today });
                },
            },
            {
                label: t("filter.date.last_year"),
                onClick: () => {
                    const today = dayjs();
                    setCurrentDateFrom({ date: today.subtract(365, "day") });
                    setCurrentDateTo({ date: today });
                },
            },
        ];

        return (
            <div style={{ display: "flex", flexDirection: "column", padding: "16px 0px 16px 16px", gap: 4 }}>
                {shortcutsoptions.map((item) => (
                    <Button
                        key={item.label}
                        size="small"
                        style={{ padding: "4px 8px", fontWeight: 600 }}
                        onClick={item.onClick}
                    >
                        {item.label}
                    </Button>
                ))}
            </div>
        );
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            <Popover
                anchorEl={anchorElement}
                open={!!anchorElement}
                onClose={onClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                PaperProps={{
                    style: overlayPaperStyle,
                }}
            >
                <div style={{ display: "flex" }}>
                    <Shortcuts />
                    <div style={{ display: "flex", gap: 16, padding: 16, alignItems: "center" }}>
                        <DatePicker
                            format={FILTER_DATE_DISPLAY_FORMAT}
                            label={t("common.date.from")}
                            value={currentDateFrom.date}
                            slotProps={{
                                field: {
                                    clearable: true,
                                    onClear: () => setCurrentDateFrom({ date: null, error: undefined }),
                                },
                            }}
                            onChange={(newValue) => {
                                if (newValue?.isValid())
                                    setCurrentDateFrom((currentValue) => {
                                        return { ...currentValue, date: newValue };
                                    });
                                if (currentDateTo.date === null && newValue?.isValid()) {
                                    setCurrentDateTo({ date: dayjs() });
                                }
                            }}
                            onError={(newError) =>
                                setCurrentDateFrom((currentValue) => {
                                    return { ...currentValue, error: newError?.toString() };
                                })
                            }
                            sx={{ maxWidth: 220, height: "fit-content" }}
                            minDate={FILTER_DEFAULT_MIN_DATE}
                            maxDate={dayjs()}
                        />
                        <span>-</span>
                        <DatePicker
                            format={FILTER_DATE_DISPLAY_FORMAT}
                            label={t("common.date.to")}
                            value={currentDateTo.date}
                            slotProps={{
                                field: {
                                    clearable: true,
                                    onClear: () => setCurrentDateTo({ date: null, error: undefined }),
                                },
                            }}
                            onChange={(newValue) => {
                                setCurrentDateTo((currentValue) => {
                                    return { ...currentValue, date: newValue };
                                });

                                if (currentDateFrom.date === null && newValue?.isValid()) {
                                    setCurrentDateFrom({ date: FILTER_DEFAULT_MIN_DATE });
                                }
                            }}
                            onError={(newError) =>
                                setCurrentDateTo((currentValue) => {
                                    return { ...currentValue, error: newError?.toString() };
                                })
                            }
                            sx={{ maxWidth: 220, height: "fit-content" }}
                            minDate={FILTER_DEFAULT_MIN_DATE}
                            maxDate={dayjs()}
                        />
                    </div>
                </div>

                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 16,
                        paddingBottom: 16,
                    }}
                >
                    <Button onClick={handleClickReset}>{t("filter.button.reset")}</Button>
                    <StyledButton
                        onClick={handleClickApply}
                        variant="contained"
                        disabled={!!currentDateFrom.error || !!currentDateTo.error}
                    >
                        {t("filter.button.apply")}
                    </StyledButton>
                </div>
            </Popover>
        </LocalizationProvider>
    );
};
