import { RoleDetailsOptions } from "../types";
import { useRole } from "./useRole";
import { useUserPermissions } from "./useUserPermissions";

export const useRoleOrUserPermissions = ({
    roleId,
    userId,
    options,
    loadCondition,
}: { roleId?: string; userId?: string; options?: RoleDetailsOptions; loadCondition?: boolean } = {}) => {
    const rolePermissions = useRole({
        id: roleId,
        options,
        loadCondition,
    });

    const userPermissions = useUserPermissions({
        id: userId,
        options,
        loadCondition,
    });

    return roleId
        ? {
              ...rolePermissions,
              // Add this to keep common interface between role and user permissions
              grantedNonRolePermissions: [],
          }
        : userPermissions;
};
