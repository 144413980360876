import { Field, getIn, useFormikContext } from "formik";
import * as React from "react";
import { useDeepCompareEffect } from "use-deep-compare";
import { useDistributorCustomers } from "../../../hooks/useDistributorCustomers";
import { t } from "../../../i18n/util";
import { BaseCustomer, PostUserRequestsPayloadV2 } from "../../../network/APITypes";
import { CustomRadioGroup } from "../../ui/CustomRadioGroup";
import { SearchField } from "../../ui/SearchField";
import { Icon } from "../../util/Icon";

export const CustomerNumberSelection = ({
    onSelectCustomer,
    initialSearch,
}: {
    onSelectCustomer: (customer?: BaseCustomer) => void;
    initialSearch?: string;
}) => {
    const { values, setFieldValue } = useFormikContext<PostUserRequestsPayloadV2>();
    const [search, setSearch] = React.useState(initialSearch ?? "");

    const selectedDistributorId = getIn(values, "distributorID");

    const { customers } = useDistributorCustomers(selectedDistributorId, { limit: 10, search });

    const currentValue = getIn(values, "customerID");

    useDeepCompareEffect(() => {
        const currentCustomerIsSelectable = customers?.some((customer) => customer.id === currentValue);

        if (!currentCustomerIsSelectable && currentValue) {
            setFieldValue("customerID", "");
        }
    }, [customers, currentValue]);

    useDeepCompareEffect(() => {
        const customer = customers?.find((customer) => customer.id === currentValue);
        onSelectCustomer(customer);
        setFieldValue("regionID", "");
        setFieldValue("distributionChannelID", "");
    }, [currentValue, customers, onSelectCustomer]);

    const handleChangeSearch = (value: string) => {
        setSearch(value);
    };

    const handleEnter = (value: string, event?: React.KeyboardEvent<HTMLInputElement>) => {
        event?.preventDefault();
        setSearch(value);
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", flexGrow: 1, overflow: "hidden" }}>
            <SearchField
                value={search}
                placeholder={t("addUserForm.customerNumber.search.placeholder")}
                onChange={handleChangeSearch}
                style={{ width: "100%", marginBottom: 8 }}
                onEnter={handleEnter}
            />
            <div style={{ flexGrow: 1, overflow: "auto" }}>
                <Field
                    name="customerID"
                    component={CustomRadioGroup}
                    options={
                        customers?.map((customer) => ({
                            label: (
                                <div
                                    className="body1"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        flexGrow: 1,
                                    }}
                                >
                                    <span>{customer.externalId}</span>
                                    {!customer.optIn && <Icon name="editDisabled" />}
                                </div>
                            ),
                            value: customer.id,
                        })) ?? []
                    }
                    style={{ marginTop: 8 }}
                    showValidationError={false}
                />
            </div>
        </div>
    );
};
