import * as React from "react";
import { t } from "../i18n/util";
import { API } from "../network/API";
import { DistributionChannel } from "../network/APITypes";
import { generalStore } from "../stores/GeneralStore";

export const useDistributionChannels = (preventGlobalLoadingStateChange?: boolean) => {
    const [response, setResponse] = React.useState<DistributionChannel[] | null>(null);
    const [isPending, setIsPending] = React.useState<boolean>(false);

    const load = React.useCallback(async () => {
        if (!preventGlobalLoadingStateChange) {
            generalStore.setIsLoading(true);
        }

        setIsPending(true);

        try {
            const res = await API.getDistributionChannels();
            setResponse(res.distributionChannels ?? []);
        } catch (error) {
            generalStore.setError(t("error.loadUsers"), error);
        } finally {
            if (!preventGlobalLoadingStateChange) {
                generalStore.setIsLoading(false);
            }

            setIsPending(false);
        }
    }, [preventGlobalLoadingStateChange]);

    React.useEffect(() => {
        load();
    }, [load]);

    return { distributionChannels: response, distributionChannelsIsPending: isPending };
};
