import * as React from "react";
import { AddUserForm } from "../components/forms/addUser/AddUserForm";
import { CustomDrawer } from "../components/ui/CustomDrawer";
import { AddPartnerPortalUserForm } from "../components/forms/addUser/AddPartnerPortalUserForm";
import { CustomerType } from "../network/APITypes";

export const useAddUserForm = ({ onSubmit }: { onSubmit?: () => void | Promise<void> }) => {
    const [openVariant, setOpenVariant] = React.useState<CustomerType | undefined>(undefined);

    const handleClose = () => {
        setOpenVariant(undefined);
    };
    const formComponent = () => {
        if (openVariant === "partner") {
            return <AddPartnerPortalUserForm onClose={handleClose} onSubmit={onSubmit} />;
        }
        return <AddUserForm onClose={handleClose} onSubmit={onSubmit} />;
    };
    const component = openVariant ? (
        <CustomDrawer open={!!openVariant} onClose={handleClose} style={{ padding: "16px 12px" }}>
            {formComponent()}
        </CustomDrawer>
    ) : null;

    return {
        open(variant: CustomerType) {
            setOpenVariant(variant);
        },

        component,
    };
};
