import { Field } from "formik";
import * as React from "react";
import { CustomRadioGroup } from "../../ui/CustomRadioGroup";
import { Distributor } from "../../../network/APITypes";

export const VkoSelection = ({ distributors }: { distributors: Distributor[] | null }) => {
    const options =
        distributors?.map((distributor) => ({
            value: distributor.id,
            label: distributor.shortName,
        })) ?? [];

    return (
        <Field
            name="distributorID"
            component={CustomRadioGroup}
            options={options}
            showValidationError={false}
            focusFirstInput
        />
    );
};
