import { Button, Dialog, Divider, Table, TableBody, TableRow, Tooltip, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { DialogHeader } from "../components/ui/DialogHeader";
import { DialogStepper } from "../components/ui/DialogStepper";
import { EmptyState } from "../components/ui/EmptyState";
import { IOSSwitch } from "../components/ui/IOSSwitch";
import { CmsTableCell, CmsTableContainer, TableLabel } from "../components/ui/Primitives";
import { SearchField } from "../components/ui/SearchField";
import { TableHeader, TableHeaderConfig } from "../components/ui/TableHeader";
import { FilterComponents, FilterResetComponents } from "../components/ui/filters/Filters";
import { SEARCH_FIELD_MAX_WIDTH } from "../config";
import { t } from "../i18n/util";
import { API } from "../network/API";
import { PermissionService, UserRequest, UserRequestPermissionService } from "../network/APITypes";
import { generalStore } from "../stores/GeneralStore";
import { Stepper } from "../types";
import { useEditPermissionsDeleteRoleDialog } from "./useEditPermissionsDeleteRolesDialog";
import { useMultiSelectionFilter } from "./useMultiSelectionFilter";
import { usePagination } from "./usePagination";
import { useRoleOrUserPermissions } from "./useRoleOrUserPermissions";
import { useServiceGroups } from "./useServiceGroups";
import { hasVerifiedEmail } from "../components/util/Helpers";
import { Colors } from "../components/util/Colors";
import { useConfirmationDialog } from "./useConfirmationDialog";

function isUserPermission(
    permission: UserRequestPermissionService | PermissionService,
): permission is UserRequestPermissionService {
    return "grantedBy" in permission;
}

const SENSITIVE_PERMISSION_ADMA_IDENTIFIER = "adma";

const StyledSensitivityWarningParagraph = styled("p")({
    color: Colors.ERROR,
    marginTop: 8,
    fontWeight: 700,
});

const AdmaPermissionDialogMessage = ({
    message,
    sensitivityWarning,
}: {
    message?: string;
    sensitivityWarning?: string;
}) => {
    return (
        <div>
            <p>{message}</p>
            <StyledSensitivityWarningParagraph>{sensitivityWarning}</StyledSensitivityWarningParagraph>
        </div>
    );
};

// - Used as step 3 when adding a role and when choosing "edit permissions" from the role list
// - Used for user details when choosing "edit permissions"
export const useManagePermissionsDialog = ({
    roleId,
    user,
    reloadUser,
    onClose,
    onBack,
    stepper,
    title = t("editPermissions.title"),
    submitButtonLabel = t("button.saveChanges"),
    onSubmit,
    linkedUsers,
}: {
    roleId?: string;
    user?: UserRequest | null;
    reloadUser?: () => void;
    onBack?: () => void;
    onClose?: () => void;
    stepper?: Stepper;
    title?: string;
    submitButtonLabel?: string;
    onSubmit?: (grantedPermissions?: string[]) => Promise<void | boolean> | void | boolean;
    linkedUsers?: Array<UserRequest>;
}) => {
    const [open, setOpen] = useState(false);
    const [permissionToRemoveIdentifier, setPermissionToRemoveIdentifier] = useState<string | undefined>(undefined);
    const [wasAdmaChanged, setWasAdmaChanged] = useState<boolean>(false);

    const { serviceGroups } = useServiceGroups();

    const serviceGroupsFilter = useMultiSelectionFilter({
        name: "roleGroup",
        label: t("filter.label.serviceGroup"),
        options: serviceGroups.map((serviceGroup) => ({
            label: serviceGroup,
            value: serviceGroup,
        })),
    });

    // We need all permissions anyway for the PUT call, so load all permissions
    const pagination = usePagination({
        pageSize: 0,
        prefix: "permissions",
        // FBP-1481: Default sorting from now on handled in FE
        defaultOrderBy: "serviceGroup",
        defaultOrderDir: "asc",
    });

    const {
        permissions,
        isInitialized,
        isLoading,
        servicesEmpty,
        grantPermission,
        isPermissionGranted,
        grantedPermissions,
        grantedNonRolePermissions,
        reload,
        reset,
    } = useRoleOrUserPermissions({
        roleId,
        userId: user?.id,
        options: {
            serviceGroups: serviceGroupsFilter.values,
            offset: pagination.offset,
            limit: pagination.pageSize,
            orderDir: pagination.orderDir,
            orderBy: pagination.orderBy,
            search: pagination.search,
        },
        loadCondition: open,
    });

    const deleteRoleDialog = useEditPermissionsDeleteRoleDialog({
        linkedUsers,
        onSubmit: async (permissionIdentifier) => {
            await reloadUser?.();
            await reload();

            // This will trigger removal of the permission, if additionally to
            // being a role permission it was also granted explicitly
            setPermissionToRemoveIdentifier(permissionIdentifier);

            // Kept as documentation for the useEffect() below
            // grantPermission(permissionIdentifier, false);
        },
    });

    const grantAdmaPermissionDialog = useConfirmationDialog({
        title: t("managePermissions.dialog.grantAdma.title"),
        submitLabel: t("managePermissions.dialog.grantAdma.submitLabel"),
        cancelLabel: t("common.no"),
        message: (
            <AdmaPermissionDialogMessage
                message={t("managePermissions.dialog.grantAdma.message")}
                sensitivityWarning={t("managePermissions.dialog.grantAdma.sensitivityWarning")}
            />
        ),
        onSubmit: () => {
            grantPermission(SENSITIVE_PERMISSION_ADMA_IDENTIFIER, true);
            setWasAdmaChanged(true);
        },
    });

    const persistAdmaPermissionDialog = useConfirmationDialog({
        title: t("managePermissions.dialog.persistAdma.title"),
        submitLabel: t("managePermissions.dialog.persistAdma.submitLabel"),
        cancelLabel: t("common.no"),
        message: (
            <AdmaPermissionDialogMessage
                message={t("managePermissions.dialog.persistAdma.message")}
                sensitivityWarning={t("managePermissions.dialog.persistAdma.sensitivityWarning")}
            />
        ),
        onSubmit: () => {
            handleSubmit();
        },
    });

    // Why is this done in an effect and not above in onSubmit() of useEditPermissionsDeleteRoleDialog()?
    // Because doing it in onSubmit() is done immediately after reload() which
    // internally does a setState() for the new permissions which are not current yet for grantPermission().
    // So two solutions
    // - either have useUserPermissions.reload() accept an onReload callback which
    //   gets forwarded to the internal setState() call
    // - or do it in an effect like here, which I think is nicer since it
    //   doesn't require a change to the useUserPermissions() hook.
    useEffect(() => {
        if (permissionToRemoveIdentifier) {
            grantPermission(permissionToRemoveIdentifier, false);
            setPermissionToRemoveIdentifier(undefined);
        }
    }, [permissionToRemoveIdentifier, grantPermission]);

    const filters = [serviceGroupsFilter];

    const close = () => {
        setOpen(false);
        pagination.clearUrl();
    };

    const handleClose = () => {
        close();
        onClose?.();
    };

    const handleBack = onBack
        ? () => {
              close();
              onBack();
          }
        : undefined;

    const headerFields: TableHeaderConfig[] = [
        { column: "identifier", label: "table.label.serviceId" },
        { column: "name", label: "table.label.serviceName" },
        { column: "description", label: "table.label.description" },
        { column: "serviceGroup", label: "table.label.serviceGroup" },
        { column: "roleCount", label: "table.label.roleCount" },
        { column: "toggle", style: { width: 0 } },
    ];

    const tableBody = (
        <TableBody>
            {permissions?.map((permission) => {
                return (
                    <TableRow key={permission.identifier}>
                        {headerFields.map(({ column }) => {
                            const label = permission[column];

                            const hasValidEmail = hasVerifiedEmail(user);

                            const created = user?.status === "created";

                            if (column === "toggle") {
                                // User management permission is only allowed if the user has a valid email or user is in "created" state
                                const disabled =
                                    user && permission.identifier === "users:management" && !hasValidEmail && !created;

                                return (
                                    <CmsTableCell key={column}>
                                        <Tooltip
                                            title={disabled ? t("screen.userDetails.emailNotConfirmed") : undefined}
                                        >
                                            <div>
                                                <IOSSwitch
                                                    checked={isPermissionGranted(permission.identifier)}
                                                    onChange={(
                                                        event: React.ChangeEvent<HTMLInputElement>,
                                                        checked: boolean,
                                                    ) => {
                                                        if (
                                                            checked &&
                                                            permission.identifier ===
                                                                SENSITIVE_PERMISSION_ADMA_IDENTIFIER
                                                        ) {
                                                            grantAdmaPermissionDialog.open();
                                                            return;
                                                        }

                                                        if (
                                                            !checked &&
                                                            user &&
                                                            isUserPermission(permission) &&
                                                            (permission.grantedBy?.length ?? 0) > 0
                                                        ) {
                                                            // We are deactivating a permission granted by a role -> ask to remove role
                                                            deleteRoleDialog.open(
                                                                user,
                                                                permission.identifier,
                                                                permission.grantedBy || [],
                                                            );
                                                        } else {
                                                            grantPermission(permission.identifier, checked);
                                                        }
                                                    }}
                                                    disabled={disabled}
                                                />
                                            </div>
                                        </Tooltip>
                                    </CmsTableCell>
                                );
                            }

                            return (
                                <CmsTableCell
                                    key={column}
                                    style={{
                                        maxWidth: column !== "name" && column !== "accountType" ? 100 : undefined,
                                    }}
                                >
                                    <TableLabel style={{ maxWidth: "100%" }}>{label}</TableLabel>
                                </CmsTableCell>
                            );
                        })}
                    </TableRow>
                );
            })}
        </TableBody>
    );

    const handleSubmit = async () => {
        if (!roleId && !user) {
            return;
        }

        const wasProcessedExternally = await onSubmit?.(grantedPermissions);

        if (wasProcessedExternally) {
            return;
        }

        try {
            generalStore.isLoading = true;
            if (roleId) {
                await API.putRoleServices(roleId, { permissionServices: grantedPermissions });
            } else if (user) {
                // In the user case we only want to grant additional permissions that have not been granted by a role
                await API.putUserPermissions(user.id, { permissionServices: grantedNonRolePermissions });
            }
            generalStore.setSuccessMessage(t("success.editPermissions"));
            onSubmit?.();
            close();
        } catch (error) {
            generalStore.setError(t("error.editRole"), error);
        } finally {
            generalStore.isLoading = false;
        }
    };

    const handleClick = () => {
        if (
            grantedPermissions.find(
                (grantedPermission) => grantedPermission === SENSITIVE_PERMISSION_ADMA_IDENTIFIER,
            ) &&
            wasAdmaChanged
        ) {
            persistAdmaPermissionDialog.open();
        } else {
            handleSubmit();
        }
    };

    const component = (
        <Dialog open={open} fullScreen>
            <div style={{ padding: 32 }}>
                <DialogHeader title={title} onBack={handleBack} onClose={handleClose} />
                {stepper && <DialogStepper stepper={stepper} />}
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: 8,
                        marginTop: 32,
                    }}
                >
                    <FilterComponents filters={filters} />
                    <div style={{ maxWidth: SEARCH_FIELD_MAX_WIDTH }}>
                        <SearchField
                            placeholder={t("screen.settings.roles.search.placeholder")}
                            onChange={pagination.onChangeSearch}
                            value={pagination.search}
                            style={{ width: "100%" }}
                        />
                    </div>
                </div>
                <Divider style={{ marginTop: 16, marginBottom: 16 }} />
                <FilterResetComponents filters={filters} style={{ marginBottom: 16 }} />
                {isInitialized && !isLoading && servicesEmpty && (
                    <EmptyState
                        iconName="sadFace"
                        title={t("screen.settings.roles.emptyScreen.title")}
                        description={t("screen.settings.roles.emptyScreen.description")}
                    />
                )}
                {isInitialized && !servicesEmpty && (
                    <CmsTableContainer>
                        <Table>
                            <TableHeader
                                headerFields={headerFields}
                                orderBy={pagination.orderBy}
                                orderDir={pagination.orderDir}
                                onChangeOrder={pagination.onChangeOrder}
                                allowSort
                            />
                            {tableBody}
                        </Table>
                    </CmsTableContainer>
                )}
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 32 }}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleClick}
                        disabled={!isInitialized || servicesEmpty || isLoading}
                    >
                        {submitButtonLabel}
                    </Button>
                </div>
            </div>
            {deleteRoleDialog.component}
            {grantAdmaPermissionDialog.component}
            {persistAdmaPermissionDialog.component}
        </Dialog>
    );

    return {
        open: () => {
            // FBP-1503: Before it was reset() on close, but that's not good enough, because
            // permissions could have changed in the meantime e.g. by copying from another user.
            reset();
            setOpen(true);
            setWasAdmaChanged(false);
        },
        component,
        reload,
    };
};
