import { Button, Checkbox, ListItemIcon, MenuItem, menuItemClasses, MenuItemProps, styled } from "@mui/material";
import * as React from "react";
import { t } from "../../../i18n/util";
import { Colors } from "../../util/Colors";
import { Icon } from "../../util/Icon";
import { CmsMenu } from "../Primitives";
import { FilterContainer } from "./FilterContainer";

export type MultiSelectionFilterOption<T extends string = string> = {
    value: T;
    label: string;
};

type MultiSelectionFilterButtonProps<T extends string = string> = {
    label: string;
    options: MultiSelectionFilterOption<T>[];
    values: T[];
    onChange: (values: T[]) => void;
};

export const StyledMenuItem = styled((props: MenuItemProps) => <MenuItem disableRipple {...props} />, {
    shouldForwardProp: (prop: string) => prop !== "isSelected",
})(({ isSelected }: { isSelected: boolean }) => {
    const backgroundColor = isSelected ? "rgba(236, 246, 252, 0.7)" : Colors.LIGHT;

    return {
        backgroundColor,
        fontSize: 15,
        padding: "12px 16px",

        "&:hover, &:focus": {
            backgroundColor,
        },
    };
});

export const StyledButton = styled(Button)({
    fontWeight: 600,
    padding: "12px 16px",
    borderRadius: 3,
    height: 40,
});

const MultiSelectionFilterMenu = <T extends string = string>({
    options,
    values,
    anchorElement,
    onChange,
    onClose,
}: {
    options: MultiSelectionFilterOption<T>[];
    values: T[];
    anchorElement: any;
    onChange: (values: T[]) => void;
    onClose: () => void;
}) => {
    const [selectedValues, setSelectedValues] = React.useState<T[]>(values);

    const handleClickOption = (value: T) => {
        const nextSelectedValues = selectedValues.includes(value)
            ? selectedValues.filter((selectedValue) => selectedValue !== value)
            : [...selectedValues, value];

        setSelectedValues(nextSelectedValues);
    };

    const handleClickReset = () => {
        setSelectedValues([]);
        onChange([]);
        onClose();
    };

    const handleClickApply = () => {
        onChange(selectedValues);
        onClose();
    };

    return (
        <CmsMenu
            anchorEl={anchorElement}
            open={!!anchorElement}
            onClose={onClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            PaperProps={{ style: { minWidth: 330 } }}
        >
            <div style={{ maxHeight: 432, overflow: "auto" }}>
                {options.map((option) => {
                    const isSelected = selectedValues.includes(option.value);

                    return (
                        <StyledMenuItem
                            key={`${option.value}_${option.label}`}
                            isSelected={isSelected}
                            onClick={() => handleClickOption(option.value)}
                        >
                            <ListItemIcon>
                                <Checkbox disableRipple checked={isSelected} style={{ padding: 0 }} />
                            </ListItemIcon>
                            {option.label}
                        </StyledMenuItem>
                    );
                })}
            </div>
            <StyledMenuItem style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Button onClick={handleClickReset}>{t("filter.button.reset")}</Button>
                <StyledButton onClick={handleClickApply} variant="contained">
                    {t("filter.button.apply")}
                </StyledButton>
            </StyledMenuItem>
        </CmsMenu>
    );
};

export const MultiSelectionFilterButton = <T extends string = string>({
    label,
    options,
    values,
    onChange,
}: MultiSelectionFilterButtonProps<T>) => {
    const [menuAnchorElement, setMenuAnchorElement] = React.useState<null | HTMLElement>(null);

    const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorElement(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setMenuAnchorElement(null);
    };

    return (
        <>
            <FilterContainer
                isSelected={options.some((option) => values.includes(option.value))}
                onClick={handleClickMenu}
            >
                <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                    <span>{label}</span>
                    <div>
                        <Icon name="chevronDown" />
                    </div>
                </div>
            </FilterContainer>
            {!!menuAnchorElement && (
                <MultiSelectionFilterMenu<T>
                    options={options}
                    anchorElement={menuAnchorElement}
                    onClose={handleCloseMenu}
                    values={values}
                    onChange={onChange}
                />
            )}
        </>
    );
};
