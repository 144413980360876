import * as React from "react";
import { useDeepCompareCallback } from "use-deep-compare";
import { t } from "../i18n/util";
import { API } from "../network/API";
import { GetRolesResponse } from "../network/APITypes";
import { generalStore } from "../stores/GeneralStore";
import { RolesOptions } from "../types";

export const useRoles = (options?: RolesOptions, loadCondition = true) => {
    const [response, setResponse] = React.useState<GetRolesResponse | null>(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isInitialized, setIsInitialized] = React.useState(false);

    const load = useDeepCompareCallback(async () => {
        generalStore.setIsLoading(true);
        setIsLoading(true);

        try {
            const res = await API.getRoles(options);
            setResponse(res);
            setIsInitialized(true);
        } catch (error) {
            generalStore.setError(t("error.loadRoles"), error);
        } finally {
            generalStore.setIsLoading(false);
            setIsLoading(false);
        }
    }, [options]);

    React.useEffect(() => {
        if (loadCondition) {
            load();
        }
    }, [load, loadCondition]);

    return {
        rolesResponse: response,
        reloadRoles: load,
        isLoading,
        isInitialized,
        isEmpty: response && response.total === 0,
    };
};
