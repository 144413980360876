import { Field } from "formik";
import { t } from "../../../i18n/util";
import { FieldDefinition } from "../../../types";
import { CustomInputField } from "../../ui/CustomInputField";

export const CustomerDetailsFields = () => {
    const fields: FieldDefinition[] = [
        {
            label: "customerDetails.customerNumber",
            name: "customerNumber",
            disabled: true,
        },
        {
            label: "customerDetails.distributor",
            name: "distributor",
            disabled: true,
        },
        {
            label: "customerDetails.name",
            name: "name",
            disabled: true,
        },
        {
            label: "customerDetails.address",
            name: "address",
            disabled: true,
        },
    ];

    return (
        <>
            {fields
                .filter((f) => f.isInitialized !== false)
                .map((field) => {
                    return (
                        <Field
                            key={field.name}
                            component={CustomInputField}
                            label={t(field.label)}
                            name={field.name}
                            type="input"
                            disabled={field.disabled}
                        />
                    );
                })}
        </>
    );
};
