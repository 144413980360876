import { TablePagination } from "@mui/material";
import * as React from "react";
import { useLocation } from "react-router";
import { pushRoute, withQuery } from "../components/app/router/history";
import { PAGE_SIZE } from "../config";
import { useQuery } from "./useQuery";

// Use <prefix> if you have two paginated tables on one Site.
// Then you can use prefix to differentiate between the two.
export const usePagination = ({
    pageSize = PAGE_SIZE,
    searchParamName = "search",
    prefix = "",
    defaultOrderBy,
    defaultOrderDir,
}: {
    pageSize?: number;
    searchParamName?: string;
    prefix?: string;
    defaultOrderBy?: string;
    defaultOrderDir?: "asc" | "desc";
} = {}) => {
    const location = useLocation();
    const queryParams = useQuery();
    const {
        [`${prefix}page`]: page,
        [`${prefix}orderBy`]: orderBy,
        [`${prefix}orderDir`]: orderDir,
        [`${prefix}${searchParamName}`]: search,
        ...remainingQuery
    } = queryParams;

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
        pushRoute(withQuery(location.pathname, { ...queryParams, [`${prefix}page`]: page + 1 }));
    };

    const handleChangeSort = (column?: string) => {
        const nextOrderDir = column === orderBy && orderDir === "asc" ? "desc" : "asc";

        pushRoute(
            withQuery(location.pathname, {
                ...queryParams,
                [`${prefix}orderBy`]: column,
                [`${prefix}orderDir`]: nextOrderDir,
            }),
        );
    };

    const handleChangeSearch = (search?: string) => {
        pushRoute(
            withQuery(location.pathname, {
                ...queryParams,
                [`${prefix}page`]: 1,
                [`${prefix}${searchParamName}`]: search || undefined,
            }),
        );
    };

    const component = (count: number) => (
        <TablePagination
            component="div"
            count={count}
            page={count > 0 ? (page ?? 1) - 1 : 0}
            rowsPerPage={pageSize}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[]}
        />
    );

    return {
        offset: ((page ?? 1) - 1) * pageSize,
        orderBy: orderBy ?? defaultOrderBy,
        orderDir: orderDir ?? defaultOrderDir,
        search,
        pageSize,
        onChangeOrder: handleChangeSort,
        onChangeSearch: handleChangeSearch,
        component,

        // Use this to clear the pagination options from the URL (e.g. when a popup was closed)
        clearUrl: () => {
            pushRoute(withQuery(location.pathname, remainingQuery));
        },
    };
};
