import { Field, useFormikContext } from "formik";
import * as React from "react";
import { t } from "../../../i18n/util";
import { AccountTypeDomain } from "../../../network/APITypes";
import { FieldDefinition } from "../../../types";
import { CustomInputField } from "../../ui/CustomInputField";
import { CustomSelect } from "../../ui/CustomSelect";
import { accountTypeOptions } from "../../util/AccountTypes";

type DomainDetailsFieldsProps = {
    domain?: AccountTypeDomain;
};

export const DomainDetailsFields = ({ domain }: DomainDetailsFieldsProps) => {
    const { values } = useFormikContext<any>();

    const fields: FieldDefinition[] = [
        {
            label: "manageDomainForm.domain",
            name: "domain",
        },
        {
            label: "manageDomainForm.accountType",
            name: "accountType",
            component: CustomSelect,
            options: accountTypeOptions,
        },
        {
            label: "manageDomainForm.description",
            name: "description",
            multiline: true,
            minRows: 5,
            maxRows: 5,
            maxLength: 100,
            type: "textarea",
        },
    ];

    return (
        <>
            {fields.map((field) => {
                return (
                    <Field
                        key={field.name}
                        component={field.component ?? CustomInputField}
                        label={t(field.label)}
                        name={field.name}
                        type={field.type ?? "input"}
                        required={field.required}
                        options={field.options}
                        disabled={field.disabled}
                        multiline={field.multiline}
                        minRows={field.minRows}
                        maxRows={field.maxRows}
                        maxLength={field.maxLength}
                    />
                );
            })}
        </>
    );
};
