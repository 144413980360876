import { useEffect, useState } from "react";
import { Role, RolePayload } from "../network/APITypes";
import { useRoleDetailsForm } from "./useRoleDetailsForm";
import { useRoleGroupDetailsForm } from "./useRoleGroupDetailsForm";
import { useStepFlow } from "./useStepFlow";
import { cloneDeep } from "lodash";
import { RoleDetailsFormData } from "../components/forms/addRole/RoleDetailsForm";
import { generalStore } from "../stores/GeneralStore";
import { t } from "../i18n/util";
import { API } from "../network/API";

const STEP_ROLE_DETAILS = 0;
const STEP_ADD_ROLE_GROUP = 1;
const STEP_COUNT = 2;

export const useRoleDetailsFlow = ({ reloadRoles }: { reloadRoles?: () => void }) => {
    const flow = useStepFlow(STEP_COUNT);
    const [open, setOpen] = useState(false);

    // Need a deep copy of the role to avoid modifying the original role. Otherwise, if the user
    // cancels the form, the original role will be modified.
    const [editRole, setEditRole] = useState<Role | null>(null);
    const [detailsFormData, setDetailsFormData] = useState<RoleDetailsFormData>();

    const roleDetailsForm = useRoleDetailsForm({
        role: editRole,
        formData: detailsFormData,
        async onSubmit(formData) {
            if (!formData || !editRole) {
                return;
            }

            try {
                generalStore.setIsLoading(true);
                await API.putRole(editRole.id, formData as RolePayload);
                reloadRoles?.();
                setOpen(false);
                generalStore.setSuccessMessage(t("success.editRole"));
            } catch (error) {
                generalStore.setError(t("error.editRole"), error);
            } finally {
                generalStore.setIsLoading(false);
            }
        },
        onNewGroup(params) {
            // Store values to restore later
            setDetailsFormData(params.formData);
            roleGroupDetailsForm.open(params.initialGroupName);
            flow.setStep(STEP_ADD_ROLE_GROUP);
        },
    });

    const roleGroupDetailsForm = useRoleGroupDetailsForm({
        onSubmit(result) {
            if (result && "id" in result) {
                // Restore old form with new role group id
                const newFormData = {
                    name: "",
                    description: "",
                    accountType: "",
                    ...detailsFormData,
                    roleGroupId: result.id,
                };
                setDetailsFormData(newFormData);
            }
            flow.back();
        },
        onBack() {
            flow.back();
        },
    });

    const handleOpen = (role: Role) => {
        setOpen(true);
        flow.reset();
        setEditRole(cloneDeep(role));
        roleDetailsForm.open();
    };

    let component = roleDetailsForm.component;
    if (flow.step === STEP_ADD_ROLE_GROUP) {
        component = roleGroupDetailsForm.component;
    }

    return {
        open: handleOpen,
        component: open ? component : null,
    };
};
