import { useDeepCompareCallback } from "use-deep-compare";
import { UserPreferencesPaginationOptions } from "../types";
import { generalStore } from "../stores/GeneralStore";
import React from "react";
import { API } from "../network/API";
import { GetUserPreferencesListResponse } from "../network/APITypes";
import { t } from "../i18n/util";

export const useUserPreferences = (options?: UserPreferencesPaginationOptions) => {
    const [response, setResponse] = React.useState<GetUserPreferencesListResponse>();
    const [isLoading, setIsLoading] = React.useState(false);

    const load = useDeepCompareCallback(async () => {
        generalStore.setIsLoading(true);
        setIsLoading(true);

        try {
            const res = await API.getUserPreferences(options);
            setResponse(res);
        } catch (error) {
            generalStore.setError(t("error.loadUserPreference"), error);
        } finally {
            generalStore.setIsLoading(false);
            setIsLoading(false);
        }
    }, [options]);

    React.useEffect(() => {
        load();
    }, [load]);

    return { userPreferences: response?.userPreferences, userPreferencesIsLoading: isLoading };
};
