import * as queryString from "query-string";
import { PublicHttpError, PublicHttpValidationError } from "./APITypes";

// Join two urls but do NOT normalize url afterwards to keep any https:// double slashes afterwards
// for our CORS proxy (url-join for sid polling chokes on this)
export function urljoin(base?: string, url?: string) {
    // trim trailing /
    let left = base ?? "";
    if (left.length > 0 && left[left.length - 1] === "/") {
        left = left.slice(0, -1);
    }

    // trim leading /
    let right = url ?? "";
    if (right.length > 0 && right[0] === "/") {
        right = right.substr(1);
    }

    const separator = right.length > 0 && right[0] === "?" ? "" : "/";
    return left + separator + right;
}

/**
 * defines a request target
 */
export interface IAPITarget {
    /**
     * relative url to resource (without base url)
     */
    url: string;
    /**
     * http method
     */
    method?: "GET" | "POST" | "DELETE" | "PUT" | "PATCH";
    /**
     * body of request
     */
    body?: object | string;
    /**
     * query parameters
     */
    queryParameters?: object;
    /**
     * used to mock a response for testing / development purpose
     */
    mockResponseJSON?: () => object;
    /**
     * header of request
     */
    headers?: object;
}

export interface IDownloadAPITarget extends IAPITarget {
    fileName: string;
}

/**
 * defines a request target with a typed result
 */
export interface ITypedAPITarget<T> extends IAPITarget {
    /**
     * validate / transform response json data to typed data
     */
    parse?: (data: object) => T;
}
export interface IAPIClientOptions {
    /**
     * base url of remote service
     */
    baseUrl: string;
    /**
     * used to modify or add header before a request is executed (like add authorization headers)
     */
    injectHeaders?: (target: IAPITarget, headers: object) => object;
    /**
     * throws status codes < 200 || > 399 as APIClientError
     */
    throwOnErrorStatusCodes?: boolean;
}

/**
 * executes a requests, defined by APITarget
 */
export class APIClient {
    options: IAPIClientOptions;

    constructor(options: IAPIClientOptions) {
        this.options = options;
        this.options.throwOnErrorStatusCodes = options.throwOnErrorStatusCodes ?? true;
    }

    async performFetch(requestUrl: string, options: RequestInit): Promise<Response> {
        const response = await fetch(requestUrl, options);

        const statusOk = response.status >= 200 && response.status < 400;
        if (!this.options.throwOnErrorStatusCodes || statusOk) {
            // Request was ok
            return response;
        } else {
            // Request not ok -> extract json error and throw
            let json: object;
            try {
                json = await response.json();
            } catch (error) {
                // JSON parsing failed -> throw with response
                throw new APIClientStatusCodeError(response, response.status);
            }

            // Throw json error
            throw new APIClientStatusCodeError(json, response.status);
        }
    }

    /**
     * executes a request for the given target,
     * transforms target definition to fetch parameters
     * throws `APIClientError` if `throwOnErrorStatusCodes` is true and status code of response is < 200 || > 399
     * @param target request target
     */
    async request(target: IAPITarget): Promise<Response> {
        let headers = target.headers;
        if (!headers) {
            headers = {};
        }

        if (this.options.injectHeaders) {
            headers = this.options.injectHeaders(target, headers);
        }

        const query = queryString.stringify(target.queryParameters as any, { arrayFormat: "bracket" });
        let requestUrl = urljoin(this.options.baseUrl, target.url);
        if (query) {
            requestUrl = urljoin(requestUrl, "?" + query);
        }

        const options: RequestInit = {
            method: target.method || "GET",
            body: typeof target.body === "string" ? target.body : JSON.stringify(target.body),
            headers: headers as any,
        };

        return this.performFetch(requestUrl, options);
    }

    /**
     * executes a request for the given target and transforms the result to JSON
     * throws `APIClientError` if `throwOnErrorStatusCodes` is true and status code of response is < 200 || > 399
     * @param target
     */
    async requestJSON(target: IAPITarget): Promise<object> {
        const response = await this.request(target);
        return response.json();
    }

    /**
     * executes a request for the given target and asks target to transform JSON result into type
     * throws `APIClientError` if `throwOnErrorStatusCodes` is true and status code of response is < 200 || > 399
     * @param target
     */
    async requestType<T>(target: ITypedAPITarget<T>): Promise<T> {
        const json = await this.requestJSON(target);
        return target.parse ? target.parse(json) : (json as any as T);
    }
}

/**
 * represents a error caused by invaid statuscode
 */
export class APIClientStatusCodeError {
    response: PublicHttpError | PublicHttpValidationError | Response;
    statusCode: number;

    constructor(response: any, statusCode: number) {
        this.statusCode = statusCode;
        this.response = response;
    }
}
