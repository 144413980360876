import { observer } from "mobx-react";
import * as React from "react";
import { generalStore } from "../../../stores/GeneralStore";
import { Banner } from "../Primitives";
import { CustomerLockedBanner } from "./CustomerLockedBanner";
import { OptInBanner } from "./OptInBanner";
import { SuccessBanner } from "./SuccessBanner";

export const Banners = observer(() => {
    return (
        <div>
            <div style={{ position: "fixed", width: "100%", zIndex: 2 }}>
                {!generalStore.successMessage && generalStore.user && <OptInBanner />}
                {generalStore.customerLocked && <CustomerLockedBanner />}
            </div>
            {generalStore.successMessage && <SuccessBanner />}

            <Banner style={{ position: "unset", backgroundColor: "transparent", border: "none" }} />
            {generalStore.customerLocked && (
                <Banner style={{ position: "unset", backgroundColor: "transparent", border: "none" }} />
            )}
        </div>
    );
});
