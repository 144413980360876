import { ConfirmationDialog } from "../../../../ui/ConfirmationDialog";

export function IdsDeleteMappingConfirmationDialog(props: {
    cancelLabel?: string;
    hideCancel?: boolean;
    message: string;
    onCancel: () => void;
    onSubmit: () => void;
    open: boolean;
    submitLabel?: string;
    title: string;
}) {
    return (
        <ConfirmationDialog
            cancelLabel={props.cancelLabel}
            hideCancel={props.hideCancel}
            message={props.message}
            onCancel={props.onCancel}
            onSubmit={props.onSubmit}
            open={props.open}
            submitLabel={props.submitLabel}
            title={props.title}
        />
    );
}
