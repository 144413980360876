import * as React from "react";
import { t } from "../i18n/util";
import { API } from "../network/API";
import { GetUserRequestLinkedUserRequestsResponse } from "../network/APITypes";
import { generalStore } from "../stores/GeneralStore";

export const useLinkedUserRequests = (userId: string) => {
    const [response, setResponse] = React.useState<GetUserRequestLinkedUserRequestsResponse | null>(null);

    React.useEffect(() => {
        const load = async () => {
            try {
                generalStore.setIsLoading(true);
                const response = await API.getLinkedUserRequests(userId);
                setResponse(response);
            } catch (err) {
                generalStore.setError(t("error.userCustomers"), err);
            } finally {
                generalStore.setIsLoading(false);
            }
        };

        load();
    }, [userId]);

    return response;
};
