import { Route } from "react-router-dom";
import { CustomSwitch } from "../../app/router/CustomSwitch";
import { RequestsSite } from "../sites/RequestsSite";
import { RequestsRoutes } from "./RequestsRoutes";

export const RequestsRouter = () => (
    <CustomSwitch>
        <Route exact path={[RequestsRoutes.ROOT]}>
            <RequestsSite />
        </Route>
    </CustomSwitch>
);
