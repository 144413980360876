import { Button, Divider, IconButton, Table, TableBody, TableRow } from "@mui/material";
import { observer } from "mobx-react";
import * as React from "react";
import { SEARCH_DEBOUNCE_MS, SEARCH_FIELD_MAX_WIDTH } from "../../../config";
import { useConfirmationDialog } from "../../../hooks/useConfirmationDialog";
import { useDomains } from "../../../hooks/useDomains";
import { useManageDomainForm } from "../../../hooks/useManageDomainForm";
import { useMultiSelectionFilter } from "../../../hooks/useMultiSelectionFilter";
import { usePagination } from "../../../hooks/usePagination";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { AccountTypeDomain } from "../../../network/APITypes";
import { generalStore } from "../../../stores/GeneralStore";
import { EmptyState } from "../../ui/EmptyState";
import { CmsTableCell, CmsTableContainer, SettingSubSiteContainer, TableLabel } from "../../ui/Primitives";
import { SearchField } from "../../ui/SearchField";
import { TableHeader, TableHeaderConfig } from "../../ui/TableHeader";
import { AccountTypes } from "../../util/AccountTypes";
import { Icon } from "../../util/Icon";
import { useAccountTypeFilter } from "../../../hooks/useAccountTypeFilter";

export const SettingsDomainManagementSite = observer(() => {
    const [contextDomain, setContextDomain] = React.useState<AccountTypeDomain>();
    const pagination = usePagination({ searchParamName: "domain" });

    const accountTypeFilter = useAccountTypeFilter("type");

    const filterOptions = {
        [accountTypeFilter.name]: accountTypeFilter.values,
    };

    const { domains, reloadDomains, isLoading, isInitialized } = useDomains({
        domain: pagination.search,
        limit: pagination.pageSize,
        offset: pagination.offset,
        orderDir: pagination.orderDir,
        orderBy: pagination.orderBy,
        ...filterOptions,
    });

    const handleSubmitManageDomain = async () => {
        try {
            await reloadDomains();
        } catch (error) {
            generalStore.setError(t("error.loadDomains"), error);
        } finally {
            setContextDomain(undefined);
        }
    };

    const handleCloseManageDomain = () => {
        setContextDomain(undefined);
    };

    const manageDomainDrawer = useManageDomainForm({
        onSubmit: handleSubmitManageDomain,
        onClose: handleCloseManageDomain,
        domain: contextDomain,
    });

    const handleSubmitDeleteDialog = async () => {
        if (contextDomain) {
            try {
                generalStore.setIsLoading(true);
                await API.deleteDomain(contextDomain.id);
                await reloadDomains();
                generalStore.setSuccessMessage(t("common.success"));
            } catch (error) {
                generalStore.setError(t("error.deleteDomain"), error);
            } finally {
                setContextDomain(undefined);
                generalStore.setIsLoading(false);
            }
        }
    };

    const deleteDialog = useConfirmationDialog({
        title: t("domain.action.deleteDialog.title"),
        message: t("domain.action.deleteDialog.message"),
        submitLabel: t("domain.action.deleteDialog.submitLabel"),
        cancelLabel: t("common.no"),
        onSubmit: handleSubmitDeleteDialog,
    });

    const handleClickAddDomain = () => {
        manageDomainDrawer.open();
    };

    const handleClickEditDomain = (domain: AccountTypeDomain) => {
        setContextDomain(domain);
        manageDomainDrawer.open();
    };

    const handleClickDeleteDomain = (domain: AccountTypeDomain) => {
        setContextDomain(domain);
        deleteDialog.open();
    };

    const headerFields: TableHeaderConfig[] = [
        { column: "domain", label: "table.label.domain" },
        { column: "accountType", label: "table.label.defaultAccountType", style: { width: 0 } },
        { column: "description", label: "table.label.comment" },
        { column: "actions", style: { width: 0 } },
    ];

    const tableBody = (
        <TableBody>
            {domains?.accountTypeDomains?.map((domain) => {
                return (
                    <TableRow key={domain.id}>
                        {headerFields.map(({ column }) => {
                            let label = domain[column];

                            if (column === "accountType") {
                                label = t(AccountTypes[domain.accountType]);
                            } else if (column === "actions") {
                                return (
                                    <CmsTableCell key={column}>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "flex-end",
                                                gap: 4,
                                                padding: "0px 8px",
                                            }}
                                        >
                                            <IconButton onClick={() => handleClickEditDomain(domain)}>
                                                <Icon name="edit" />
                                            </IconButton>
                                            <IconButton onClick={() => handleClickDeleteDomain(domain)}>
                                                <Icon name="delete" />
                                            </IconButton>
                                        </div>
                                    </CmsTableCell>
                                );
                            }

                            return (
                                <CmsTableCell
                                    key={column}
                                    style={{
                                        maxWidth: column !== "domain" && column !== "accountType" ? 100 : undefined,
                                    }}
                                >
                                    <TableLabel style={{ maxWidth: "100%" }}>{label}</TableLabel>
                                </CmsTableCell>
                            );
                        })}
                    </TableRow>
                );
            })}
        </TableBody>
    );

    const isEmpty = domains && domains.total === 0;

    return (
        <SettingSubSiteContainer>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 8 }}>
                <div>{accountTypeFilter.component}</div>
                <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                    <div style={{ maxWidth: SEARCH_FIELD_MAX_WIDTH }}>
                        <SearchField
                            placeholder={t("screen.settings.domainManagement.search.placeholder")}
                            onChange={pagination.onChangeSearch}
                            value={pagination.search}
                            style={{ width: "100%" }}
                        />
                    </div>
                    <Button onClick={handleClickAddDomain} variant="contained" style={{ flexShrink: 0 }}>
                        {t("screen.settings.domainManagement.button.addDomain.text")}
                    </Button>
                </div>
            </div>
            <Divider style={{ marginTop: 16, marginBottom: 16 }} />
            {accountTypeFilter.resetComponent}
            {isInitialized && !isLoading && isEmpty && (
                <EmptyState
                    iconName="sadFace"
                    title={t(
                        pagination.search
                            ? "screen.settings.domainManagement.noResult.title"
                            : "screen.settings.domainManagement.emptyScreen.title",
                    )}
                    description={t("screen.settings.domainManagement.emptyScreen.description")}
                />
            )}
            {pagination.component(domains?.total ?? 0)}
            {isInitialized && !isLoading && !isEmpty && (
                <CmsTableContainer>
                    <Table>
                        <TableHeader
                            headerFields={headerFields}
                            orderBy={pagination.orderBy}
                            orderDir={pagination.orderDir}
                            onChangeOrder={pagination.onChangeOrder}
                            allowSort
                        />
                        {tableBody}
                    </Table>
                </CmsTableContainer>
            )}
            {manageDomainDrawer.component}
            {deleteDialog.component}
        </SettingSubSiteContainer>
    );
});
