import * as React from "react";
import { t } from "../i18n/util";
import { API } from "../network/API";
import { Department } from "../network/APITypes";
import { generalStore } from "../stores/GeneralStore";

export const useCorporatePositions = (distributorId: string) => {
    const [response, setResponse] = React.useState<Department[] | null>(null);

    const load = React.useCallback(async () => {
        generalStore.setIsLoading(true);

        try {
            const res = await API.getCorporatePositions(distributorId);
            setResponse(res.corporatePositions);
        } catch (error) {
            generalStore.setError(t("error.loadUsers"), error);
        } finally {
            generalStore.setIsLoading(false);
        }
    }, [distributorId]);

    React.useEffect(() => {
        load();
    }, [load]);

    return response;
};
