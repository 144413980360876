import { t } from "../i18n/util";
import { API } from "../network/API";
import { generalStore } from "../stores/GeneralStore";
import { useConfirmationDialog } from "./useConfirmationDialog";

export function useDeleteRoleDialog(roleId?: string, reload?: () => void) {
    return useConfirmationDialog({
        title: t("role.action.deleteDialog.title"),
        message: t("role.action.deleteDialog.message"),
        submitLabel: t("role.action.deleteDialog.submitLabel"),
        cancelLabel: t("common.no"),
        onSubmit: async () => {
            if (!roleId) {
                return;
            }

            try {
                generalStore.isLoading = true;
                await API.deleteRole(roleId);
                generalStore.setSuccessMessage(t("success.deleteRole"));
                reload?.();
            } catch (error) {
                generalStore.setError(t("error.deleteRole"), error);
            } finally {
                generalStore.isLoading = false;
            }
        },
    });
}
