import { styled } from "@mui/material";
import * as React from "react";
import { Colors } from "../../util/Colors";

export const FilterContainer = styled("div", {
    shouldForwardProp: (prop: string) => prop !== "isSelected",
})(({ isSelected }: { isSelected: boolean }) => ({
    display: "inline-flex",
    alignItems: "center",
    fontWeight: 600,
    fontSize: 14,
    height: 48,
    border: `1px solid ${Colors.GREY_200}`,
    padding: 12,
    borderRadius: 6,
    backgroundColor: isSelected ? "#ECF6FC" : undefined,
    borderColor: isSelected ? Colors.PRIMARY_500 : undefined,
    cursor: "pointer",
    userSelect: "none",
}));
