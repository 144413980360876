import { useState } from "react";
import { RoleGroupDetailsForm, RoleGroupDetailsFormData } from "../components/forms/addRoleGroup/RoleGroupDetailsForm";
import { CustomDrawer } from "../components/ui/CustomDrawer";
import { RoleGroup } from "../network/APITypes";
import { Stepper } from "../types";

export const useRoleGroupDetailsForm = ({
    onSubmit,
    onBack,
    roleGroup,
    stepper,
    persistOnSubmit = true,
}: {
    onSubmit?: (result?: RoleGroup | RoleGroupDetailsFormData) => void | Promise<void>;
    onBack?: () => void;
    roleGroup?: RoleGroup | null;
    stepper?: Stepper;
    persistOnSubmit?: boolean;
}) => {
    const [open, setOpen] = useState(false);
    const [initialName, setInitialName] = useState("");

    const handleClose = () => {
        setOpen(false);
    };

    const component = open ? (
        <CustomDrawer open={open} onClose={handleClose}>
            <RoleGroupDetailsForm
                onClose={handleClose}
                onSubmit={onSubmit}
                onBack={onBack}
                roleGroup={roleGroup}
                stepper={stepper}
                name={initialName}
                persistOnSubmit={persistOnSubmit}
            />
        </CustomDrawer>
    ) : null;

    return {
        open(name = "") {
            setInitialName(name);
            setOpen(true);
        },

        component,
    };
};
